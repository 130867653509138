import { observer } from "mobx-react";
import BrsrSkeleton from "./brsrSkeleton";
import { useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { Modal } from "react-bootstrap";

function BRSRReport() {
  const [titleImage, setTitleImage] = useState(null);
  const ref = useRef(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div
      style={{
        borderRadius: "2px",
        padding: "8px",
      }}
    >
      <div className="card mt-2 ">
        <div className="card-body p-4 d-flex gap-2 flex-wrap report-download-button  ">
          <h5 className="card-title">BRSR Report</h5>

          <div className="d-flex gap-2 flex-wrap">
            <div className="custom-file-input">
              <input
                type="file"
                accept="image/*"
                id="fileUpload"
                className="form-control h-100"
                onChange={(e) => setTitleImage(e.target.files[0])}
              />
            </div>

            <button
              className="btn btn-secondary"
              onClick={handleShow}
              //         onClick={() => {
              //           const printWindow = window.open("", "", "height=800,width=800");
              //           printWindow.document.open();
              //           printWindow.document.write(`
              //   <html>
              //     <head>
              //       <title>Print</title>
              //       <style>
              //         /* Add print-specific styles here */
              //         body { font-family: Arial, sans-serif; margin: 20px; }
              //       </style>
              //     </head>
              //     <body>
              //     ${document.getElementById("brsr-report").innerHTML}
              //     </body>
              //   </html>
              // `);
              //           printWindow.document.close();
              //         }}
            >
              Preview
            </button>
            <button
              className="btn btn-secondary"
              onClick={async () => {
                const printWindow = window.open("", "", "height=800,width=800");
                printWindow.document.open();
                printWindow.document.write(`
                  <html>
                    <head>
                      <title>Print</title>
                      <style>
                        /* Add print-specific styles here */
                        body { font-family: Arial, sans-serif; margin: 20px; }
                        @page { size: A4 landscape; }
                      </style>
                    </head>
                    <body>
                    ${document.getElementById("brsr-report").innerHTML}
                    </body>
                  </html>
                `);
                printWindow.document.close();
                printWindow.onload = function () {
                  printWindow.focus();
                  printWindow.print();
                  printWindow.onafterprint = function () {
                    printWindow.close();
                  };
                };
              }}
            >
              Download
            </button>
          </div>
        </div>
        <div ref={ref} style={{ display: "" }}>
          <BrsrSkeleton
            mainPageUrl={titleImage ? URL.createObjectURL(titleImage) : ""}
          />
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>BRSR Report Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BrsrSkeleton />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default observer(BRSRReport);
