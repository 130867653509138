import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import fetchData from "../../../utils/fetchData";
import { sum, getPercentageOfTwoNumber } from "../../../utils/helpers";

const createFindKeyFunc =
  (data) =>
  (key = "id", value, valueKey) =>
    data?.find((e) => e?.[key] === value)?.value?.[valueKey] || "";

const BRSRSkeleton = ({ mainPageUrl }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData(`brsr/getAll?year=${new Date().getFullYear()}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status && res.data.length > 0) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log("[Error]", err);
      });
    return () => {
      setData([]);
    };
  }, []);

  const assignValue = useMemo(() => createFindKeyFunc(data), [data]);

  return (
    <div id="brsr-report" style={{ padding: "10px" }}>
      <>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              height: "500px",

              ...(mainPageUrl
                ? {
                    backgroundImage: `url(${mainPageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }
                : { backgroundColor: "" }),
            }}
          >
            <div>
              <h1>Green Horizons: BRSR</h1>
            </div>
            <div>
              <h1>Sustainability Report 2024</h1>
            </div>
          </div>
        </div>
        <div
          style={{
            height: "900px",
            marginTop: "10px",
            pageBreakBefore: "always",
          }}
        >
          <div>
            <h3>Table of Contents</h3>

            <p>
              {" "}
              <strong>SECTION A:</strong> GENERAL DISCLOSURES
              …………………………………………………………………………
            </p>

            <p>
              <strong>SECTION B:</strong> MANAGEMENT AND PROCESS DISCLOSURES
              …………………………………………………………………………
            </p>

            <p>
              <strong>SECTION C:</strong> PRINCIPLE WISE PERFORMANCE DISCLOSURE
              …………………………………………………………………………
            </p>

            <div
              style={{ display: "flex", gap: "20px", flexDirection: "column" }}
            >
              <p>
                <strong>PRINCIPLE 1:</strong>
                Businesses should conduct and govern themselves with integrity,
                and in a manner that is Ethical, Transparent and Accountable
                …………………………………………………………………………
              </p>

              <p>
                <strong>PRINCIPLE 2:</strong>
                Businesses should provide goods and services in a manner that is
                sustainable and safe …………………………………………………………………………
              </p>

              <p>
                {" "}
                <strong>PRINCIPLE 3:</strong>
                Businesses should respect and promote the well-being of all
                employees, including those in their value chains
                …………………………………………………………………………
              </p>

              <p>
                <strong>PRINCIPLE 4:</strong>
                Businesses should respect the interests of and be responsive to
                all its stakeholders …………………………………………………………………………
              </p>

              <p>
                <strong>PRINCIPLE 5:</strong>
                Businesses should respect and promote human rights
                …………………………………………………………………………
              </p>

              <p>
                {" "}
                <strong>PRINCIPLE 6:</strong>
                Businesses should respect and make efforts to protect and
                restore the environment …………………………………………………………………………
              </p>

              <p>
                {" "}
                <strong>PRINCIPLE 7:</strong>
                Businesses, when engaging in influencing public and regulatory
                policy, should do so in a manner that is responsible and
                transparent …………………………………………………………………………
              </p>

              <p>
                <strong>PRINCIPLE 8:</strong>
                Businesses should promote inclusive growth and equitable
                development …………………………………………………………………………
              </p>

              <p>
                {" "}
                <strong>PRINCIPLE 9:</strong>
                Businesses should engage with and provide value to their
                consumers in a responsible manner …………………………………………………………………………
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "40px",
                  width: "200px",
                  border: "1px solid black",
                }}
              >
                <img src="" alt="bar code of the company" />
              </div>

              <p style={{ marginTop: "10px" }}>
                {" "}
                For more information, please, visit our website
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            pageBreakBefore: "always",
          }}
        >
          <h4 style={{ color: "#004B88", fontSize: "20px", fontWeight: "800" }}>
            SECTION A: GENERAL DISCLOSURES
          </h4>
          <span
            style={{
              display: "flex",
            }}
          >
            <h4
              style={{ color: "#004B88", fontSize: "20px", fontWeight: "800" }}
            >
              I. DETAILS OF THE LISTED ENTITY
            </h4>
          </span>

          <span
            style={{
              display: "flex",
            }}
          >
            <strong>
              <p>1. Corporate Identity Number (CIN) of the Listed Entity: </p>
            </strong>
            <p>{assignValue("id", "0-2-0-0-0", "value")}</p>
          </span>

          <span
            style={{
              display: "flex",
            }}
          >
            <strong>
              <p>2. Name of the Listed Entity: </p>
            </strong>
            <p>{assignValue("id", "0-2-0-0-1", "value")}</p>
          </span>

          <span
            style={{
              display: "flex",
            }}
          >
            <strong>
              <p>3. Year of incorporation: </p>
            </strong>
            <p>{assignValue("id", "0-2-0-0-2", "value")}</p>
          </span>

          <span
            style={{
              display: "flex",
            }}
          >
            <strong>
              <p>4. Registered office address: </p>
            </strong>
            <p>{assignValue("id", "0-2-0-0-3", "value")}</p>
          </span>

          <p>
            <strong>5. Corporate address:</strong>
            {assignValue("id", "0-2-0-0-4", "value")}
          </p>

          <p>
            <strong>6. E-mail:</strong>
            {assignValue("id", "0-2-0-0-5", "value")}
          </p>

          <p>
            <strong>7. Telephone:</strong>
            {assignValue("id", "0-2-0-0-6", "value")}
          </p>

          <p>
            <strong>8. Website:</strong>
            {assignValue("id", "0-2-0-0-7", "value")}
          </p>

          <p>
            <strong>
              9. Financial year for which reporting is being done:
            </strong>
            {assignValue("id", "0-2-0-0-8", "value")}
          </p>

          <p>
            <strong>
              10. Name of the Stock Exchange(s) where shares are listed:
            </strong>
            {assignValue("id", "0-2-0-0-9", "value")}
          </p>

          <p>
            <strong>11. Paid-up Capital</strong>
            {assignValue("id", "0-2-0-0-10", "value")}
          </p>

          <p>
            <strong>
              12. Name and contact details (telephone, email address) of the
              person who may be contacted in case of any queries on the BRSR
              report
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Sr. No</th>
                <th>Particulars</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Name</td>
                <td>{assignValue("id", "0-2-0-1-0", "details")}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Designation</td>
                <td>{assignValue("id", "0-2-0-2-0", "Designation")}</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Contact Details</td>
                <td>{assignValue("id", "0-2-0-3-0", "Contact Details")}</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Mail Id</td>
                <td>{assignValue("id", "0-2-0-4-0", "MailId")}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              13. Reporting boundary - Are the disclosures under this report
              made on a standalone basis (i.e. only for the entity) or on a
              consolidated basis (i.e. for the entity and all the entities which
              form a part of its consolidated financial statements, taken
              together).
            </strong>
            {assignValue("id", "0-2-1-0-0", "value")}
          </p>

          <p>
            <strong>
              14. Name of assurance provider: The independent assurance provider
              has verified the data presented in this report in accordance with
              applicable regulatory guidelines and prepared in line with
              recognized international standards, as specified in their
              Assurance Statement. The scope and basis of assurance are detailed
              in the statement.
            </strong>
            {assignValue("id", "0-3-0-0-0", "value")}
          </p>
        </div>

        <p>
          <strong>
            <p>15. Type of assurance obtained:</p>
          </strong>
          {/* <p>Core KPIs – Reasonable Assurance</p>

          <p>Rest of the report Limited Assurance</p> */}
          {assignValue("id", "0-3-0-0-1", "value")}
        </p>

        <h4 style={{ color: "#004B88", fontSize: "20px", fontWeight: "800" }}>
          II. Products/services
        </h4>
        <strong>
          <p>
            16. Details of business activities (accounting for 90% of the
            turnover):
          </p>
        </strong>
        <table className="table" border="1" cellpadding="10" cellspacing="0">
          <thead style={{ border: "1px solid #000" }}>
            <tr>
              <th>S. No.</th>
              <th>Description of Main Activity</th>
              <th>Description of Business Activity</th>
              <th>% of Turnover of the Entity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{assignValue("id", "0-0-0-0", "value")}</td>
              <td>{assignValue("id", "0-0-0-1", "value")}</td>
              <td>{assignValue("id", "0-0-0-2", "value")}</td>
            </tr>
          </tbody>
        </table>
        <strong>
          <p>
            17. Products/Services sold by the entity (accounting for 90% of the
            entity’s Turnover):
          </p>
        </strong>
        <table className="table" border="1" cellpadding="10" cellspacing="0">
          <thead style={{ border: "1px solid #000" }}>
            <tr>
              <th>S. No.</th>
              <th>Product/Service</th>
              <th>NIC Code</th>
              <th>% of total Turnover contributed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{assignValue("id", "0-0-1-0", "value")}</td>
              <td>{assignValue("id", "0-0-1-1", "value")}</td>
              <td>{assignValue("id", "0-0-1-2", "value")}</td>
            </tr>
          </tbody>
        </table>
        <div id="pf6" className="pf w6 h12" data-page-no={6}>
          <div className="pc pc6 w6 h12">
            <h4
              style={{ color: "#004B88", fontSize: "20px", fontWeight: "800" }}
            >
              III. Operations
            </h4>

            <strong>
              <p>
                18. Number of locations where plants and/or operations/offices
                of the entity are situated:
              </p>
            </strong>

            <table
              className="table"
              border="1"
              cellpadding="10"
              cellspacing="0"
              style={{ width: "100%" }}
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th>Location</th>
                  <th>Number of plants</th>
                  <th>Number of offices</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>National</td>
                  <td>{assignValue("id", "0-1-0-1", "Number_of_plants")}</td>
                  <td>{assignValue("id", "0-1-0-1", "Number_of_offices")}</td>
                  <td>{assignValue("id", "0-1-0-1", "Total")}</td>
                </tr>
                <tr>
                  <td>International</td>
                  <td>{assignValue("id", "0-1-0-2", "Number_of_plants")}</td>
                  <td>{assignValue("id", "0-1-0-1", "Number_of_offices")}</td>
                  <td>{assignValue("id", "0-1-0-1", "Total")}</td>
                </tr>
              </tbody>
              <tfoot>
                <span>
                  *As LTIMindtree is an IT Services Company, we do not have any
                  manufacturing plants. For more information, refer to Global
                  presence section.
                </span>
              </tfoot>
            </table>

            <div style={{ pageBreakBefore: "always" }}>
              <strong>
                <p>19. Markets served by the entity:</p>
              </strong>
              <strong>
                <p>a. Number of locations</p>
              </strong>
              <table
                className="table"
                border="1"
                cellpadding="10"
                cellspacing="0"
              >
                <thead style={{ border: "1px solid #000" }}>
                  <tr>
                    <th>Locations</th>
                    <th>Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>National (No. of States)</td>
                    <td>{assignValue("id", "0-1-1-1", "value")}</td>
                  </tr>
                  <tr>
                    <td>International (No. of Countries)</td>
                    <td>{assignValue("id", "0-1-1-2", "value")}</td>
                  </tr>
                </tbody>
              </table>
              <ul>
                <li>
                  LTIMindtree has business activities in 9 National locations -
                  Delhi, Karnataka, Kerala, Maharashtra, Odisha, Tamil Nadu,
                  Telangana, Uttar Pradesh, West Bengal.
                </li>
                <li>
                  LTIMindtree has business activities in 37 International
                  locations - Australia, Belgium, Canada, China, Costa Rica,
                  Cyprus, Denmark, Finland, France, Germany, Ireland, Japan,
                  Malaysia, Mexico, Morocco, Netherlands, Norway, Philippines,
                  Poland, Qatar, Saudi Arabia, Singapore, South Africa, Spain,
                  Sweden, UAE, UK, USA, Hungary, Italy, Luxembourg, South Korea,
                  Switzerland, Thailand, Czech Republic, Romania, Taiwan.
                </li>
              </ul>

              <strong>
                <p>
                  b. What is the contribution of exports as a percentage of the
                  total turnover of the entity?
                </p>
              </strong>
              <p>{assignValue("id", "0-1-1-3", "value")}</p>

              <strong>
                <p>c. A brief on types of customers</p>
              </strong>

              <p>{assignValue("id", "0-1-1-4", "value")}</p>

              <h4
                style={{
                  color: "#004B88",
                  fontSize: "20px",
                  fontWeight: "800",
                }}
              >
                IV. Employees
              </h4>

              <p>
                All employees within the organization are classified as either
                Permanent or Non-Permanent, with no specific categorization as
                'Workers.' Acknowledging that gender is not strictly binary,
                individuals may choose to opt out of traditional male/female
                identification and select 'other.' This inclusive category
                covers individuals who identify as non-binary, gender-fluid,
                transgender, or any other gender identity beyond the binary.
              </p>

              <p>
                In the reporting period, the organization included data on
                employees who identified as 'Other' alongside conventional
                male/female classifications, wherever applicable and data was
                available, as part of our commitment to inclusivity.
              </p>
            </div>
          </div>
          <div id="pf7">
            <strong>
              <p>20. Details as at the end of Financial Year:</p>
            </strong>

            <strong>
              <p>a. Employees and workers (including differently abled):</p>
            </strong>

            <table
              className="table"
              border="1"
              cellpadding="10"
              cellspacing="0"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th>S. No.</th>
                  <th>Particulars</th>
                  <th>Total (A)</th>
                  <th>Male (B)</th>
                  <th>% (B/A)</th>
                  <th>Female (C)</th>
                  <th>% (C/A)</th>
                </tr>
              </thead>
              {/* confusion  */}
              <tbody>
                <tr>
                  <td colspan="7">
                    <strong>EMPLOYEES</strong>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Permanent (D)</td>

                  <td>
                    {Number(assignValue("id", "0-1-2-3", "value")) +
                      Number(assignValue("id", "0-1-2-2", "value")) || 0}
                  </td>
                  <td>{assignValue("id", "0-1-2-2", "value")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "0-1-2-2", "value"),
                      Number(assignValue("id", "0-1-2-3", "value")) +
                        Number(assignValue("id", "0-1-2-2", "value")) || 0
                    ).toFixed(2)}
                  </td>
                  <td>{assignValue("id", "0-1-2-3", "value")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "0-1-2-3", "value"),
                      Number(assignValue("id", "0-1-2-3", "value")) +
                        Number(assignValue("id", "0-1-2-2", "value")) || 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Other than Permanent (E)</td>

                  <td>
                    {Number(assignValue("id", "0-1-2-3", "value")) +
                      Number(assignValue("id", "0-1-2-2", "value")) || 0}
                  </td>
                  <td>{assignValue("id", "0-1-2-2", "value")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "0-1-2-2", "value"),
                      Number(assignValue("id", "0-1-2-3", "value")) +
                        Number(assignValue("id", "0-1-2-2", "value")) || 0
                    ).toFixed(2)}
                  </td>
                  <td>{assignValue("id", "0-1-2-3", "value")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "0-1-2-3", "value"),
                      Number(assignValue("id", "0-1-2-3", "value")) +
                        Number(assignValue("id", "0-1-2-2", "value")) || 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Total Employees (D + E)</td>

                  <td>
                    {Number(assignValue("id", "0-1-2-3", "value")) +
                      Number(assignValue("id", "0-1-2-2", "value")) +
                      Number(assignValue("id", "0-1-2-6", "value")) +
                      Number(assignValue("id", "0-1-2-7", "value")) || 0}
                  </td>
                  <td>
                    {Number(assignValue("id", "0-1-2-2", "value")) +
                      Number(assignValue("id", "0-1-2-6", "value")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-1-2-2", "value")) +
                        Number(assignValue("id", "0-1-2-6", "value")),
                      Number(assignValue("id", "0-1-2-3", "value")) +
                        Number(assignValue("id", "0-1-2-2", "value")) +
                        Number(assignValue("id", "0-1-2-6", "value")) +
                        Number(assignValue("id", "0-1-2-7", "value"))
                    ).toFixed(2)}
                  </td>
                  <td>
                    {Number(assignValue("id", "0-1-2-3", "value")) +
                      Number(assignValue("id", "0-1-2-7", "value")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-1-2-3", "value")) +
                        Number(assignValue("id", "0-1-2-7", "value")),
                      Number(assignValue("id", "0-1-2-2", "value")) +
                        Number(assignValue("id", "0-1-2-3", "value")) +
                        Number(assignValue("id", "0-1-2-6", "value")) +
                        Number(assignValue("id", "0-1-2-7", "value"))
                    ).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td colspan="7">
                    <strong>WORKERS</strong>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Permanent (F)</td>
                  <td>
                    {Number(assignValue("id", "0-1-2-11", "value")) +
                      Number(assignValue("id", "0-1-2-12", "value")) || 0}
                  </td>
                  <td>{assignValue("id", "0-1-2-11", "value")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "0-1-2-11", "value"),
                      Number(assignValue("id", "0-1-2-11", "value")) +
                        Number(assignValue("id", "0-1-2-12", "value")) || 0
                    ).toFixed(2)}
                  </td>
                  <td>{assignValue("id", "0-1-2-12", "value")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "0-1-2-12", "value"),
                      Number(assignValue("id", "0-1-2-11", "value")) +
                        Number(assignValue("id", "0-1-2-12", "value")) || 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Other than Permanent (G)</td>
                  <td>
                    {Number(assignValue("id", "0-1-2-15", "value")) +
                      Number(assignValue("id", "0-1-2-16", "value")) || 0}
                  </td>
                  <td>{assignValue("id", "0-1-2-15", "value")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "0-1-2-15", "value"),
                      Number(assignValue("id", "0-1-2-15", "value")) +
                        Number(assignValue("id", "0-1-2-16", "value")) || 0
                    ).toFixed(2)}
                  </td>
                  <td>{assignValue("id", "0-1-2-16", "value")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "0-1-2-16", "value"),
                      Number(assignValue("id", "0-1-2-15", "value")) +
                        Number(assignValue("id", "0-1-2-16", "value")) || 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Total Workers (F + G)</td>
                  <td>
                    {Number(assignValue("id", "0-1-2-11", "value")) +
                      Number(assignValue("id", "0-1-2-12", "value")) +
                      Number(assignValue("id", "0-1-2-15", "value")) +
                      Number(assignValue("id", "0-1-2-16", "value")) || 0}
                  </td>
                  <td>
                    {Number(assignValue("id", "0-1-2-11", "value")) +
                      Number(assignValue("id", "0-1-2-15", "value")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-1-2-11", "value")) +
                        Number(assignValue("id", "0-1-2-15", "value")),
                      Number(assignValue("id", "0-1-2-11", "value")) +
                        Number(assignValue("id", "0-1-2-12", "value")) +
                        Number(assignValue("id", "0-1-2-15", "value")) +
                        Number(assignValue("id", "0-1-2-16", "value"))
                    ).toFixed(2)}
                  </td>
                  <td>
                    {Number(assignValue("id", "0-1-2-12", "value")) +
                      Number(assignValue("id", "0-1-2-16", "value")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-1-2-12", "value")) +
                        Number(assignValue("id", "0-1-2-16", "value")),
                      Number(assignValue("id", "0-1-2-11", "value")) +
                        Number(assignValue("id", "0-1-2-12", "value")) +
                        Number(assignValue("id", "0-1-2-15", "value")) +
                        Number(assignValue("id", "0-1-2-16", "value"))
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="pf7">
            <strong>
              <p>b. Differently abled Employees and workers:</p>
            </strong>

            <table
              className="table"
              border="1"
              cellpadding="10"
              cellspacing="0"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th>S. No.</th>
                  <th>Particulars</th>
                  <th>Total (A)</th>
                  <th>Male (B)</th>
                  <th>% (B/A)</th>
                  <th>Female (C)</th>
                  <th>% (C/A)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="7">
                    <strong>DIFFERENTLY ABLED EMPLOYEES</strong>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Permanent (D)</td>
                  <td>{assignValue("id", "0-3-1-0-1", "Total(A)") || 0}</td>
                  <td>{assignValue("id", "0-3-1-0-1", "Male(B)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-1", "Male(B)")),
                      Number(assignValue("id", "0-3-1-0-1", "Total(A)"))
                    ).toFixed(2)}
                  </td>
                  <td>{assignValue("id", "0-3-1-0-1", "Female(C)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-1", "Female(C)")),
                      Number(assignValue("id", "0-3-1-0-1", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Other than Permanent (E)</td>

                  <td>{assignValue("id", "0-3-1-0-2", "Total(A)") || 0}</td>
                  <td>{assignValue("id", "0-3-1-0-2", "Male(B)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-2", "Male(B)")),
                      Number(assignValue("id", "0-3-1-0-2", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                  <td>{assignValue("id", "0-3-1-0-2", "Female(C)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-2", "Female(C)")),
                      Number(assignValue("id", "0-3-1-0-2", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Total Employees (D + E)</td>
                  <td>
                    {Number(assignValue("id", "0-3-1-0-1", "Total(A)")) +
                      Number(assignValue("id", "0-3-1-0-2", "Total(A)")) || 0}
                  </td>
                  <td>
                    {Number(assignValue("id", "0-3-1-0-1", "Male(B)")) +
                      Number(assignValue("id", "0-3-1-0-2", "Male(B)")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-1", "Male(B)")) +
                        Number(assignValue("id", "0-3-1-0-2", "Male(B)")),
                      Number(assignValue("id", "0-3-1-0-1", "Total(A)")) +
                        Number(assignValue("id", "0-3-1-0-2", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                  <td>
                    {Number(assignValue("id", "0-3-1-0-1", "Female(C)")) +
                      Number(assignValue("id", "0-3-1-0-2", "Female(C)")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-1", "Female(C)")) +
                        Number(assignValue("id", "0-3-1-0-2", "Female(C)")),
                      Number(assignValue("id", "0-3-1-0-1", "Total(A)")) +
                        Number(assignValue("id", "0-3-1-0-2", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>

                <tr>
                  <td colspan="7">
                    <strong>DIFFERENTLY ABLED WORKERS</strong>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Permanent (F)</td>
                  <td>{assignValue("id", "0-3-1-0-4", "Total(A)") || 0}</td>
                  <td>{assignValue("id", "0-3-1-0-4", "Male(B)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-4", "Male(B)")),
                      Number(assignValue("id", "0-3-1-0-4", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                  <td>{assignValue("id", "0-3-1-0-4", "Female(C)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-4", "Female(C)")),
                      Number(assignValue("id", "0-3-1-0-4", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Other than Permanent (G)</td>
                  <td>{assignValue("id", "0-3-1-0-5", "Total(A)") || 0}</td>
                  <td>{assignValue("id", "0-3-1-0-5", "Male(B)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-5", "Male(B)")),
                      Number(assignValue("id", "0-3-1-0-5", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                  <td>{assignValue("id", "0-3-1-0-5", "Female(C)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-5", "Female(C)")),
                      Number(assignValue("id", "0-3-1-0-5", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Total Workers (F + G)</td>
                  <td>
                    {Number(assignValue("id", "0-3-1-0-4", "Total(A)")) +
                      Number(assignValue("id", "0-3-1-0-5", "Total(A)")) || 0}
                  </td>
                  <td>
                    {Number(assignValue("id", "0-3-1-0-4", "Male(B)")) +
                      Number(assignValue("id", "0-3-1-0-5", "Male(B)")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-4", "Male(B)")) +
                        Number(assignValue("id", "0-3-1-0-5", "Male(B)")),
                      Number(assignValue("id", "0-3-1-0-4", "Total(A)")) +
                        Number(assignValue("id", "0-3-1-0-5", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                  <td>
                    {Number(assignValue("id", "0-3-1-0-4", "Female(C)")) +
                      Number(assignValue("id", "0-3-1-0-5", "Female(C)")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-1-0-4", "Female(C)")) +
                        Number(assignValue("id", "0-3-1-0-5", "Female(C)")),
                      Number(assignValue("id", "0-3-1-0-4", "Total(A)")) +
                        Number(assignValue("id", "0-3-1-0-5", "Total(A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Note: It is completely voluntary for our employees to declare
              their disability status and the number of PwD employees shown here
              are those who have declared their disability. So, this data
              represents a subset of actual PwDs currently working with
              LTIMindtree.
            </p>

            <strong>
              <p>21. Participation/Inclusion/Representation of women</p>
            </strong>

            <table
              className="table"
              border="1"
              cellpadding="10"
              cellspacing="0"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th></th>
                  <th>Total (A)</th>
                  <th>Number of Females</th>
                  <th>Percentage of Females</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Board of Directors</td>
                  <td>{assignValue("id", "0-3-2-1-0", "value")}</td>
                  <td>{assignValue("id", "0-3-2-1-0", "Female")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-2-1-0", "Female")),
                      Number(assignValue("id", "0-3-2-1-0", "value"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>

                <tr>
                  <td>Key Management Personnel</td>
                  <td>{assignValue("id", "0-3-2-1-1", "value")}</td>
                  <td>{assignValue("id", "0-3-2-1-1", "Female")}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "0-3-2-1-1", "Female")),
                      Number(assignValue("id", "0-3-2-1-1", "value"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p>Note:</p>
              <li>
                CEO & MD and two Whole-time Directors are included in the Board
                of Directors (BODs)
              </li>
              <li>
                Key Management Personnel (KMP) include Chief Financial Officer
                (CFO) and Company Secretary (CS)
              </li>
            </div>
            <strong>
              <p>22. Turnover rate for permanent employees and workers</p>
            </strong>
          </div>
          <div id="pf9" className="pf w6 h12" data-page-no={9}>
            <div>
              <table
                className="table"
                border="1"
                cellpadding="10"
                cellspacing="0"
              >
                <thead style={{ border: "1px solid #000" }}>
                  <tr>
                    <th></th>
                    <th>FY (Turnover rate in current FY)</th>
                    <th>FY (Turnover rate in previous FY)</th>
                    <th>
                      FY (Turnover rate in the year prior to the previous FY)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Permanent Employees</td>
                    <td>{assignValue("id", "0-3-3-0-0", "Male")}</td>
                    <td>{assignValue("id", "0-3-3-0-0", "Female")}</td>
                    <td>
                      {Number(assignValue("id", "0-3-3-0-0", "Male")) +
                        Number(assignValue("id", "0-3-3-0-0", "Female")) || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <p>Note:</p>
                <li>
                  The Turnover rates mentioned above are for LTIMindtree’s
                  global headcount, including subsidiaries
                </li>
                <li>This data is Voluntary Attrition %</li>
                <li>
                  LTIMindtree has taken a multi-pronged approach towards
                  workforce retention. To retain and attract the workforce, we
                  provide upskilling opportunities, promotions, career growth,
                  benefits and rewards, and long-term incentives which reduced
                  attrition
                </li>
              </div>

              <h4
                style={{
                  color: "#004B88",
                  fontSize: "20px",
                  fontWeight: "800",
                }}
              >
                V.&nbsp; Holding, Subsidiary and Associate Companies (including
                joint ventures)
              </h4>

              <strong>
                <p>
                  (a) Names of holding / subsidiary / associate companies /
                  joint ventures
                </p>
              </strong>

              <table
                className="table"
                border="1"
                cellpadding="10"
                cellspacing="0"
              >
                <thead style={{ border: "1px solid #000" }}>
                  <tr>
                    <th>Sr. No.</th>
                    <th>
                      Name of the holding / subsidiary / associate companies/
                      joint ventures (A)
                    </th>
                    <th>
                      Indicate whether holding/ Subsidiary/ Associate/ Joint
                      Venture
                    </th>
                    <th>% of shares held by listed entity</th>
                    <th>
                      Does the entity indicated at column A, participate in the
                      Business Responsibility initiatives of the listed entity?
                      (Yes/No)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>{assignValue("id", "0-1-3-0", "value")}</td>
                    <td>{assignValue("id", "0-1-3-1", "value")}</td>
                    <td>{assignValue("id", "0-1-3-2", "value")}</td>
                    <td>{assignValue("id", "0-1-3-3", "value")}</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <span>
                  1. During the year, Cuelogic Technologies Inc is liquidated
                  w.e.f. April 26, 2023.
                </span>
                <br />
                <span>
                  2. During the year, Mindtree Software (Shanghai) Co. Ltd is
                  liquidated w.e.f. August 26, 2023
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            VI. CSR DETAILS
          </h4>
          <p>
            24. (i) Whether CSR is applicable as per section 135 of Companies
            Act, 2013: (Yes/No) {assignValue("id", "0-1-4-0", "value") || "No"}
          </p>
          <p>(ii) Turnover = {assignValue("id", "0-1-4-1", "value")}</p>
          <p>(iii) Net worth ={assignValue("id", "0-3-4-0-1", "value")}</p>
          <p>
            (iv) CSR threshold limit: ={assignValue("id", "0-3-4-0-2", "value")}
          </p>
          <p>(v) CSR spend: = {assignValue("id", "0-3-4-0-3", "value")} </p>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            VII. Transparency and Disclosures Compliances
          </h4>

          <strong>
            <p>
              25. Complaints/Grievances on any of the principles (Principles 1
              to 9) under the National Guidelines on Responsible Business
              Conduct:
            </p>
          </strong>

          <table className="table" border="1" cellpadding="10" cellspacing="0">
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Stakeholder group from whom complaint is received</th>
                <th>
                  Grievance Redressal Mechanism in Place (Yes/No) (If Yes, then
                  provide web-link for grievance redress policy)
                </th>
                <th>FY Current Financial Year</th>
                {/* <th>FY Previous Financial Year</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Communities</td>
                <td>
                  {assignValue("id", "0-1-5-2", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-3", "value")}
                </td>
                <td>
                  {assignValue("id", "0-1-5-5", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-6", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-7", "value")}
                </td>
                {/* <td></td> */}
              </tr>
              <tr>
                <td>Shareholders</td>
                <td>
                  {assignValue("id", "0-1-5-14", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-15", "value")}
                </td>
                <td>
                  {assignValue("id", "0-1-5-16", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-7", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-18", "value")}
                </td>
                {/* <td></td> */}
              </tr>
              <tr>
                <td>Employees and workers</td>
                <td>
                  {assignValue("id", "0-1-5-20", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-21", "value")}
                </td>
                <td>
                  {assignValue("id", "0-1-5-22", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-23", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-24", "value")}
                </td>
                {/* <td></td> */}
              </tr>
              <tr>
                <td>Customers</td>
                <td>
                  {assignValue("id", "0-1-5-26", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-27", "value")}
                </td>
                <td>
                  {assignValue("id", "0-1-5-28", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-29", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-30", "value")}
                </td>
                {/* <td></td> */}
              </tr>
              <tr>
                <td>Value Chain Partners</td>
                <td>
                  {assignValue("id", "0-1-5-32", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-33", "value")}
                </td>
                <td>
                  {assignValue("id", "0-1-5-34", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-35", "value")} {","}{" "}
                  {assignValue("id", "0-1-5-36", "value")}
                </td>
                {/* <td></td> */}
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: "10px" }}>
          <strong>
            26. Overview of the entity’s material responsible business conduct
            issues
            <br />
            Please indicate material responsible business conduct and
            sustainability issues pertaining to environmental and social matters
            that present a risk or an opportunity to your business, rationale
            for identifying the same, approach to adapt or mitigate the risk
            along-with its financial implications, as per the following format
          </strong>
          <p>
            At the core of our commitment to creating sustainable value for all
            stakeholders is our materiality assessment process, which helps
            identify and prioritize key issues that align with stakeholder
            interests and influence our business operations. Guided by our core
            principle of balancing positive impact with business excellence, we
            recognize the importance of integrating stakeholder expectations
            into our decision-making processes.
          </p>

          <p>
            <strong>Methodology:</strong> Our materiality assessment process
            aligns with international reporting frameworks such as the GRI
            Universal Standards 2021. This process explores both internal and
            external factors to provide a comprehensive overview. Internally, we
            assess leadership changes, strategic initiatives, and the
            complexities of our business model. Externally, we evaluate economic
            conditions, environmental challenges, resource availability, and the
            evolving expectations of customers and partners.
          </p>

          <p>
            <strong>Determining Materiality:</strong> Our internal evaluations
            are complemented by external benchmarking against industry peers and
            global sustainability standards. This approach ensures we address
            current concerns while also anticipating future trends, aligning our
            strategies with stakeholder expectations over various time horizons.
          </p>

          <p>
            <strong>Key Considerations:</strong> A critical component of our
            materiality assessment is the proactive identification and
            evaluation of emerging Environmental, Social, and Governance (ESG)
            risks. We recognize the evolving nature of material issues and
            incorporate multiple factors into our assessment framework. These
            include evaluating direct economic impacts on our business model,
            aligning with organizational values, benchmarking against industry
            standards, addressing stakeholder concerns, and responding to
            societal shifts.
          </p>

          <p>In our Materiality Assessment process, we focus on:</p>

          <p>
            <strong>Stakeholders:</strong> We analyze the needs and expectations
            of various stakeholder groups and how they influence our strategic
            and operational decisions.
          </p>
          <p>
            <strong>Regulations:</strong> We monitor current regulations and
            anticipate future legislative changes that may impact our financial
            planning and business strategies.
          </p>
          <p>
            Risks: We identify and assess significant risks, both financial and
            non-financial, that could affect our operations, outcomes, and
            relationships with customers. This includes identifying
            opportunities within challenges.
          </p>
          <p>
            <strong>Value Creation:</strong> We explore areas for value creation
            that enhance outcomes for customers and the business, including
            products, services, and operational processes.
          </p>
          <p>
            <strong>Performance:</strong> We evaluate our commitments, values,
            policies, goals, and targets in relation to our performance over the
            previous year.
          </p>
          <p>
            <strong>Reputation:</strong> We carefully review our reputation and
            brand perception compared to peer organizations, including an
            analysis of external media coverage and benchmarking.
          </p>
          <p>
            We prioritize key material issues based on feedback from
            stakeholders and internal assessments, organizing them across
            Environmental, Social, and Governance pillars. These issues are
            aligned with the Sustainable Development Goals (SDGs) to ensure a
            positive impact in these areas.
          </p>
        </div>
        <div style={{ pageBreakBefore: "always" }}>
          <h6
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginBottom: "5px",
            }}
          >
            Management Approach
          </h6>

          <p>
            Our management approach outlines how we effectively address
            economic, environmental, and social impacts related to key material
            topics. This involves a thorough process of identifying,
            prioritizing, analyzing, and responding to both existing and
            potential impacts. Key material topics are discussed during Board
            Meetings, with oversight from dedicated committees to ensure the
            effectiveness of internal processes. The evaluation of our
            management approach is carried out by our Enterprise Risk Management
            (ERM) team, using methods such as risk assessments, audits, and
            performance reviews. The outcomes of these assessments are presented
            to the Audit Committee for consideration and approval of any
            necessary modifications.
          </p>

          <p>
            The prioritization of material topics is informed by inputs from key
            stakeholders and internal discussions within management meetings.
            Our ESG performance is evaluated by our customers, who often review
            us through various indices and rating organizations. Additionally,
            independent third-party audits are conducted to validate our
            performance against established standards and benchmarks.
          </p>

          <div
            style={{
              border: "1px solid black",
              height: "200px",
              width: "100%",
              padding: "10px",
            }}
          >
            <p>Materiality matrix Diagram of different countries</p>
          </div>
        </div>
        <div style={{ pageBreakBefore: "always" }}>
          <h4 style={{ color: "#004B88", fontSize: "20px", fontWeight: "800" }}>
            SECTION B: MANAGEMENT AND PROCESS DISCLOSURES
          </h4>

          <p>
            This section is designed to help businesses showcase the structures,
            policies, and processes implemented to adopt the NGRBC Principles
            and Core Elements.
          </p>
          <p>
            The National Guidelines on Responsible Business Conduct (NGRBC),
            based on the National Voluntary Guidelines on Social, Environmental,
            and Economic Responsibilities of Business (NVGs) released by the
            Ministry of Corporate Affairs, outline nine key areas of Business
            Responsibility, which are summarized as follows:
          </p>

          <strong>
            <p>Principle Descriptions</p>
          </strong>
          <ul>
            <li>
              <strong>P1:</strong> Businesses should conduct and govern
              themselves with integrity, ethics, transparency, and
              accountability.
            </li>
            <li>
              <strong>P2:</strong> Businesses should provide goods and services
              in a manner that is sustainable and ensures safety.
            </li>
            <li>
              <strong>P3:</strong> Businesses should respect and promote the
              well-being of all employees, including those within their value
              chain.
            </li>
            <li>
              <strong>P4:</strong> Businesses should respect the interests of
              and be responsive to all stakeholders.
            </li>
            <li>
              <strong>P5:</strong> Businesses should respect and promote human
              rights.
            </li>
            <li>
              <strong>P6:</strong> Businesses should respect, protect, and
              actively work to restore the environment.
            </li>
            <li>
              <strong>P7:</strong> Businesses should engage in influencing
              public and regulatory policy in a responsible and transparent
              manner.
            </li>
            <li>
              <strong>P8:</strong> Businesses should promote inclusive growth
              and equitable development.
            </li>
            <li>
              <strong>P9:</strong> Businesses should engage with and provide
              value to consumers in a responsible manner.
            </li>
          </ul>
        </div>
        <div>
          <strong>
            <p>
              These principles serve as a comprehensive guide for businesses to
              conduct operations responsibly and sustainab
            </p>
          </strong>

          <table className="table" border="1" cellpadding="10" cellspacing="0">
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Disclosure Questions</th>

                <th>P1</th>
                <th>P2</th>
                <th>P3</th>
                <th>P4</th>
                <th>P5</th>
                <th>P6</th>
                <th>P7</th>
                <th>P8</th>
                <th>P9</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Whether your entity’s policy/policies cover each principle and
                  its core elements of NGRBCs. ( Yes/No)
                </td>
                <td>{assignValue("id", "1-0-0-0", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-0", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-0", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-0", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-0", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-0", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-0", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-0", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-0", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Has the policy been approved by the Board? (Yes/No)</td>
                <td>{assignValue("id", "1-0-0-1", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-1", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-1", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-1", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-1", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-1", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-1", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-1", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-1", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Web Link of the Policies, if available</td>
                <td>{assignValue("id", "1-0-0-2", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-2", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-2", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-2", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-2", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-2", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-2", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-2", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-2", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  Whether the entity has translated the policy into procedures.
                  (Yes / No)
                </td>
                <td>{assignValue("id", "1-0-0-3", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-3", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-3", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-3", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-3", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-3", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-3", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-3", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-3", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  Do the enlisted policies extend to your value chain partners?
                  (Yes/No)
                </td>
                <td>{assignValue("id", "1-0-0-4", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-4", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-4", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-4", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-4", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-4", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-4", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-4", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-4", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  Name of the national and international
                  codes/certifications/labels/ standards (e.g. Forest
                  Stewardship Council, Fairtrade, Rainforest Alliance, Trustea)
                  standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your
                  entity and manpped to each princiaple
                </td>
                <td>{assignValue("id", "1-0-0-5", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-5", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-5", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-5", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-5", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-5", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-5", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-5", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-5", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  Specific commitments, goals and targets set by the entity with
                  defined timelines if any
                </td>
                <td>{assignValue("id", "1-0-0-6", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-6", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-6", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-6", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-6", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-6", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-6", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-6", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-6", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  Performance of the entity against the specific commitments,
                  goals and targets along-with reasons in case the same are not
                  met
                </td>
                <td>{assignValue("id", "1-0-0-7", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-7", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-7", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-7", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-7", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-7", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-7", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-7", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-0-0-7", "P9") ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ pageBreakBefore: "always" }}>
          <strong>
            <p>P1 Ethics & Transparency</p>
          </strong>

          <p>
            Our Code of Conduct encourages all employees to conduct business
            lawfully, ethically, and in the best interests of the organization.
            To address anti-bribery and corruption, we have implemented a
            comprehensive anti-bribery and anti-corruption policy, along with
            training programs to manage these risks effectively. The
            organization has multiple policies, both internal and external, that
            promote ethical behavior, transparency, and accountability.
          </p>

          <strong>
            <p style={{ marginTop: "50px" }}>P2 Service Responsibility</p>
          </strong>

          <p>
            We believe that sustainable practices in production and consumption
            are interconnected, enhancing quality of life and supporting the
            protection and preservation of natural resources.
          </p>

          <strong>
            <p style={{ marginTop: "50px" }}>P3 Human Resources</p>
          </strong>

          <p>
            Organizations implement various policies to support employee
            well-being.
          </p>

          <strong>
            <p style={{ marginTop: "50px" }}>P4 Responsive to Stakeholders</p>
          </strong>

          <p>
            Organizations recognize their responsibility to maximize the
            positive impacts and minimize and mitigate the adverse impacts of
            their services, operations, and practices on all stakeholders.
          </p>

          <strong>
            <p style={{ marginTop: "50px" }}>P5 Human Rights</p>
          </strong>

          <p>
            The Code of Conduct outlines the policy regarding human rights.
            Organizations, through their Human Rights Statements, uphold human
            rights as outlined in relevant national constitutions and support
            the principles of the United Nations' Universal Declaration of Human
            Rights.
          </p>

          <strong>
            <p style={{ marginTop: "50px" }}>P6 Restore Environment</p>
          </strong>

          <p>
            Environmental responsibility is essential for sustainable economic
            growth and societal well-being. Organizations adopt environmental
            practices and processes that minimize or eliminate the adverse
            impacts of their operations across the value chain. Additionally,
            employees are educated on reducing wasteful use.
          </p>
        </div>
        <div>
          <strong>
            <p style={{ marginTop: "50px" }}>P7 Public Advocacy</p>
          </strong>

          <p>
            While organizations may share their expertise to assist in the
            formulation of public policy and advocate with suppliers to adopt
            sustainability and business responsibility practices, they do not
            directly engage in lobbying or advocacy activities and therefore do
            not have a specific policy for this purpose.
          </p>

          <strong>
            <p style={{ marginTop: "50px" }}>P8 Inclusive Growth</p>
          </strong>

          <p>
            An organization's Corporate Social Responsibility charter includes
            activities aimed at supporting marginalized and vulnerable sections
            of society. The organization contributes to overall development with
            a specific focus on disadvantaged, vulnerable, and marginalized
            communities.
          </p>

          <strong>
            <p style={{ marginTop: "50px" }}>P9 Customer Engagement</p>
          </strong>

          <p>
            Organizations believe that businesses should engage with customers
            and provide value in a responsible manner. Their Information
            Security Management System (ISMS) complies with relevant standards
            and provides a systematic approach to managing and securing
            information. This certification assures stakeholders that the
            organization has implemented adequate data protection and
            information security measures to mitigate information and
            cybersecurity threats.
          </p>
        </div>
        <div>
          <strong>
            <p>
              5. "Specific commitments, goals, and targets set by the
              organization with defined timelines, if any."
            </p>
          </strong>
          <div>
            <img
              src="/image-specific.png"
              alt="image-Specific commitments, goals"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div>
          <strong>
            <p>
              6. "Performance of the organization against specific commitments,
              goals, and targets, along with reasons for any deviations
            </p>
          </strong>

          <p>
            "We are working diligently and monitoring our progress to achieve
            our ambitious ESG vision. We will continue to enhance our
            capabilities and successes as we embark on our next growth phase,
            supported by a skilled and engaged workforce, thriving communities,
            efficient sustainability practices, and strong economic performance.
            This will help us deliver sustained value to all stakeholders. The
            ESG section in this annual report provides further details on our
            performance against specific commitments, goals, and targets."
          </p>

          <table
            border="1"
            style={{ width: "100%" }}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Sr.NO</th>
                <th>ESG Vision</th>
                <th>Progress IN FY</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Net-Zero by </td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td>% of Renewable Energy use by 20XX</td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td>% of Waste Recycling</td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Water Conservation</td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Expand green technology offerings to clients.</td>
                <td></td>
              </tr>
              <tr>
                <td>6</td>
                <td>Women in workforce</td>
                <td></td>
              </tr>
              <tr>
                <td>7</td>
                <td>Women in leadership by 20XX</td>
                <td></td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  Become an employer of choice for LGBTQ individuals, people
                  with disabilities (PwD), and veterans, and create a great
                  workplace for all. Achieve 50% or more local nationalities in
                  major business countries by 2030.
                </td>
                <td></td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  Promote and create an ecosystem of diverse suppliers; 10%
                  supplier base to be minority owned businesses
                </td>
                <td></td>
              </tr>

              <tr>
                <td>10</td>
                <td>
                  Impact 4 Million plus lives positively in the community by
                  2030
                </td>
                <td></td>
              </tr>
              <tr>
                <td>11</td>
                <td>Diversify our board (across gender and background)</td>
                <td></td>
              </tr>
              <tr>
                <td>12</td>
                <td>Link ESG to Executive Compensation</td>
                <td></td>
              </tr>
              <tr>
                <td>13</td>
                <td>
                  Continue to train 100% associates, partners, and suppliers on
                  business ethics and data privacy
                </td>
                <td></td>
              </tr>
              <tr>
                <td>14</td>
                <td>
                  Maintain robust compliance, integrity practices, and Key
                  certifications
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <strong>
            <h4
              style={{
                color: "#004B88",
                fontSize: "20px",
                fontWeight: "800",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              GOVERNANCE, LEADERSHIP AND OVERSIGHT
            </h4>
          </strong>

          <p>
            <strong> 7. </strong> "Statement by the director responsible for the
            business responsibility report, highlighting ESG-related challenges,
            targets, and achievements. The placement of this disclosure is
            flexible according to the organization’s preference."
          </p>

          <p>
            {" "}
            In today's dynamic business environment, corporate responsibility
            extends beyond traditional boundaries. We embrace this evolution,
            recognizing our evolving responsibilities to society, communities,
            and the environment. As regulatory frameworks for ESG standards
            transform, we remain committed to integrating sustainability into
            our organization.
          </p>

          <p>
            <strong>Our approach</strong>
            is comprehensive, involving both actionable steps and core values.
            We focus on reducing our carbon footprint, preserving natural
            resources, and being transparent about climate risks and
            opportunities. We also stay attuned to emerging ESG trends to align
            with the preferences of future customers and investors, particularly
            younger generations, enhancing our reputation and attracting top
            talent.
          </p>

          <p>
            <strong>Our dedication</strong>
            to ethical governance is evident in our robust policies and culture
            of integrity, guiding interactions with clients, partners, and
            stakeholders. In our aim to be an Employer of Choice, we emphasize
            diversity and inclusivity, including gender diversity, employees
            with disabilities, LGBTQ+ individuals, and veterans. Our recruitment
            targets individuals who align with our commitment to SDGs and ESG
            principles.
          </p>

          <p>This year, we have achieved several notable milestones:</p>

          <p>
            <strong>- Talent Development:</strong> Our platform has upskilled
            over&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;associates,
            and our IGNITE program has provided hands-on training to college
            graduates in emerging technologies.
          </p>

          <p>
            <strong>- Sustainability Goals:</strong>
            We progressed towards our Net-Zero goal by enhancing operational
            efficiencies and investing in green buildings. We achieved a waste
            diversion rate of XX% and are pursuing Zero Waste to Landfill
            Certification. Our water positivity index is
          </p>

          <p>
            <strong>- CSR Initiatives:</strong>
            We impacted over
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; lives
            through skills development and environmental education, planted
            nearly 3.8 million saplings, and conserved
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of
            forests.
          </p>

          <p>
            <strong>- Client Solutions:</strong>
            We provide digital solutions to help clients measure ESG parameters,
            reduce carbon emissions, and optimize energy use.
          </p>

          <p>
            In &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; we
            received the
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            "Climate Leaders Asia-Pacific 2023" for reducing GHG emissions
            intensity, and earned a top rating in the Travel Smart Campaign
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; for
            minimizing business travel emissions. We also aligned our
            disclosures with TNFD standards, becoming an 'Early Adopter' in
            India.
          </p>

          <p>
            As we move forward, our commitment to sustainability, innovation,
            and collaboration will guide us toward a future that is both
            sustainable and equitable for all.
          </p>

          <strong>
            {" "}
            <p>Chief Sustainability Officer</p>
          </strong>
          <p>Name : …………………………………………………</p>

          <p>
            <strong>
              8. Details of the highest authority responsible for implementation
              and oversight of the Business Responsibility policy (ies).
            </strong>
            <p>{assignValue("id", "1-0-1-1", "value")}</p>
          </p>
          <p>
            <strong>
              9. Does the entity have a specified Committee of the
              Board/Director responsible for decision making on sustainability
              related issues? (Yes/No). If yes, provide details
            </strong>
          </p>
          <p>{assignValue("id", "2-0-1-0-1", "value")}</p>
        </div>
        <p>
          <strong>Details of Review of NGRBCs by the Company</strong>
        </p>
        <div>
          <table className="table" border="1" cellpadding="10" cellspacing="0">
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Questions</th>

                <th>P1</th>
                <th>P2</th>
                <th>P3</th>
                <th>P4</th>
                <th>P5</th>
                <th>P6</th>
                <th>P7</th>
                <th>P8</th>
                <th>P9</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Performance against above policies and follow up action</td>
                <td>{assignValue("id", "1-1-0-0", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-0", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-0", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-0", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-0", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-0", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-0", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-0", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-0", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  Compliance with statutory requirements of relevance to the
                  principles, and, rectification of any non-compliance
                </td>
                <td>{assignValue("id", "1-1-0-1", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-1", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-1", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-1", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-1", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-1", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-1", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-1", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-1", "P9") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  Has the entity carried out independent assessment/ evaluation
                  of the working of its policies by an external agency?
                  (Yes/No). If yes, provide name of the agency.
                </td>
                <td>{assignValue("id", "1-1-0-2", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-2", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-2", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-2", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-2", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-2", "P6") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-2", "P7") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-2", "P8") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-1-0-2", "P9") ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p>
          <strong>
            If answer to question (1) above is “No” i.e. not all Principles are
            covered by a policy, reasons to be stated
          </strong>
        </p>
        <div>
          <table className="table" border="1" cellpadding="10" cellspacing="0">
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Questions</th>

                <th>P1</th>
                <th>P2</th>
                <th>P3</th>
                <th>P4</th>
                <th>P5</th>
                <th>P6</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  The entity does not consider the Principles material to its
                  business (Yes/No)
                </td>
                <td>{assignValue("id", "1-2-0-0", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-0", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-0", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-0", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-0", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-0", "P6") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  The entity is not at a stage where it is in a position to
                  formulate and implement the policies on specified principles
                  (Yes/No)
                </td>
                <td>{assignValue("id", "1-2-0-1", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-1", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-1", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-1", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-1", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-1", "P6") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  The entity does not have the financial or/human and technical
                  resources available for the task (Yes/No)
                </td>
                <td>{assignValue("id", "1-2-0-2", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-2", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-2", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-2", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-2", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-2", "P6") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>
                  It is planned to be done in the next financial year (Yes/No)
                </td>
                <td>{assignValue("id", "1-2-0-3", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-3", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-3", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-3", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-3", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-3", "P6") ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Any other reason (please specify)</td>
                <td>{assignValue("id", "1-2-0-4", "P1") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-4", "P2") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-4", "P3") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-4", "P4") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-4", "P5") ? "Yes" : "No"}</td>
                <td>{assignValue("id", "1-2-0-4", "P6") ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ pageBreakBefore: "always" }}>
          <h4 style={{ color: "#004B88", fontSize: "20px", fontWeight: "800" }}>
            SECTION C: PRINCIPLE WISE PERFORMANCE DISCLOSURE
          </h4>
          <p>
            This section is aimed at helping entities demonstrate their
            performance in integrating the Principles and Core Elements with key
            processes and decisions. The information sought is categorized as
            “Essential” and “Leadership”. While the essential indicators are
            expected to be disclosed by every entity that is mandated to file
            this report, the leadership indicators may be voluntarily disclosed
            by entities which aspire to progress to a higher level in their
            quest to be socially, environmentally and ethically responsible
          </p>
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 1 Businesses should conduct and govern themselves with
            integrity, and in a manner that is Ethical, Transparent and
            Accountable
          </h3>
          <p>
            "Integrity is central to everything we do. We are committed to
            upholding the highest standards of ESG governance, from our Board to
            every level of leadership. This commitment is reflected in our
            sustainability risk monitoring, stringent data privacy measures, and
            transparent disclosures. We have established whistleblower and
            grievance redressal procedures to ensure fairness and
            accountability. Our governance approach is based on company values,
            aligning our purpose, vision, and mission with responsible business
            practices. We have implemented systems, policies, and checks to
            monitor governance throughout the organization."
          </p>

          <p>
            {" "}
            <strong>
              {" "}
              1. Percentage coverage by training and awareness programmes on any
              of the Principles during the financial year:
            </strong>
          </p>

          <table className="table" border="1" cellpadding="10" cellspacing="0">
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Segment</th>

                <th>Total number of training and awareness programmers he</th>
                <th>
                  Topics / principles covered under the training and its impact
                </th>
                <th>
                  %age of persons in respective category covered by the
                  awareness programmers
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Board of Directors</td>
                <td>{assignValue("id", "2-0-0-0", "Total")}</td>
                <td>{assignValue("id", "2-0-0-0", "Topics")}</td>
                <td>{assignValue("id", "2-0-0-0", "Total")}</td>
              </tr>
              <tr>
                <td>Key Managerial Personnel</td>
                <td>{assignValue("id", "2-0-0-1", "Total")}</td>
                <td>{assignValue("id", "2-0-0-1", "Topics")}</td>
                <td>{assignValue("id", "2-0-0-1", "Total")}</td>
              </tr>
              <tr>
                <td>Employees other than BoD and KMPs</td>
                <td>{assignValue("id", "2-0-0-2", "Total")}</td>
                <td>{assignValue("id", "2-0-0-2", "Topics")}</td>
                <td>{assignValue("id", "2-0-0-2", "Total")}</td>
              </tr>
              <tr>
                <td>Workers</td>
                <td>{assignValue("id", "2-0-0-3", "Total")}</td>
                <td>{assignValue("id", "2-0-0-3", "Topics")}</td>
                <td>{assignValue("id", "2-0-0-3", "Total")}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <p>
              <span>
                * During the year, six meetings (four quarterly meetings, one
                strategy meeting and one Independent Directors’ meeting) were
                held where Board is updated inter alia on ESG matters,
                applicable legal requirements, market trends etc., as part of
                Business Responsibility programs.
              </span>
              <br />
              <span>
                # Six e-learning courses with annual assessment mandatory as
                part of Business Responsibility Training programs.
              </span>
            </p>
          </div>

          <p>
            <strong>
              2. Details of fines / penalties /punishment/ award/ compounding
              fees/ settlement amount paid in proceedings (by the entity or by
              directors / KMPs) with regulators/ law enforcement agencies/
              judicial institutions, in the financial year, in the following
              format (Note: the entity shall make disclosures on the basis of
              materiality as specified in Regulation 30 of SEBI (Listing
              Obligations and Disclosure Obligations) Regulations, 2015 and as
              disclosed on the entity’s website):
            </strong>
          </p>

          <table border="1" cellpadding="10" cellspacing="0" width={"100%"}>
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Categories</th>
                <th>NGRBC Principle</th>
                <th>
                  Name of the regulatory/ enforcement agencies/ judicial
                  institutions
                </th>
                {/* <th>Brief of the Case</th> */}

                <th> Has an appeal been preferred? (Yes/No)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <strong>Monetary</strong>
                </td>
              </tr>
              <tr>
                <td>Penality Fine</td>
                <td>{assignValue("id", "2-0-1-1", "NGRBC")}</td>
                <td>{assignValue("id", "2-0-1-1", "Name")}</td>
                <td>{assignValue("id", "2-0-1-1", "Preffered")}</td>
              </tr>
              <tr>
                <td>Settlement</td>
                <td>{assignValue("id", "2-0-1-2", "NGRBC")}</td>
                <td>{assignValue("id", "2-0-1-2", "Name")}</td>
                <td>{assignValue("id", "2-0-1-2", "Preffered")}</td>
              </tr>
              <tr>
                <td>Compunding Fee</td>
                <td>{assignValue("id", "2-0-1-3", "NGRBC")}</td>
                <td>{assignValue("id", "2-0-1-3", "Name")}</td>
                <td>{assignValue("id", "2-0-1-3", "Preffered")}</td>
              </tr>

              <tr>
                <td>
                  <strong>Non-Monetary</strong>
                </td>
              </tr>
              <tr>
                <td>Imprisonment</td>
                <td>{assignValue("id", "2-0-1-5", "NGRBC")}</td>
                <td>{assignValue("id", "2-0-1-5", "Name")}</td>
                <td>{assignValue("id", "2-0-1-5", "Preffered")}</td>
              </tr>
              <tr>
                <td>Punishement</td>
                <td>{assignValue("id", "2-0-1-6", "NGRBC")}</td>
                <td>{assignValue("id", "2-0-1-6", "Name")}</td>
                <td>{assignValue("id", "2-0-1-6", "Preffered")}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              3. Of the instances disclosed in Question 2 above, details of the
              Appeal/Revision preferred in cases where monetary or non-monetary
              action has been appealed
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Case Details</th>
                <th>
                  Name of the regulatory/ enforcement Agencies/ judicial
                  institutions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{assignValue("id", "3-0-0-0-0", "Case")}</td>
                <td>
                  <td>{assignValue("id", "3-0-0-0-0", "Name")}</td>
                </td>
              </tr>
              <tr>
                <td>{assignValue("id", "3-0-0-0-1", "Case")}</td>
                <td>
                  <td>{assignValue("id", "3-0-0-0-1", "Name")}</td>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              4. Does the entity have an anti-corruption or anti-bribery policy?
              If yes, provide details in brief and if available, provide a
              weblink to the policy.
            </strong>
          </p>
          <p>{assignValue("id", "3-0-1-0-0", "value") || "NA"}</p>

          <p>
            <strong>
              5. Number of Directors/KMPs/employees against whom disciplinary
              action was taken by any law enforcement agency for the charges of
              bribery/corruption:
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Categories</th>
                <th>FY</th>
                {/* <th>FY</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Directors</td>
                <td>{assignValue("id", "3-0-2-0-0", "value")}</td>
                {/* <td></td> */}
              </tr>
              <tr>
                <td>KMPs</td>
                <td>{assignValue("id", "3-0-2-0-1", "value")}</td>
                {/* <td></td> */}
              </tr>
              <tr>
                <td>Employees</td>
                <td>{assignValue("id", "3-0-2-0-2", "value")}</td>
                {/* <td></td> */}
              </tr>
              <tr>
                <td>Workers</td>
                <td>{assignValue("id", "3-0-2-0-3", "value")}</td>
                {/* <td></td> */}
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              6. Details of complaints with regard to conflict of interest:
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Categories</th>
                <th>FY</th>
                {/* <th>FY</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Number of complaints received in relation to issues of
                  Conflict of Interest of the Directors
                </td>
                <td>{assignValue("id", "3-0-3-0-0", "value") || "NA"}</td>

                {/* <td></td> */}
              </tr>
              <tr>
                <td>
                  Number of complaints received in relation to issues of
                  Conflict of Interest of the KMPs
                </td>
                <td>{assignValue("id", "3-0-3-0-1", "value") || "NA"}</td>
                {/* <td></td> */}
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              7. Provide details of any corrective action taken or underway on
              issues related to fines / penalties / action taken by regulators/
              law enforcement agencies/ judicial institutions, on cases of
              corruption and conflicts of interest
            </strong>
          </p>
          <p>{assignValue("id", "3-1-4-0-0", "value") || "NA"}</p>

          <p>
            <strong>
              8. Number of days Account Payable (Accounts Payable * 365) / Cost
              of Goods & Services Produced in this format.{" "}
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Categories</th>
                <th>FY</th>
                {/* <th>FY</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Number of days of accounts payables</td>
                <td>{assignValue("id", "3-7-7-0-0", "value") || "NA"}</td>
                {/* <td></td> */}
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              9. Provide details of concentration of purchases and sales with
              trading houses, dealers and related parties along-with loans and
              advances & investments, with related parties in the following
              format:{" "}
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Parameters</th>
                <th>Metrics</th>
                <th> (Current Financial Year) </th>
                {/* <th> (Previous Financial Year) </th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="3">Concentrationof Purchases</td>
                <td colspan="1">
                  Purchases from trading houses as % of total purchases
                </td>
                <td>{assignValue("id", "3-5-5-0-0", "value") || "NA"}</td>
              </tr>
              <tr>
                <td colspan="1">
                  Purchases from trading houses as % of total purchases
                </td>
                <td>{assignValue("id", "3-5-5-0-1", "value") || "NA"}</td>
              </tr>
              <tr>
                <td colspan="1">
                  Purchases from top 10 trading houses as % of total purchases
                  from trading houses
                </td>
                <td>{assignValue("id", "3-5-5-0-2", "value") || "NA"}</td>
              </tr>
              <tr>
                <td rowspan="3">Concentration of Sales</td>
                <td colspan="1">
                  Sales to dealers/distributors as % of total sales
                </td>
                <td>{assignValue("id", "3-5-5-0-3", "value") || "NA"}</td>
              </tr>
              <tr>
                <td colspan="1">
                  Number of dealers/distributors to whom sales are made
                </td>
                <td>{assignValue("id", "3-5-5-0-4", "value") || "NA"}</td>
              </tr>
              <tr>
                <td colspan="1">
                  Sales to top 10 dealers/distributors as % of total sales to
                  dealers/ distributors
                </td>
                <td>{assignValue("id", "3-5-5-0-5", "value") || "NA"}</td>
              </tr>
              <tr>
                <td rowspan="3">Share of RPTs in</td>
                <td colspan="1">
                  Purchases (Purchases with related parties/Total Purchases)
                </td>
                <td>{assignValue("id", "3-5-5-0-6", "value") || "NA"}</td>
              </tr>
              <tr>
                <td colspan="1">
                  Sales (Sales to related parties/Total Sales)
                </td>
                <td>{assignValue("id", "3-5-5-0-7", "value") || "NA"}</td>
              </tr>
              <tr>
                <td colspan="1">
                  Loans & advances (Loans & advances given to related
                  parties/Total loans & advances)
                </td>
                <td>{assignValue("id", "3-5-5-0-8", "value") || "NA"}</td>
              </tr>
            </tbody>
          </table>
          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Leadership Indicators
          </h4>

          <p>
            <strong>
              1. Awareness programmes conducted for value chain partners on any
              of the Principles during the financial year:
            </strong>
          </p>

          <table
            className="table"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
            border="1"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Total number of awareness programmes held</th>
                <th>Topics / principles covered under the training</th>
                <th>
                  %age of value chain partners covered (by value of business
                  done with such partners) under the awareness programmes
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{assignValue("id", "2-0-2-0", "value")}</td>
                <td>{assignValue("id", "2-0-2-1", "value")}</td>
                <td>{assignValue("id", "2-0-2-2", "value")}</td>
              </tr>
            </tbody>
          </table>

          <p>
            Organizations conduct training and awareness programs for their
            supplier and vendor partners. Through various initiatives, they
            engage with suppliers to offer insights into their sustainability
            efforts and seek alignment with organizational goals. They also
            organize awareness programs covering sustainability and other
            aspects of business responsibility.
          </p>

          <p>
            Moreover, a Supplier Code of Conduct outlines expectations and
            standards for engagement with partners, covering key areas like
            human rights, compliance, ethical practices, data privacy, and more.
          </p>

          <p>
            In pursuit of transparency and collaboration, organizations actively
            integrate their vendors into their ESG initiatives. Looking ahead,
            they are committed to educating their value chain partners on ESG
            initiatives and providing opportunities for mutual support and
            collaboration.
          </p>

          <p>
            <strong>
              2. Does the entity have processes in place to avoid/manage
              conflict of interests involving members of the Board? (Yes/No) If
              yes, provide details of the same.
            </strong>
          </p>
          <p>{assignValue("id", "2-0-3-0", "value")}</p>
        </div>

        <div
          style={{
            pageBreakBefore: "always",
          }}
        >
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 2 Businesses should provide goods and services in a manner
            that is sustainable and safe
          </h3>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>
          <p>
            <strong>
              1. Percentage of R&D and capital expenditure (capex) investments
              in specific technologies to improve the environmental and social
              impacts of products and processes to total R&D and capex
              investments made by the entity, respectively.
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Categories</th>
                <th>FY</th>
                <th>
                  Details of improvements in environmental and social impacts
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>R&D</td>
                <td>{assignValue("id", "3-0-0-0", "Year")}</td>
                <td>{assignValue("id", "3-0-0-0", "Details")}</td>
              </tr>
              <tr>
                <td>Capex</td>
                <td>{assignValue("id", "3-0-0-1", "Year")}</td>
                <td>{assignValue("id", "3-0-0-1", "Details")}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              2. A. Does the entity have procedures in place for sustainable
              sourcing? (Yes/No)
            </strong>
          </p>
          <p>{assignValue("id", "3-0-4-0", "value")}</p>
          <p>
            <strong>
              B. If yes, what percentage of inputs were sourced sustainably?
            </strong>
          </p>
          <p>{assignValue("id", "3-0-4-1", "value")}</p>
          <p>
            <strong>
              3. Describe the processes in place to safely reclaim your products
              for reusing, recycling and disposing at the end of life, for (a)
              Plastics (including packaging) (b) E-waste (c) Hazardous waste and
              (d) other waste.
            </strong>
          </p>
          <p>{assignValue("id", "3-0-1-0", "value")}</p>
          <p>
            <strong>
              4. Whether Extended Producer Responsibility (EPR) is applicable to
              the entity’s activities (Yes / No). If yes, whether the waste
              collection plan is in line with the Extended Producer
              Responsibility (EPR) plan submitted to Pollution Control Boards?
              If not, provide steps taken to address the same.
            </strong>
          </p>
          <p>{assignValue("id", "3-0-4-3", "value")}</p>
        </div>
        <div>
          <div
            style={{
              pageBreakBefore: "always",
            }}
          >
            <h4
              style={{
                color: "#004B88",
                fontSize: "20px",
                fontWeight: "800",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Leadership Indicators
            </h4>

            <p>
              <strong>
                1. Has the entity conducted Life Cycle Perspective / Assessments
                (LCA) for any of its products (for manufacturing industry) or
                for its services (for service industry)? If yes, provide details
                in the following format?
              </strong>
            </p>

            <table
              className="table"
              border="1"
              cellpadding="10"
              cellspacing="0"
              width={"100%"}
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th>NIC Code </th>
                  <th>Name of Product /Service</th>
                  <th>% of total Turnover contributed</th>
                  <th>
                    Boundary for which the Life Cycle Perspective / Assessment
                    was conducted
                  </th>
                  <th>
                    Whether conducted by independent external agency (Yes/No)
                  </th>
                  <th>
                    Results communicated in public domain (Yes/No) If yes,
                    provide the web-link
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{assignValue("id", "3-0-2-0", "value")}</td>
                  <td>{assignValue("id", "3-0-2-1", "value")}</td>
                  <td>{assignValue("id", "3-0-2-2", "value")}</td>
                  <td>{assignValue("id", "3-0-2-3", "value")}</td>
                  <td>{assignValue("id", "3-0-2-4", "value")}</td>
                  <td>{assignValue("id", "3-0-2-5", "value")}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>
            <strong>
              2. If there are any significant social or environmental concerns
              and/or risks arising from production or disposal of your services,
              as identified in the Life Cycle Perspective/ Assessments (LCA) or
              through any other means, briefly describe the same along-with
              action taken to mitigate the same.
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Name of Product / Service </th>
                <th>Description of the risk / concern</th>
                <th>Action Taken </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{assignValue("id", "3-0-5-0", "value")}</td>
                <td>{assignValue("id", "3-0-5-1", "value")}</td>
                <td>{assignValue("id", "3-0-5-2", "value")}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              3. Percentage of recycled or reused input material to total
              material (by value) used in production (for manufacturing
              industry) or providing services (for service industry).
            </strong>
          </p>
          <p>{assignValue("id", "3-0-6-0", "value")}</p>

          <p>
            <strong>
              4. Of the products and packaging reclaimed at the end of life of
              products, amount (in metric tonnes) reused, recycled and safely
              disposed
            </strong>
          </p>
          <p>Reuse : {assignValue("id", "3-0-7-0", "value")}</p>
          <p>Recycling : {assignValue("id", "3-0-7-1", "value")}</p>
          <p>Disposed : {assignValue("id", "3-0-7-2", "value")}</p>
          <p>
            <strong>
              5. Reclaimed products and their packaging materials (as percentage
              of products sold) for product category.
            </strong>
          </p>
          <p>{assignValue("id", "3-0-3-0", "value")}</p>
          <p>{assignValue("id", "3-0-3-1", "value")}</p>
        </div>
        <div></div>

        <div style={{ pageBreakBefore: "always", breakInside: "auto" }}>
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 3 Businesses should respect and promote the well-being of
            all employees, including those in their value chains.
          </h3>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>
          <p>
            <strong>
              1. a. Details of measures for the well-being of employees:
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th rowspan="2">Category</th>
                <th rowspan="2">Total (A)</th>
                <th colspan="2">Health insurance</th>
                <th colspan="2">Accident insurance</th>
                <th colspan="2">Maternity benefits</th>
                <th colspan="2">Paternity benefits</th>
                <th colspan="2">Day Care facilities</th>
              </tr>
              <tr>
                <th>Number (B)</th>
                <th>% (B/A)</th>
                <th>Number (C)</th>
                <th>% (C/A)</th>
                <th>Number (D)</th>
                <th>% (D/A)</th>
                <th>Number (E)</th>
                <th>% (E/A)</th>
                <th>Number (F)</th>
                <th>% (F/A)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="11">Permanent Employees</td>
              </tr>
              <tr>
                <td>Male</td>
                <td>{assignValue("id", "4-0-2-1", "Value")}</td>
                <td>{assignValue("id", "4-0-2-4", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-4", "Number"),
                    assignValue("id", "4-0-2-1", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-2-5", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-5", "Number"),
                    assignValue("id", "4-0-2-1", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-2-6", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-6", "Number"),
                    assignValue("id", "4-0-2-1", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-2-7", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-7", "Number"),
                    assignValue("id", "4-0-2-1", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-2-8", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-8", "Number"),
                    assignValue("id", "4-0-2-1", "Value")
                  ).toFixed(2) || 0}
                </td>
              </tr>
              <tr>
                <td>Female</td>
                <td>{assignValue("id", "4-0-2-2", "Value")}</td>
                <td>{assignValue("id", "4-0-2-4", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-4", "Number"),
                    assignValue("id", "4-0-2-2", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-2-5", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-5", "Number"),
                    assignValue("id", "4-0-2-2", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-2-6", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-6", "Number"),
                    assignValue("id", "4-0-2-2", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-2-7", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-7", "Number"),
                    assignValue("id", "4-0-2-2", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-2-8", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-2-8", "Number"),
                    assignValue("id", "4-0-2-2", "Value")
                  ).toFixed(2) || 0}
                </td>
              </tr>
              <tr>
                <td>Totals</td>
                <td>
                  {Number(assignValue("id", "4-0-2-1", "Value")) +
                    Number(assignValue("id", "4-0-2-2", "Value"))}
                </td>
                <td>
                  {Number(assignValue("id", "4-0-2-4", "Number")) +
                    Number(assignValue("id", "4-0-2-4", "Number"))}
                </td>
                <td>
                  {getPercentageOfTwoNumber(
                    Number(assignValue("id", "4-0-2-4", "Number")) +
                      Number(assignValue("id", "4-0-2-4", "Number")),
                    Number(assignValue("id", "4-0-2-1", "Value")) +
                      Number(assignValue("id", "4-0-2-2", "Value"))
                  ).toFixed(2) || 0}
                </td>
                <td>
                  {Number(assignValue("id", "4-0-2-5", "Number")) +
                    Number(assignValue("id", "4-0-2-5", "Number"))}
                </td>
                <td>
                  {getPercentageOfTwoNumber(
                    Number(assignValue("id", "4-0-2-5", "Number")) +
                      Number(assignValue("id", "4-0-2-5", "Number")),
                    Number(assignValue("id", "4-0-2-1", "Value")) +
                      Number(assignValue("id", "4-0-2-2", "Value"))
                  ).toFixed(2) || 0}
                </td>
                <td>
                  {Number(assignValue("id", "4-0-2-6", "Number")) +
                    Number(assignValue("id", "4-0-2-6", "Number"))}
                </td>
                <td>
                  {getPercentageOfTwoNumber(
                    Number(assignValue("id", "4-0-2-6", "Number")) +
                      Number(assignValue("id", "4-0-2-6", "Number")),
                    Number(assignValue("id", "4-0-2-1", "Value")) +
                      Number(assignValue("id", "4-0-2-2", "Value"))
                  ).toFixed(2) || 0}
                </td>
                <td>
                  {Number(assignValue("id", "4-0-2-7", "Number")) +
                    Number(assignValue("id", "4-0-2-7", "Number"))}
                </td>
                <td>
                  {getPercentageOfTwoNumber(
                    Number(assignValue("id", "4-0-2-7", "Number")) +
                      Number(assignValue("id", "4-0-2-7", "Number")),
                    Number(assignValue("id", "4-0-2-1", "Value")) +
                      Number(assignValue("id", "4-0-2-2", "Value"))
                  ).toFixed(2) || 0}
                </td>
                <td>
                  {Number(assignValue("id", "4-0-2-8", "Number")) +
                    Number(assignValue("id", "4-0-2-8", "Number"))}
                </td>
                <td>
                  {getPercentageOfTwoNumber(
                    Number(assignValue("id", "4-0-2-8", "Number")) +
                      Number(assignValue("id", "4-0-2-8", "Number")),
                    Number(assignValue("id", "4-0-2-1", "Value")) +
                      Number(assignValue("id", "4-0-2-2", "Value"))
                  ).toFixed(2) || 0}
                </td>
              </tr>
              <tr>
                <td colspan="11">Other Than Permanent Employees</td>
              </tr>
              <tr>
                <td>Male</td>
                <td>{assignValue("id", "4-0-3-1", "Value")}</td>
                <td>{assignValue("id", "4-0-3-4", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-4", "Number"),
                    assignValue("id", "4-0-3-1", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-3-5", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-5", "Number"),
                    assignValue("id", "4-0-3-1", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-3-6", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-6", "Number"),
                    assignValue("id", "4-0-3-1", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-3-7", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-7", "Number"),
                    assignValue("id", "4-0-3-1", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-3-8", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-8", "Number"),
                    assignValue("id", "4-0-3-1", "Value")
                  ).toFixed(2) || 0}
                </td>
              </tr>
              <tr>
                <td>Female</td>
                <td>{assignValue("id", "4-0-3-2", "Value")}</td>
                <td>{assignValue("id", "4-0-3-4", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-4", "Number"),
                    assignValue("id", "4-0-3-2", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-3-5", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-5", "Number"),
                    assignValue("id", "4-0-3-2", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-3-6", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-6", "Number"),
                    assignValue("id", "4-0-3-2", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-3-7", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-7", "Number"),
                    assignValue("id", "4-0-3-2", "Value")
                  ).toFixed(2) || 0}
                </td>
                <td>{assignValue("id", "4-0-3-8", "Number")}</td>
                <td>
                  {getPercentageOfTwoNumber(
                    assignValue("id", "4-0-3-8", "Number"),
                    assignValue("id", "4-0-3-2", "Value")
                  ).toFixed(2) || 0}
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              2. Details of retirement benefits, for Current FY and Previous
              Financial Year.
            </strong>
          </p>

          <table
            className="table"
            border="1"
            width={"100%"}
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th rowspan="2">Benefits</th>
                <th colspan="3">FY</th>
                {/* <th colspan="3">FY</th> */}
              </tr>
              <tr>
                <th>No. of employees covered as a % of total employees</th>
                <th>No. of workers covered as a % of total workers</th>
                <th>Deducted and deposited with the authority (Y/N/N.A.)</th>
                {/* <th>No. of employees covered as a % of total employees</th>
                <th>No. of workers covered as a % of total workers</th>
                <th>Deducted and deposited with the authority (Y/N/N.A.)</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PF</td>
                <td>{assignValue("id", "4-0-4-0", "employees")}</td>
                <td>{assignValue("id", "4-0-4-0", "workers")}</td>
                <td>{assignValue("id", "4-0-4-0", "Deducted")}</td>
              </tr>
              <tr>
                <td>Gratuity</td>
                <td>{assignValue("id", "4-0-4-1", "employees")}</td>
                <td>{assignValue("id", "4-0-4-1", "workers")}</td>
                <td>{assignValue("id", "4-0-4-1", "Deducted")}</td>
              </tr>
              <tr>
                <td>ESI</td>
                <td>{assignValue("id", "4-0-4-2", "employees")}</td>
                <td>{assignValue("id", "4-0-4-2", "workers")}</td>
                <td>{assignValue("id", "4-0-4-2", "Deducted")}</td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p> Accessibility of workplaces</p>{" "}
          </strong>
          <strong>
            <p>
              3. Are the premises / offices of the entity accessible to
              differently abled employees and workers, as per the requirements
              of the Rights of Persons with Disabilities Act, 2016? If not,
              whether any steps are being taken by the entity in this regard.
            </p>
          </strong>
          <p>{assignValue("id", "4-0-5-0", "value")}</p>

          <strong>
            <p>
              4. Does the entity have an equal opportunity policy as per the
              Rights of Persons with Disabilities Act, 2016? If so, provide a
              web-link to the policy.
            </p>
          </strong>
          <p>{assignValue("id", "4-0-5-1", "value")}</p>

          <div
            style={{
              pageBreakBefore: "always",
            }}
          >
            <strong>
              <p>
                5. Return to work and Retention rates of permanent employees and
                workers that took parental leave.
              </p>
            </strong>

            <table
              className="table"
              border="1"
              cellpadding="10"
              cellspacing="0"
              width={"100%"}
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th>Categories </th>
                  <th>Permanent Employees</th>
                  <th>Permanent Workers </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="3">Males</td>
                </tr>
                <tr>
                  <td>Return to work rate</td>
                  <td>{assignValue("id", "4-0-6-1", "Males")}</td>
                  <td>{assignValue("id", "4-0-6-4", "Males")}</td>
                </tr>
                <tr>
                  <td>Retention Rate</td>
                  <td>{assignValue("id", "4-0-6-2", "Males")}</td>
                  <td>{assignValue("id", "4-0-6-5", "Males")}</td>
                </tr>
                <tr>
                  <td colSpan={3}>Females</td>
                </tr>
                <tr>
                  <td>Return to work rate</td>
                  <td>{assignValue("id", "4-0-6-1", "Males")}</td>
                  <td>{assignValue("id", "4-0-6-4", "Males")}</td>
                </tr>
                <tr>
                  <td>Retention Rate</td>
                  <td>{assignValue("id", "4-0-6-2", "Males")}</td>
                  <td>{assignValue("id", "4-0-6-5", "Males")}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <strong>
              <p>
                6. Is there a mechanism available to receive and redress
                grievances for the following categories of employees? If yes,
                give details of the mechanism in brief.
              </p>
            </strong>

            <table
              border="1"
              cellpadding="10"
              cellspacing="0"
              width={"100%"}
              className="table"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th>Parameters </th>
                  <th>Yes/No</th>
                  <th>If yes then give the details of mechanism in details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Employees ( Permanent + Contract)</td>
                  <td>{assignValue("id", "4-0-0-0", "Yes/No")}</td>
                  <td>{assignValue("id", "4-0-0-0", "details")}</td>
                </tr>
                <tr>
                  <td>Workers ( Permanent + Contract)</td>
                  <td>{assignValue("id", "4-0-0-1", "Yes/No")}</td>
                  <td>{assignValue("id", "4-0-0-1", "details")}</td>
                </tr>
              </tbody>
            </table>

            <div>
              <strong>
                <p>
                  7. Membership of employees and worker in association(s) or
                  Unions recognized by the listed entity:
                </p>
              </strong>

              <table
                border="1"
                cellpadding="10"
                cellspacing="0"
                width={"100%"}
                className="table"
              >
                <thead style={{ border: "1px solid #000" }}>
                  <tr>
                    <th>Category </th>
                    <th>Total employees in respective category (A)</th>
                    <th>
                      No. of employees in respective category, who are part of
                      association(s) or Union (B)
                    </th>
                    <th>% (B/A)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Permanent Employees</td>
                    <td>{assignValue("id", "4-0-7-0", "category_A")}</td>
                    <td>{assignValue("id", "4-0-7-0", "employees_B")}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-7-0", "employees_B"),
                        Number(assignValue("id", "4-0-7-0", "category_A"))
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>Male</td>
                    <td>{assignValue("id", "4-0-7-1", "category_A")}</td>
                    <td>{assignValue("id", "4-0-7-1", "employees_B")}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-7-1", "employees_B"),
                        Number(assignValue("id", "4-0-7-1", "category_A"))
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>Female</td>
                    <td>{assignValue("id", "4-0-7-2", "category_A")}</td>
                    <td>{assignValue("id", "4-0-7-2", "employees_B")}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-7-2", "employees_B"),
                        Number(assignValue("id", "4-0-7-2", "category_A"))
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>Others</td>
                    <td>{assignValue("id", "4-0-7-3", "category_A")}</td>
                    <td>{assignValue("id", "4-0-7-3", "employees_B")}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-7-3", "employees_B"),
                        Number(assignValue("id", "4-0-7-3", "category_A"))
                      ).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              style={{
                pageBreakBefore: "always",
              }}
            >
              <strong>
                <p>8. Details of training given to employees</p>
              </strong>

              <table
                border="1"
                cellpadding="10"
                cellspacing="0"
                width={"100%"}
                className="table"
              >
                <thead style={{ border: "1px solid #000" }}>
                  <tr>
                    {[
                      "Category",
                      "Total (A)",
                      "No. (B)",
                      "% (B/A)",
                      "No. (C)",
                      "% (C/A)",
                    ].map((cat) => (
                      <th key={cat}>{cat}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Male</td>
                    <td>{assignValue("id", "4-0-8-0", "Total (A)") || 0}</td>
                    <td>{assignValue("id", "4-0-8-0", "No. (B)") || 0}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-8-0", "No. (B)"),
                        Number(assignValue("id", "4-0-8-0", "Total (A)"))
                      ).toFixed(2) || 0}
                    </td>
                    <td>{assignValue("id", "4-0-8-0", "No. (C)") || 0}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-8-0", "No. (C)"),
                        Number(assignValue("id", "4-0-8-0", "Total (A)"))
                      ).toFixed(2) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Female</td>
                    <td>{assignValue("id", "4-0-8-1", "Total (A)") || 0}</td>
                    <td>{assignValue("id", "4-0-8-1", "No. (B)") || 0}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-8-1", "No. (B)"),
                        Number(assignValue("id", "4-0-8-1", "Total (A)"))
                      ).toFixed(2) || 0}
                    </td>
                    <td>{assignValue("id", "4-0-8-1", "No. (C)") || 0}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-8-1", "No. (C)"),
                        Number(assignValue("id", "4-0-8-1", "Total (A)"))
                      ).toFixed(2) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>{assignValue("id", "4-0-8-2", "Total (A)") || 0}</td>
                    <td>{assignValue("id", "4-0-8-2", "No. (B)") || 0}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-8-2", "No. (B)"),
                        Number(assignValue("id", "4-0-8-2", "Total (A)"))
                      ).toFixed(2) || 0}
                    </td>
                    <td>{assignValue("id", "4-0-8-2", "No. (C)") || 0}</td>
                    <td>
                      {getPercentageOfTwoNumber(
                        assignValue("id", "4-0-8-2", "No. (C)"),
                        Number(assignValue("id", "4-0-8-2", "Total (A)"))
                      ).toFixed(2) || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <strong>
            <p>Employees</p>
          </strong> */}

            {/* <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "8px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#e8f1fa" }}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Male
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}></td>
              </tr>
              <tr style={{ backgroundColor: "#e8f1fa" }}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Female
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}></td>
              </tr>
              <tr style={{ borderTop: "3px solid blue" }}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Total
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}></td>
              </tr>
            </tbody>
          </table> */}

            <strong>
              <p>
                9. Details of performance and career development reviews of
                employees and worker:
              </p>
            </strong>

            <table
              border="1"
              cellpadding="10"
              cellspacing="0"
              width={"100%"}
              className="table"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  {["Category", "Total (A)", "No. (B)", "% (B/A)"].map(
                    (cat) => (
                      <th key={cat}>{cat}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Males</td>
                  <td>{assignValue("id", "4-0-9-1", "Total (A)") || 0}</td>
                  <td>{assignValue("id", "4-0-9-1", "No. (B)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "4-0-9-1", "No. (B)"),
                      Number(assignValue("id", "4-0-9-1", "Total (A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td>Females</td>
                  <td>{assignValue("id", "4-0-9-2", "Total (A)") || 0}</td>
                  <td>{assignValue("id", "4-0-9-2", "No. (B)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "4-0-9-2", "No. (B)"),
                      Number(assignValue("id", "4-0-9-2", "Total (A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td>Others</td>
                  <td>{assignValue("id", "4-0-9-3", "Total (A)") || 0}</td>
                  <td>{assignValue("id", "4-0-9-3", "No. (B)") || 0}</td>
                  <td>
                    {getPercentageOfTwoNumber(
                      assignValue("id", "4-0-9-3", "No. (B)"),
                      Number(assignValue("id", "4-0-9-3", "Total (A)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    {Number(assignValue("id", "4-0-9-1", "Total (A)")) +
                      Number(assignValue("id", "4-0-9-2", "Total (A)")) +
                      Number(assignValue("id", "4-0-9-3", "Total (A)")) || 0}
                  </td>
                  <td>
                    {Number(assignValue("id", "4-0-9-1", "No. (B)")) +
                      Number(assignValue("id", "4-0-9-2", "No. (B)")) +
                      Number(assignValue("id", "4-0-9-3", "No. (B)")) || 0}
                  </td>
                  <td>
                    {getPercentageOfTwoNumber(
                      Number(assignValue("id", "4-0-9-1", "Total (A)")) +
                        Number(assignValue("id", "4-0-9-2", "Total (A)")) +
                        Number(assignValue("id", "4-0-9-3", "Total (A)")),
                      Number(assignValue("id", "4-0-9-1", "No. (B)")) +
                        Number(assignValue("id", "4-0-9-2", "No. (B)")) +
                        Number(assignValue("id", "4-0-9-3", "No. (B)"))
                    ).toFixed(2) || 0}
                  </td>
                </tr>
              </tbody>
            </table>

            <strong>
              <p>10. Health and safety management system:</p>
            </strong>
            <p>
              <strong>
                a. Whether an occupational health and safety management system
                has been implemented by the entity? (Yes/No). If yes, the
                coverage of such system?
              </strong>
            </p>
            <p>{assignValue("id", "4-0-10-0", "value")}</p>
            <p>
              <strong>
                b. What are the processes used to identify work-related hazards
                and assess risks on a routine and non-routine basis by the
                entity?
              </strong>
            </p>
            <p>{assignValue("id", "4-0-10-1", "value")}</p>
            <p>
              <strong>
                c. Whether you have processes for workers to report work-related
                hazards and to remove themselves from such risks. (Y/N)
              </strong>
            </p>
            <p>{assignValue("id", "4-0-10-2", "value")}</p>
            <p>
              <strong>
                d. Do the employees/worker of the entity have access to
                non-occupational medical and healthcare services? (Yes/No)
              </strong>
            </p>
            <p>{assignValue("id", "4-0-10-3", "value")}</p>
            <strong>
              <p>
                11. Details of safety related incidents, in the following
                format:
              </p>
            </strong>

            <table
              border="1"
              cellpadding="10"
              cellspacing="0"
              width={"100%"}
              className="table"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th>Safety Incident/Number </th>
                  <th>Category* </th>
                  <th>FY </th>
                  <th>FY </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p>
              <strong>
                12. Describe the measures taken by the entity to ensure a safe
                and healthy work place.
              </strong>
            </p>
            <p>{assignValue("id", "4-0-12-0", "value")}</p>

            <strong>
              <p>
                13. Number of Complaints on the following made by employees and
                workers:
              </p>
            </strong>

            <table
              border="1"
              cellpadding="10"
              cellspacing="0"
              width={"100%"}
              className="table"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  {[
                    "Categories",
                    "Filed during the year",
                    "Pending resolution at the end of year",
                    "Remarks",
                  ].map((dth) => (
                    <th key={dth}>{dth}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Working Condition</td>
                  <td>{assignValue("id", "4-0-13-0", "Filed")}</td>
                  <td>{assignValue("id", "4-0-13-0", "Pending")}</td>
                  <td>{assignValue("id", "4-0-13-0", "Remarks")}</td>
                </tr>
                <tr>
                  <td>Health & Safety</td>
                  <td>{assignValue("id", "4-0-13-0", "Filed")}</td>
                  <td>{assignValue("id", "4-0-13-0", "Pending")}</td>
                  <td>{assignValue("id", "4-0-13-0", "Remarks")}</td>
                </tr>
              </tbody>
            </table>

            <strong>
              <p>14. Assessments for the year:</p>
            </strong>

            <table
              border="1"
              cellpadding="10"
              cellspacing="0"
              width={"100%"}
              className="table"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr>
                  <th> Categories </th>
                  <th>
                    % of your plants and offices that were assessed (by entity
                    or statutory authorities orthird parties){" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Health and safety practices</td>
                  <td>{assignValue("id", "4-0-14-0", "plants")}</td>
                </tr>
                <tr>
                  <td>Working Conditions</td>
                  <td>{assignValue("id", "4-0-14-1", "plants")}</td>
                </tr>
              </tbody>
            </table>

            <p>
              <strong>
                15. . Provide details of any corrective action taken or underway
                to address safety-related incidents (if any) and on significant
                risks/concerns arising from assessments of health & safety
                practices and working conditions.
              </strong>
            </p>
            <p>{assignValue("id", "4-0-15-0", "value")}</p>
          </div>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Leadership Indicators
          </h4>

          <p>
            <strong>
              1. Does the entity extend any life insurance or any compensatory
              package in the event of death of (A) Employees (Y/N)?
            </strong>
          </p>
          <p>{assignValue("id", "4-0-1-0", "value")}</p>
          <p>
            <strong>
              2. Provide the measures undertaken by the entity to ensure that
              statutory dues have been deducted and deposited by the value chain
              partners.
            </strong>
          </p>
          <p>{assignValue("id", "4-1-0-0", "value") || "NA"}</p>
          <strong>
            <p>
              3. Provide the number of employees having suffered high
              consequence work- related injury/ ill-health/fatalities (as
              reported in Q11 of Essential Indicators above), who have been
              rehabilitated and placed in suitable employment or whose family
              members have been placed in suitable employment.
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th> Categories </th>

                <th>Total no. of affected employees/ workers </th>
                <th>
                  No. of employees/workers that are rehabilitated and placed in
                  suitable employment or whose family members have been placed
                  in suitable employment
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Employees</td>
                <td>{assignValue("id", "4-1-1-0", "Total")}</td>
                <td>{assignValue("id", "4-1-1-0", "employees")}</td>
              </tr>
              <tr>
                <td>Workers</td>
                <td>{assignValue("id", "4-1-1-1", "Total")}</td>
                <td>{assignValue("id", "4-1-1-1", "employees")}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              4. Does the entity provide transition assistance programs to
              facilitate continued employability and the management of career
              endings resulting from retirement or termination of employment?
              (Yes/No)
            </strong>
          </p>
          <p>{assignValue("id", "4-1-2-0", "value") || "NA"}</p>
          <strong>
            <p>5. Details on assessment of value chain partners:</p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th> Categories </th>
                <th>
                  % of value chain partners (by value of business done with such
                  partners) that were assessed
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Health and Safety</td>
                <td>{assignValue("id", "4-3-1-0", "value")}</td>
              </tr>
              <tr>
                <td>Health and Safety</td>
                <td>{assignValue("id", "4-3-1-1", "value")}</td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>
              6. Provide details of any corrective actions taken or underway to
              address significant risks/ concerns arising from assessments of
              health and safety practices and working conditions of value chain
              partners.
            </p>
          </strong>
        </div>

        <div
          style={{
            pageBreakBefore: "always",
          }}
        >
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 4: BUSINESSES SHOULD RESPECT THE INTERESTS OF AND BE
            RESPONSIVE TO ALL ITS STAKEHOLDERS
          </h3>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>
          <strong>
            <p>
              1. Describe the processes for identifying key stakeholder groups
              of the entity
            </p>
          </strong>

          <p>{assignValue("id", "5-0-0-0", "value") || "NA"}</p>

          <strong>
            <p>
              2. List stakeholder groups identified as key for your entity and
              the frequency of engagement with each stakeholder group.
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th> Stakeholder Group </th>

                <th>identified as Vulnerable & Marginalized Group (Yes/No)</th>
                <th>of communication</th>
                <th>
                  y of engagement (Annually/ Half yearly/ Quarterly / others –
                  please specify) Purpose
                </th>
                <th>
                  and scope of engagement including key topics and concerns
                  raised during such engagement
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{assignValue("id", "5-0-1-0", "value") || "NA"}</td>
                <td>{assignValue("id", "5-0-1-1", "value") || "NA"}</td>
                <td>{assignValue("id", "5-0-1-2", "value") || "NA"}</td>
                <td>{assignValue("id", "5-0-1-3", "value") || "NA"}</td>
                <td>{assignValue("id", "5-0-1-4", "value") || "NA"}</td>
              </tr>
            </tbody>
          </table>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Leadership Indicators
          </h4>

          <strong>
            <p>
              1. Provide the processes for consultation between stakeholders and
              the Board on economic, environmental, and social topics or if
              consultation is delegated, how is feedback from such consultations
              provided to the Board.
            </p>
          </strong>
          <p>{assignValue("id", "5-0-2-0", "value") || "NA"}</p>
          <p>
            <strong>
              2. Whether stakeholder consultation is used to support the
              identification and management of environmental, and social topics
              (Yes / No). If so, provide details of instances as to how the
              inputs received from stakeholders on these topics were
              incorporated into policies and activities of the entity
            </strong>
          </p>
          <p>{assignValue("id", "5-0-2-1", "value") || "NA"}</p>
          <p>
            <strong>
              3. Provide details of instances of engagement with, and actions
              taken to, address the concerns of vulnerable/ marginalized
              stakeholder groups
            </strong>
          </p>
          <p>{assignValue("id", "5-0-2-2", "value") || "NA"}</p>
        </div>

        <div
          style={{
            pageBreakBefore: "always",
          }}
        >
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 5: BUSINESSES SHOULD RESPECT AND PROMOTE HUMAN RIGHTS
          </h3>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>

          <strong>
            <p>
              1. Employees and workers who have been provided training on human
              rights issues and policy (ies) of the entity, in the following
              format:
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th> Categories </th>
                {["Total (A)", "No. of employees covered (B)", "% (B/A)"].map(
                  (tdh) => (
                    <th key={tdh}>{tdh}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4}>Employees</td>
              </tr>
              <tr>
                <td>Permanent</td>
                <td>{assignValue("id", "6-0-4-1", "Total (A)")}</td>
                <td>{assignValue("id", "6-0-4-1", "employees_B")}</td>
                <td>
                  {(Number(assignValue("id", "6-0-4-1", "employees_B")) /
                    Number(assignValue("id", "6-0-4-1", "Total (A)") || 1)) *
                    100}
                </td>
              </tr>
              <tr>
                <td>Other than Permanent</td>
                <td>{assignValue("id", "6-0-4-2", "Total (A)")}</td>
                <td>{assignValue("id", "6-0-4-2", "employees_B")}</td>
                <td>
                  {(Number(assignValue("id", "6-0-4-2", "employees_B")) /
                    Number(assignValue("id", "6-0-4-2", "Total (A)") || 1)) *
                    100}
                </td>
              </tr>
              <tr>
                <td>Total Employees</td>
                <td>{assignValue("id", "6-0-4-3", "Total (A)")}</td>
                <td>{assignValue("id", "6-0-4-3", "employees_B")}</td>
                <td>
                  {(Number(assignValue("id", "6-0-4-3", "employees_B")) /
                    Number(assignValue("id", "6-0-4-3", "Total (A)") || 1)) *
                    100}
                </td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>
              2. Details of minimum wages paid to employees, in the following
              format:
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                {[
                  "Categories",
                  "Total (A)",
                  "No. (B)",
                  "% (B/A))",
                  "No. (C)",
                  "% (C/A)",
                ].map((tdh) => (
                  <th key={tdh}>{tdh}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6}>Employees</td>
              </tr>
              <tr>
                <td>Permanent</td>
                <td>{assignValue("id", "6-0-5-1", "Total (A)") || 0}</td>
                <td>{assignValue("id", "6-0-5-1", "No. (B)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-1", "No. (B)")) /
                    (Number(assignValue("id", "6-0-5-1", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
                <td>{assignValue("id", "6-0-5-1", "No. (C)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-1", "No. (C)")) /
                    (Number(assignValue("id", "6-0-5-1", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
              </tr>
              <tr>
                <td>Male</td>
                <td>{assignValue("id", "6-0-5-2", "Total (A)") || 0}</td>
                <td>{assignValue("id", "6-0-5-2", "No. (B)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-2", "No. (B)")) /
                    (Number(assignValue("id", "6-0-5-2", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
                <td>{assignValue("id", "6-0-5-2", "No. (C)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-2", "No. (C)")) /
                    (Number(assignValue("id", "6-0-5-2", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
              </tr>
              <tr>
                <td>Female</td>
                <td>{assignValue("id", "6-0-5-3", "Total (A)") || 0}</td>
                <td>{assignValue("id", "6-0-5-3", "No. (B)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-3", "No. (B)")) /
                    (Number(assignValue("id", "6-0-5-3", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
                <td>{assignValue("id", "6-0-5-3", "No. (C)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-3", "No. (C)")) /
                    (Number(assignValue("id", "6-0-5-3", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
              </tr>
              <tr>
                <td>Others</td>
                <td>{assignValue("id", "6-0-5-4", "Total (A)") || 0}</td>
                <td>{assignValue("id", "6-0-5-4", "No. (B)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-4", "No. (B)")) /
                    (Number(assignValue("id", "6-0-5-4", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
                <td>{assignValue("id", "6-0-5-4", "No. (C)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-4", "No. (C)")) /
                    (Number(assignValue("id", "6-0-5-4", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
              </tr>
              <tr>
                <tr colSpan="6">Other than permanent</tr>
              </tr>
              <tr>
                <td>Male</td>
                <td>{assignValue("id", "6-0-5-6", "Total (A)") || 0}</td>
                <td>{assignValue("id", "6-0-5-6", "No. (B)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-6", "No. (B)")) /
                    (Number(assignValue("id", "6-0-5-6", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
                <td>{assignValue("id", "6-0-5-6", "No. (C)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-6", "No. (C)")) /
                    (Number(assignValue("id", "6-0-5-6", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
              </tr>
              <tr>
                <td>Female</td>
                <td>{assignValue("id", "6-0-5-7", "Total (A)") || 0}</td>
                <td>{assignValue("id", "6-0-5-7", "No. (B)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-7", "No. (B)")) /
                    (Number(assignValue("id", "6-0-5-7", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
                <td>{assignValue("id", "6-0-5-7", "No. (C)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-7", "No. (C)")) /
                    (Number(assignValue("id", "6-0-5-7", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
              </tr>
              <tr>
                <td>Others</td>
                <td>{assignValue("id", "6-0-5-8", "Total (A)") || 0}</td>
                <td>{assignValue("id", "6-0-5-8", "No. (B)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-8", "No. (B)")) /
                    (Number(assignValue("id", "6-0-5-8", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
                <td>{assignValue("id", "6-0-5-8", "No. (C)") || 0}</td>
                <td>
                  {(Number(assignValue("id", "6-0-5-8", "No. (C)")) /
                    (Number(assignValue("id", "6-0-5-8", "Total (A)")) || 1)) *
                    100 || 0}
                </td>
              </tr>
            </tbody>
          </table>
          <strong>
            <p>3. Details of remuneration/salary/wages</p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Categories</th>
                <th>Number </th>
                <th>
                  Median remuneration/ salary/ wages of respective category
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Board of Directors (BoD)</td>
                <td>{assignValue("id", "6-0-6-0", "Number")}</td>
                <td>{assignValue("id", "6-0-6-0", "value")}</td>
              </tr>
              <tr>
                <td>Key Managerial Personnel</td>
                <td>{assignValue("id", "6-0-6-1", "Number")}</td>
                <td>{assignValue("id", "6-0-6-1", "value")}</td>
              </tr>
              <tr>
                <td>Employees other than BoD and KMP</td>
                <td>{assignValue("id", "6-0-6-2", "Number")}</td>
                <td>{assignValue("id", "6-0-6-2", "value")}</td>
              </tr>
              <tr>
                <td>Workers</td>
                <td>{assignValue("id", "6-0-6-3", "Number")}</td>
                <td>{assignValue("id", "6-0-6-3", "value")}</td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>
              4. Do you have a focal point (Individual/ Committee) responsible
              for addressing human rights impacts or issues caused or
              contributed to by the business? (Yes/No)
            </p>
          </strong>

          <p>{assignValue("id", "6-0-0-0", "value")}</p>

          <strong>
            <p>
              5. Describe the internal mechanisms in place to redress grievances
              related to human rights issues
            </p>
          </strong>
          <p>{assignValue("id", "6-0-0-1", "value")}</p>

          <strong>
            <p>
              6. Number of Complaints on the following made by employees and
              workers:
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Title</th>
                {[
                  "Filed during the year",
                  "Pending resolution at the end of year",
                  "Remarks",
                ].map((tdh) => (
                  <th key={tdh}>{tdh}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sexual Harassment</td>
                <td>{assignValue("id", "6-0-1-0", "Filed")}</td>
                <td>{assignValue("id", "6-0-1-0", "Pending")}</td>
                <td>{assignValue("id", "6-0-1-0", "Remarks")}</td>
              </tr>
              <tr>
                <td>Discrimination at workplace</td>
                <td>{assignValue("id", "6-0-1-1", "Filed")}</td>
                <td>{assignValue("id", "6-0-1-1", "Pending")}</td>
                <td>{assignValue("id", "6-0-1-1", "Remarks")}</td>
              </tr>
              <tr>
                <td>Child Labour</td>
                <td>{assignValue("id", "6-0-1-2", "Filed")}</td>
                <td>{assignValue("id", "6-0-1-2", "Pending")}</td>
                <td>{assignValue("id", "6-0-1-2", "Remarks")}</td>
              </tr>
              <tr>
                <td>Forced Labour/Involuntary Labour</td>
                <td>{assignValue("id", "6-0-1-3", "Filed")}</td>
                <td>{assignValue("id", "6-0-1-3", "Pending")}</td>
                <td>{assignValue("id", "6-0-1-3", "Remarks")}</td>
              </tr>
              <tr>
                <td>Wages</td>
                <td>{assignValue("id", "6-0-1-4", "Filed")}</td>
                <td>{assignValue("id", "6-0-1-4", "Pending")}</td>
                <td>{assignValue("id", "6-0-1-4", "Remarks")}</td>
              </tr>
              <tr>
                <td>Other human</td>
                <td>{assignValue("id", "6-0-1-5", "Filed")}</td>
                <td>{assignValue("id", "6-0-1-5", "Pending")}</td>
                <td>{assignValue("id", "6-0-1-5", "Remarks")}</td>
              </tr>
              <tr>
                <td>Rights related issues</td>
                <td>{assignValue("id", "6-0-1-6", "Filed")}</td>
                <td>{assignValue("id", "6-0-1-6", "Pending")}</td>
                <td>{assignValue("id", "6-0-1-6", "Remarks")}</td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>
              7. Complaints filed under the Sexual Harassment of Women at
              Workplace (Prevention, Prohibition and Redressal) Act, 2013 in the
              following format:
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th>Title</th>
                <th>(Current Financial Year)</th>
                {/* {["(Current Financial Year)"].map((tdh) => (
                  <th key={tdh}>{tdh}</th>
                ))} */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Total Complaints reported under Sexual Harassment on of Women
                  at Workplace (Prevention, Prohibition and Redressal) Act, 2013
                  (POSH)
                </td>
                <td>{assignValue("id", "6-0-8-0", "value") || "NA"}</td>
              </tr>
              <tr>
                <td>Complaints on POSH as a % of female employees</td>
                <td>{assignValue("id", "6-0-8-1", "value") || "NA"}</td>
              </tr>
              <tr>
                <td>Complaints on POSH upheld</td>
                <td>{assignValue("id", "6-0-8-2", "value") || "NA"}</td>
              </tr>
            </tbody>
          </table>
          <strong>
            <p>
              8. Mechanisms to prevent adverse consequences to the complainant
              in discrimination and harassment cases
            </p>
          </strong>
          <p>{assignValue("id", "6-0-7-0", "value")}</p>

          <strong>
            <p>
              9. Do human rights requirements form part of your business
              agreements and contracts? (Yes/No)
            </p>
          </strong>
          <p>{assignValue("id", "6-0-7-1", "value")}</p>

          <strong>
            <p>10. Assessments for the year:</p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr>
                <th></th>
                <th>
                  % Of your plants and offices that were assessed (By entity or
                  statutory authorities or third parties){" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Child Labor</td>
                <td>{assignValue("id", "6-0-9-0", "plants") || "NA"}</td>
              </tr>
              <tr>
                <td>Forced/involuntary Labor</td>
                <td>{assignValue("id", "6-0-9-1", "plants") || "NA"}</td>
              </tr>
              <tr>
                <td>Sexual harassment</td>
                <td>{assignValue("id", "6-0-9-2", "plants") || "NA"}</td>
              </tr>
              <tr>
                <td>Discrimination at workplace</td>
                <td>{assignValue("id", "6-0-9-3", "plants") || "NA"}</td>
              </tr>
              <tr>
                <td>Wages</td>
                <td>{assignValue("id", "6-0-9-4", "plants") || "NA"}</td>
              </tr>
              <tr>
                <td>Others- please specify</td>
                <td>{assignValue("id", "6-0-9-5", "plants") || "NA"}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              11. Provide details of any corrective actions taken or underway to
              address significant risks/ concerns arising from the assessments
              at Question 9. above.
            </strong>
          </p>
          <p>{assignValue("id", "4-0-15-0", "value")}</p>
        </div>

        <div
          style={{
            pageBreakBefore: "always",
            pageBreakInside: "avoid",
          }}
        >
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 6: BUSINESSES SHOULD RESPECT AND MAKE EFFORTS TO PROTECT
            AND RESTORE THE ENVIRONMENT
          </h3>

          <p>
            As environmental challenges such as climate change, biodiversity
            loss, and natural resource depletion grow more urgent, our
            organization takes proactive measures to address them. Our
            initiatives focus on climate change mitigation, water efficiency,
            resource management, pollution control, and waste reduction. We are
            dedicated to ambitious goals, including achieving net-zero emissions
            and becoming water-positive within defined timelines, reflecting our
            commitment to environmental sustainability.
          </p>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>
          <p>
            1. . Details of total energy consumption (in Joules or multiples)
            and energy intensity, in the following format:
          </p>

          <p>
            2. Does the entity have any sites / facilities identified as
            designated consumers (DCs) under the Performance, Achieve and Trade
            (PAT) Scheme of the Government of India? (Y/N) If yes, disclose
            whether targets set under the PAT scheme have been achieved. In case
            targets have not been achieved, provide the remedial action taken,
            if any
          </p>

          <strong>
            <p style={{ marginBottom: "0px" }}>3. Water stewardships</p>
            <p>a. Details on Water Withdrawal</p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                <th>Sr.No </th>
                <th>Parameter </th>
                <th>Unit</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Surface water</td>
                <td>{assignValue("id", "7-0-0-0", "Unit")}</td>
                <td>{assignValue("id", "7-0-0-0", "Current Value")}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Groundwater</td>
                <td>{assignValue("id", "7-0-0-1", "Unit")}</td>
                <td>{assignValue("id", "7-0-0-1", "Current Value")}</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Third party water</td>
                <td>{assignValue("id", "7-0-0-2", "Unit")}</td>
                <td>{assignValue("id", "7-0-0-2", "Current Value")}</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Produced Water</td>
                <td>{assignValue("id", "7-0-0-3", "Unit")}</td>
                <td>{assignValue("id", "7-0-0-3", "Current Value")}</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Seawater / desalinated water</td>
                <td>{assignValue("id", "7-0-0-4", "Unit")}</td>
                <td>{assignValue("id", "7-0-0-4", "Current Value")}</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Others</td>
                <td>{assignValue("id", "7-0-0-5", "Unit")}</td>
                <td>{assignValue("id", "7-0-0-5", "Current Value")}</td>
              </tr>
              <tr style={{ backgroundColor: "lightgrey" }}>
                <td></td>
                <td colspan={2}>Totals</td>
                <td>
                  {Number(assignValue("id", "7-0-0-0", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-0-1", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-0-2", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-0-3", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-0-4", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-0-5", "Current Value") || 0)}
                </td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>b. Details on Water Discharge</p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                <th>Sr.No </th>
                <th>Parameter </th>
                <th>Unit</th>
                <th>Values </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Surface water</td>
                <td>{assignValue("id", "7-0-1-0", "Unit")}</td>
                <td>{assignValue("id", "7-0-1-0", "Current Value")}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Groundwater</td>
                <td>{assignValue("id", "7-0-1-1", "Unit")}</td>
                <td>{assignValue("id", "7-0-1-1", "Current Value")}</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Third party water</td>
                <td>{assignValue("id", "7-0-1-2", "Unit")}</td>
                <td>{assignValue("id", "7-0-1-2", "Current Value")}</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Produced Water</td>
                <td>{assignValue("id", "7-0-1-3", "Unit")}</td>
                <td>{assignValue("id", "7-0-1-3", "Current Value")}</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Seawater / desalinated water</td>
                <td>{assignValue("id", "7-0-1-4", "Unit")}</td>
                <td>{assignValue("id", "7-0-1-4", "Current Value")}</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Others</td>
                <td>{assignValue("id", "7-0-1-5", "Unit")}</td>
                <td>{assignValue("id", "7-0-1-5", "Current Value")}</td>
              </tr>
              <tr style={{ backgroundColor: "lightgrey" }}>
                <td></td>
                <td colspan={2}>Totals</td>
                <td>
                  {Number(assignValue("id", "7-0-1-0", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-1-1", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-1-2", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-1-3", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-1-4", "Current Value") || 0) +
                    Number(assignValue("id", "7-0-1-5", "Current Value") || 0)}
                </td>
              </tr>
            </tbody>
          </table>

          <div
            style={{
              pageBreakBefore: "always",
            }}
          >
            <strong>
              <p>c. Total volume of water consumption </p>
            </strong>

            <table
              border="1"
              cellpadding="10"
              cellspacing="0"
              width={"100%"}
              className="table"
            >
              <thead style={{ border: "1px solid #000" }}>
                <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                  <th>3 </th>
                  <th>Parameter</th>
                  <th>Unit</th>
                  <th>Values </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>i</td>
                  <td>Total Fresh Water Consumption : Plant</td>
                  <td>{assignValue("id", "7-0-2-0", "Unit")}</td>
                  <td>{assignValue("id", "7-0-2-0", "Current Value")}</td>
                </tr>
                <tr>
                  <td>ii</td>
                  <td>Recycled Water consumption</td>
                  <td>{assignValue("id", "7-0-2-1", "Unit")}</td>
                  <td>{assignValue("id", "7-0-2-1", "Current Value")}</td>
                </tr>
                <tr>
                  <td>iii</td>
                  <td>Quantum of Effluent Generated (ETP)</td>
                  <td>{assignValue("id", "7-0-2-2", "Unit")}</td>
                  <td>{assignValue("id", "7-0-2-2", "Current Value")}</td>
                </tr>
                <tr>
                  <td>iv</td>
                  <td>Quantum of Effluent Recycled (ETP)</td>
                  <td>{assignValue("id", "7-0-2-3", "Unit")}</td>
                  <td>{assignValue("id", "7-0-2-3", "Current Value")}</td>
                </tr>
                <tr>
                  <td>v</td>
                  <td>Others</td>
                  <td>{assignValue("id", "7-0-2-4", "Unit")}</td>
                  <td>{assignValue("id", "7-0-2-4", "Current Value")}</td>
                </tr>
                <tr style={{ backgroundColor: "lightgrey" }}>
                  <td></td>
                  <td colspan={2}>Totals</td>
                  <td>
                    {Number(
                      assignValue("id", "7-0-2-0", "Current Value") || 0
                    ) +
                      Number(
                        assignValue("id", "7-0-2-1", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-0-2-2", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-0-2-3", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-0-2-4", "Current Value") || 0
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <strong>
            <p>Waste Management </p>
          </strong>
          <p>Type of waste Generated</p>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr style={{ backgroundColor: "#FFFDD0" }}>
                <th>Srno</th>
                <th>Title</th>
                <th>Unit</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>Hazardous</td>
                <td>{assignValue("id", "7-1-0-0", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-0", "Current Value")}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>Non-hazardous</td>
                <td>{assignValue("id", "7-1-0-1", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-1", "Current Value")}</td>
              </tr>
              <tr>
                <td>iii</td>
                <td>Plastic waste</td>
                <td>{assignValue("id", "7-1-0-2", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-2", "Current Value")}</td>
              </tr>
              <tr>
                <td>iv</td>
                <td>E-waste</td>
                <td>{assignValue("id", "7-1-0-3", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-3", "Current Value")}</td>
              </tr>
              <tr>
                <td>v</td>
                <td>Bio-medical waste</td>
                <td>{assignValue("id", "7-1-0-4", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-4", "Current Value")}</td>
              </tr>
              <tr>
                <td>vi</td>
                <td>Construction and demolition waste</td>
                <td>{assignValue("id", "7-1-0-5", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-5", "Current Value")}</td>
              </tr>
              <tr>
                <td>vii</td>
                <td>Battery waste</td>
                <td>{assignValue("id", "7-1-0-6", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-6", "Current Value")}</td>
              </tr>
              <tr>
                <td>viii</td>
                <td>Radioactive waste</td>
                <td>{assignValue("id", "7-1-0-7", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-7", "Current Value")}</td>
              </tr>
              <tr>
                <td>ix</td>
                <td>Other</td>
                <td>{assignValue("id", "7-1-0-8", "Unit")}</td>
                <td>{assignValue("id", "7-1-0-8", "Current Value")}</td>
              </tr>
              <tr style={{ backgroundColor: "lightgrey" }}>
                <td></td>
                <td colspan={2}>Totals</td>
                <td>
                  {Number(assignValue("id", "7-1-0-0", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-1", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-2", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-3", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-4", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-5", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-6", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-7", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-8", "Current Value") || 0)}
                </td>
              </tr>
            </tbody>
          </table>

          <p>Waste Diverted from Disposal</p>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr style={{ backgroundColor: "#FFFDD0" }}>
                <th>Srno</th>
                <th>Title</th>
                <th>Unit</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>Hazardous Waste</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Reuse</td>
                <td>{assignValue("id", "7-1-1-1", "Unit")}</td>
                <td>{assignValue("id", "7-1-1-1", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Composting</td>
                <td>{assignValue("id", "7-1-1-2", "Unit")}</td>
                <td>{assignValue("id", "7-1-1-2", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Recycling</td>
                <td>{assignValue("id", "7-1-1-3", "Unit")}</td>
                <td>{assignValue("id", "7-1-1-3", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - other</td>
                <td>{assignValue("id", "7-1-1-4", "Unit")}</td>
                <td>{assignValue("id", "7-1-1-4", "Current Value")}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>Non - Hazardous</td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>*</td>
                <td>Non-hazardous - Reuse</td>
                <td>{assignValue("id", "7-1-1-6", "Unit")}</td>
                <td>{assignValue("id", "7-1-1-6", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Non-hazardous - Composting</td>
                <td>{assignValue("id", "7-1-1-7", "Unit")}</td>
                <td>{assignValue("id", "7-1-1-7", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Non-hazardous - Recycling</td>
                <td>{assignValue("id", "7-1-1-8", "Unit")}</td>
                <td>{assignValue("id", "7-1-1-8", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Non-hazardous - other</td>
                <td>{assignValue("id", "7-1-2-9", "Unit")}</td>
                <td>{assignValue("id", "7-1-2-9", "Current Value")}</td>
              </tr>
              <tr>
                <td>iii</td>
                <td>E-waste</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>*</td>
                <td> E-waste - Recycle</td>
                <td>{assignValue("id", "7-1-2-11", "Unit")}</td>
                <td>{assignValue("id", "7-1-2-11", "Current Value")}</td>
              </tr>

              <tr style={{ backgroundColor: "lightgrey" }}>
                <td></td>
                <td colspan={2}>Totals</td>
                <td>
                  {Number(assignValue("id", "7-1-1-1", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-1-2", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-1-3", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-1-4", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-1-5", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-1-6", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-1-8", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-1-9", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-2-9", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-2-11", "Current Value") || 0)}
                </td>
              </tr>
            </tbody>
          </table>

          <p>Waste directed to Disposal</p>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr style={{ backgroundColor: "#FFFDD0" }}>
                <th>Srno</th>
                <th>Title</th>
                <th>Unit</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>Hazardous Waste</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Incineration With Energy recovery</td>
                <td>{assignValue("id", "7-1-3-1", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-1", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Incineration Without Recovery</td>
                <td>{assignValue("id", "7-1-3-2", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-2", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Landfilling</td>
                <td>{assignValue("id", "7-1-3-3", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-3", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Co-processing</td>
                <td>{assignValue("id", "7-1-3-4", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-4", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Deep well injection</td>
                <td>{assignValue("id", "7-1-3-5", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-5", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Hazardous waste - Other</td>
                <td>{assignValue("id", "7-1-3-6", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-6", "Current Value")}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>Non - Hazardous</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>*</td>
                <td>Non-Hazardous - Incineration With Energy recovery</td>
                <td>{assignValue("id", "7-1-3-8", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-8", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Non-Hazardous - Incineration Without Recovery</td>
                <td>{assignValue("id", "7-1-3-9", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-9", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Non-Hazardous - Landfilling</td>
                <td>{assignValue("id", "7-1-3-10", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-10", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Non-Hazardous - Deep well injection</td>
                <td>{assignValue("id", "7-1-3-11", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-11", "Current Value")}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Non-Hazardous - Other</td>
                <td>{assignValue("id", "7-1-3-12", "Unit")}</td>
                <td>{assignValue("id", "7-1-3-12", "Current Value")}</td>
              </tr>
              <tr style={{ backgroundColor: "lightgrey" }}>
                <td></td>
                <td colspan={2}>Totals</td>
                <td>
                  {Number(assignValue("id", "7-1-3-1", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-3-2", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-3-3", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-3-4", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-3-5", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-3-6", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-3-8", "Current Value") || 0) +
                    Number(assignValue("id", "7-1-0-9", "Current Value") || 0) +
                    Number(
                      assignValue("id", "7-1-0-10", "Current Value") || 0
                    ) +
                    Number(
                      assignValue("id", "7-1-0-11", "Current Value") || 0
                    ) +
                    Number(assignValue("id", "7-1-0-12", "Current Value") || 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Emission Managements
          </h4>

          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            1.Details of Scope 1,2,3 Emissions
          </h3>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                <th>Sr.No </th>
                <th>Parameter </th>
                <th>Unit</th>
                <th>Target- Monthly </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>Scope 1 Emission</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Stationary combustion </td>
                <td>{assignValue("id", "7-4-0-1", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-0-1", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Mobile combustion </td>
                <td>{assignValue("id", "7-4-0-2", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-0-2", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Fugitive emission from air conditioning </td>
                <td>{assignValue("id", "7-4-0-3", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-0-3", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>Revenue </td>
                <td>{assignValue("id", "7-4-0-4", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-0-4", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>iii</td>
                <td>Scope-1 Emission Intensity/Revenue </td>
                <td>{assignValue("id", "7-4-0-5", "Unit") || "NA"}</td>
                <td>
                  {(
                    (Number(
                      assignValue("id", "7-4-0-1", "Current Value") || 0
                    ) +
                      Number(
                        assignValue("id", "7-4-0-2", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-0-3", "Current Value") || 0
                      )) /
                    Number(assignValue("id", "7-4-0-4", "Current Value") || 1)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>iv</td>
                <td>
                  Biogenic emissions data (Optional not include in Scope-1){" "}
                </td>
                <td>{assignValue("id", "7-4-0-6", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-0-6", "Current Value") || "0"}</td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                <th>Sr.No </th>
                <th>Parameter </th>
                <th>Unit</th>
                <th>Target- Monthly </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>Scope 2 Emission</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Purchased Electricity </td>
                <td>{assignValue("id", "7-4-1-1", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-1-1", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Purchased Steam </td>
                <td>{assignValue("id", "7-4-1-2", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-1-2", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Purchased Heating & Cooling </td>
                <td>{assignValue("id", "7-4-1-3", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-1-3", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Others </td>
                <td>{assignValue("id", "7-4-1-4", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-1-4", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>Scope-2 Emission Intensity/Revenue </td>
                <td>{assignValue("id", "7-4-1-5", "Unit") || "NA"}</td>
                <td>
                  {(
                    (Number(
                      assignValue("id", "7-4-1-1", "Current Value") || 0
                    ) +
                      Number(
                        assignValue("id", "7-4-1-2", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-1-3", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-1-4", "Current Value") || 0
                      )) /
                    Number(assignValue("id", "7-4-0-4", "Current Value") || 1)
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid #000" }}>
              <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                <th>Sr.No </th>
                <th>Parameter </th>
                <th>Unit</th>
                <th>Target- Monthly </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>
                  <strong>Upstream</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>*</td>
                <td>Purchased goods and services </td>
                <td>{assignValue("id", "7-4-2-1", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-1", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Capital Goods </td>
                <td>{assignValue("id", "7-4-2-2", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-2", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>
                  Fuel- and energy-related activities (not included in Scope 1
                  or Scope 2{" "}
                </td>
                <td>{assignValue("id", "7-4-2-3", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-3", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Employee commute </td>
                <td>{assignValue("id", "7-4-2-4", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-4", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Business travel </td>
                <td>{assignValue("id", "7-4-2-5", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-5", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Upstream transportation & distribution </td>
                <td>{assignValue("id", "7-4-2-6", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-6", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Upstream leased assets </td>
                <td>{assignValue("id", "7-4-2-7", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-7", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Waste generated in operations </td>
                <td>{assignValue("id", "7-4-2-8", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-8", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>
                  <strong>DownStream</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>*</td>
                <td>Downstream transportation and distribution </td>
                <td>{assignValue("id", "7-4-2-10", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-10", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Processing of sold products </td>
                <td>{assignValue("id", "7-4-2-11", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-11", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Use of sold products </td>
                <td>{assignValue("id", "7-4-2-12", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-12", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>End-of-life treatment of sold products </td>
                <td>{assignValue("id", "7-4-2-13", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-13", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Downstream leased assets </td>
                <td>{assignValue("id", "7-4-2-14", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-14", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Franchises </td>
                <td>{assignValue("id", "7-4-2-15", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-15", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>*</td>
                <td>Investments </td>
                <td>{assignValue("id", "7-4-2-16", "Unit") || "NA"}</td>
                <td>{assignValue("id", "7-4-2-16", "Current Value") || "0"}</td>
              </tr>
              <tr>
                <td>iii</td>
                <td>Scope-3 Emission Intensity/Revenue </td>
                <td>{assignValue("id", "7-4-2-17", "Unit") || "NA"}</td>
                <td>
                  {(
                    (Number(
                      assignValue("id", "7-4-2-1", "Current Value") || 0
                    ) +
                      Number(
                        assignValue("id", "7-4-2-2", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-3", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-4", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-5", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-6", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-7", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-8", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-10", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-11", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-12", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-13", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-14", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-15", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-16", "Current Value") || 0
                      )) /
                    Number(assignValue("id", "7-4-0-4", "Current Value") || 1)
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>Total Emission Data</p>
          </strong>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                <th>Sr.No </th>
                <th>Parameter </th>
                <th>Unit</th>
                <th>Target- Monthly </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>Scope-1</td>
                <td>{assignValue("id", "7-4-3-0", "Unit")}</td>
                <td>
                  {Number(assignValue("id", "7-4-0-1", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-0-2", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-0-3", "Current Value") || 0)}
                </td>
              </tr>
              <tr>
                <td>ii</td>
                <td>Scope-2</td>
                <td>{assignValue("id", "7-4-3-1", "Unit")}</td>
                <td>
                  {Number(assignValue("id", "7-4-1-1", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-1-2", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-1-3", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-1-4", "Current Value") || 0)}
                </td>
              </tr>
              <tr>
                <td>iii</td>
                <td>Scope-3</td>
                <td>{assignValue("id", "7-4-3-2", "Unit")}</td>
                <td>
                  {Number(assignValue("id", "7-4-2-1", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-2-2", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-2-3", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-2-4", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-2-5", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-2-6", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-2-7", "Current Value") || 0) +
                    Number(assignValue("id", "7-4-2-8", "Current Value") || 0) +
                    Number(
                      assignValue("id", "7-4-2-10", "Current Value") || 0
                    ) +
                    Number(
                      assignValue("id", "7-4-2-11", "Current Value") || 0
                    ) +
                    Number(
                      assignValue("id", "7-4-2-12", "Current Value") || 0
                    ) +
                    Number(
                      assignValue("id", "7-4-2-13", "Current Value") || 0
                    ) +
                    Number(
                      assignValue("id", "7-4-2-14", "Current Value") || 0
                    ) +
                    Number(
                      assignValue("id", "7-4-2-15", "Current Value") || 0
                    ) +
                    Number(assignValue("id", "7-4-2-16", "Current Value") || 0)}
                </td>
              </tr>
              <tr>
                <td>iv</td>
                <td>Gases included in the calculation</td>
                <td>{assignValue("id", "7-4-3-3", "Unit")}</td>
                <td>{assignValue("id", "7-4-3-3", "Current Value")}</td>
              </tr>
              <tr>
                <td>v</td>
                <td>GHG Emission Intensity/Revenue</td>
                <td>{assignValue("id", "7-4-3-4", "Unit")}</td>
                <td>
                  {(
                    (Number(
                      assignValue("id", "7-4-0-1", "Current Value") || 0
                    ) +
                      Number(
                        assignValue("id", "7-4-0-2", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-0-3", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-1-1", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-1-2", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-1-3", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-1-4", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-1", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-2", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-3", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-4", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-5", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-6", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-7", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-8", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-10", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-11", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-12", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-13", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-14", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-15", "Current Value") || 0
                      ) +
                      Number(
                        assignValue("id", "7-4-2-16", "Current Value") || 0
                      )) /
                    Number(assignValue("id", "7-4-0-4", "Current Value") || 1)
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>
              Nitrogen oxides (NOx), sulfur oxides (SOx), and other significant
              air emissions
            </p>
          </strong>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr style={{ backgroundColor: "#FFFDD0" }}>
                <th>Sr no</th>
                <th>Title</th>
                <th>Unit</th>
                <th>Values </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>NOx</td>
                <td>{assignValue("id", "7-4-4-0", "Unit")}</td>
                <td>{assignValue("id", "7-4-4-0", "Current Value")}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>SOx</td>
                <td>{assignValue("id", "7-4-4-1", "Unit")}</td>
                <td>{assignValue("id", "7-4-4-1", "Current Value")}</td>
              </tr>
              <tr>
                <td>iii</td>
                <td>Particulate Matter</td>
                <td>{assignValue("id", "7-4-4-2", "Unit")}</td>
                <td>{assignValue("id", "7-4-4-2", "Current Value")}</td>
              </tr>
              <tr>
                <td>iv</td>
                <td>Volatile organic compounds (VOC)</td>
                <td>{assignValue("id", "7-4-4-3", "Unit")}</td>
                <td>{assignValue("id", "7-4-4-3", "Current Value")}</td>
              </tr>
              <tr>
                <td>v</td>
                <td>Persistent organic pollutants (POP)</td>
                <td>{assignValue("id", "7-4-4-4", "Unit")}</td>
                <td>{assignValue("id", "7-4-4-4", "Current Value")}</td>
              </tr>
              <tr>
                <td>vi</td>
                <td>
                  Other standard categories of air emissions identified in
                  relevant regulations
                </td>
                <td>{assignValue("id", "7-4-4-5", "Unit")}</td>
                <td>{assignValue("id", "7-4-4-5", "Current Value")}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <p>
            <strong>
              4. Has the entity implemented a mechanism for Zero Liquid
              Discharge? If yes, provide details of its coverage and
              implementation
            </strong>
          </p>
          <p>{assignValue("id", "7-6-0-1", "Description")}</p>
          <p>
            <strong>
              Q. Does the entity have a business continuity and disaster
              management plan? Give details in 100 words/ web link
            </strong>
          </p>
          <p>{assignValue("id", "7-6-0-2", "Description")}</p>
          <p>
            <strong>
              Q. Disclose any significant adverse impact to the environment,
              arising from the value chain of the entity. What mitigation or
              adaptation measures have been taken by the entity in this regard?
            </strong>
          </p>
          <p>{assignValue("id", "7-6-0-3", "Description")}</p>
          <p>
            <strong>
              Q. Percentage of value chain partners (by value of business done
              with such partners) that were assessed for environmental impacts.
            </strong>
          </p>
          <p>{assignValue("id", "7-6-0-4", "Description")}</p>
        </div>
        <div>
          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>

          <p>
            <strong>
              8. Does the entity have any project related to reducing Green
              House Gas emission? If yes, then provide details
            </strong>
          </p>
          <p>{assignValue("id", "7-6-0-5", "Description") || "-NA"}</p>

          <p>
            <strong>
              9.Briefly describe the waste management practices adopted in your
              establishments. Describe the strategy adopted by your company to
              reduce usage of hazardous and toxic chemicals in your products and
              processes and the practices adopted to manage such wastes.
            </strong>
          </p>
          <p>{assignValue("id", "7-7-0-0", "Description") || "-NA"}</p>
          <p>
            {" "}
            <strong>
              10 . If the entity has operations/offices in/around ecologically
              sensitive areas (such as national parks, wildlife sanctuaries,
              biosphere reserves, wetlands, biodiversity hotspots, forests,
              coastal regulation zones etc.) where environmental
              approvals/clearances are required,please specify details in the
              following format:
            </strong>
          </p>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                <th>Sr.No </th>
                <th>Location of operations/office </th>
                <th>Type of operations</th>
                <th>
                  Whether the conditions of environmental approval/clearance are
                  being complied with? (Y/N) If no, the reasons thereof and
                  corrective action taken,if any{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>{assignValue("id", "7-8-0-0", "value1") || "NA"}</td>
                <td>{assignValue("id", "7-8-0-0", "value2") || "NA"}</td>
                <td>{assignValue("id", "7-8-0-0", "value3") || "NA"}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>{assignValue("id", "7-8-0-1", "value1") || "NA"}</td>
                <td>{assignValue("id", "7-8-0-1", "value2") || "NA"}</td>
                <td>{assignValue("id", "7-8-0-1", "value3") || "NA"}</td>
              </tr>
              <tr>
                <td>iii</td>
                <td>{assignValue("id", "7-8-0-2", "value1") || "NA"}</td>
                <td>{assignValue("id", "7-8-0-2", "value2") || "NA"}</td>
                <td>{assignValue("id", "7-8-0-2", "value3") || "NA"}</td>
              </tr>
            </tbody>
          </table>
          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Leadership Indicators
          </h4>
          <p>
            <strong>
              Q. With respect to the ecologically sensitive areas reported at
              Question 11 of Essential Indicators above, provide details of
              significant direct & indirect impact of the entity on biodiversity
              in such areas along-with prevention and remediation activities
            </strong>
          </p>
          <p>{assignValue("id", "7-6-0-4", "Description") || "-NA"}</p>
          <p>
            <strong>
              If the entity has undertaken any specific initiatives or used
              innovative technology or solutions to improve resource efficiency,
              or reduce impact due to emissions/effluent discharge/waste
              generated, please provide details of the same as well as outcome
              of such initiatives, as per the following format:
            </strong>
          </p>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr style={{ backgroundColor: "rgb(218, 232, 244)" }}>
                <th>Sr.No </th>
                <th>Initiative undertaken </th>
                <th>
                  Details of the initiative (Web-link, if any, may be provided
                  along-with summary
                </th>
                <th>Outcome of the initiative </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>{assignValue("id", "7-11-0-0", "value1") || "NA"}</td>
                <td>{assignValue("id", "7-11-0-0", "value2") || "NA"}</td>
                <td>{assignValue("id", "7-11-0-0", "value3") || "NA"}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>{assignValue("id", "7-11-0-1", "value1") || "NA"}</td>
                <td>{assignValue("id", "7-11-0-1", "value2") || "NA"}</td>
                <td>{assignValue("id", "7-11-0-1", "value3") || "NA"}</td>
              </tr>
              <tr>
                <td>iii</td>
                <td>{assignValue("id", "7-11-0-2", "value1") || "NA"}</td>
                <td>{assignValue("id", "7-11-0-2", "value2") || "NA"}</td>
                <td>{assignValue("id", "7-11-0-2", "value3") || "NA"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            pageBreakBefore: "always",
          }}
        >
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 7 Businesses, when engaging in influencing public and
            regulatory policy, should do so in a manner that is responsible and
            transparent
          </h3>

          <p>
            We participate in industry and business forums within countries
            where our presence is notable. These associations are dedicated to
            enhancing local competitiveness and advocating for employee rights
            in those regions.
          </p>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>
          <strong>
            <p>
              1.A. Number of affiliations with trade and industry
              chambers/associations
            </p>
            <p>{assignValue("id", "8-0-0-0", "value")}</p>

            <p>
              B. List the top 10 trade and industry chambers/ associations
              (determined based on the total members of such body) the entity is
              a member of/ affiliated to.
            </p>
          </strong>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>Sr no </th>
                <th>of the trade and industry chambers/ associations </th>
                <th>
                  Reach of trade and industry chambers/ associations
                  (State/National)
                </th>
              </tr>
            </thead>
            <tbody>
              {["A", "B", "C", "D", "E", "F", "G"].map((key) => (
                <tr key={`top-10-trades${key}`}>
                  <td>{key}</td>
                  <td>{assignValue("id", "8-0-1-0", key)}</td>
                  <td>{assignValue("id", "8-0-1-1", key)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <strong>
            <p>
              2. Provide details of corrective action taken or underway on any
              issues related to anti- competitive conduct by the entity based on
              adverse orders from regulatory authorities.
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>Name of authority</th>
                <th>Brief of the case</th>
                <th>Corrective action taken</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{assignValue("id", "8-0-2-0", "value")}</td>
                <td>{assignValue("id", "8-0-2-1", "value")}</td>
                <td>{assignValue("id", "8-0-2-2", "value")}</td>
              </tr>
            </tbody>
          </table>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Leadership Indicators
          </h4>

          <p>1. Details of public policy positions advocated by the entity.</p>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>S. No.</th>
                <th>Public policy advocated</th>
                <th>Method resorted for such advocacy Whether</th>
                <th>Information available in public domain? (Yes/No)</th>
                <th>
                  frequency of Review by Board (Annually/ Half yearly/ Quarterly
                  / Others – please specify)
                </th>
                <th>Web Link, if available</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{assignValue("id", "8-0-3-0", "value")}</td>
                <td>{assignValue("id", "8-0-3-1", "value")}</td>
                <td>{assignValue("id", "8-0-3-2", "value")}</td>
                <td>{assignValue("id", "8-0-3-3", "value")}</td>
                <td>{assignValue("id", "8-0-3-4", "value")}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ pageBreakBefore: "always" }}>
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 8: BUSINESSES SHOULD PROMOTE INCLUSIVE GROWTH AND
            EQUITABLE DEVELOPMENT
          </h3>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>
          <strong>
            <p>
              1.. Details of Social Impact Assessments (SIA) of projects
              undertaken by the entity based on applicable laws, in the current
              financial year
            </p>
          </strong>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>Name and brief detail of project</th>
                <th>SIA Notification No. </th>
                <th>Date of notification</th>
                <th>
                  Whether conducted by independent external agency (Yes/No)
                </th>
                <th>Results communicated in public domain (Yes/No)</th>
                <th>Relevant Web link</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{assignValue("id", "9-0-0-0", "value")}</td>
                <td>{assignValue("id", "9-0-0-1", "value")}</td>
                <td>{assignValue("id", "9-0-0-2", "value")}</td>
                <td>{assignValue("id", "9-0-0-3", "value")}</td>
                <td>{assignValue("id", "9-0-0-4", "value")}</td>
                <td>{assignValue("id", "9-0-0-5", "value")}</td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>
              2.. Provide information on project(s) for which ongoing
              Rehabilitation and Resettlement (R&R) is being undertaken by your
              entity, in the following format
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th></th>
                <th>Name of Project for which R&R is ongoing</th>
                <th></th>
                <th></th>
                <th>No. of Project Affected Families (PAFs)</th>

                <th>% of PAFs covered by R&R Amounts paid to PAFs in the</th>
                <th>paid to PAFs in the FY (In INR)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>
              3. Describe the mechanisms to receive and redress grievances of
              the community
            </p>
          </strong>
          <p>{assignValue("id", "9-1-0-0", "value")}</p>

          <strong>
            <p>
              4. Percentage of input material (inputs to total inputs by value)
              sourced from suppliers:
            </p>
          </strong>

          <table
            cellpadding="10"
            cellspacing="0"
            border="1"
            style={{ width: "100%", borderCollapse: "collapse" }}
            className="table"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>Sr no</th>
                <th>Title</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>Directly sourced from MSMEs/ small producers</td>
                <td>{assignValue("id", "9-0-1-0", "value")}</td>
              </tr>
              <tr>
                <td>ii</td>
                <td>
                  Sourced directly from within the district and neighbouring
                  districts
                </td>
                <td>{assignValue("id", "9-0-1-1", "value")}</td>
              </tr>
              <tr>
                <td>iii</td>
                <td>MSMEs/small producers</td>
                <td>{assignValue("id", "9-0-1-0", "value")}</td>
              </tr>
              <tr>
                <td>iv</td>
                <td>
                  Directly from within India - MSME - Non MSME Sourced outside
                  India
                </td>
                <td>{assignValue("id", "9-0-1-0", "value")}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              5. Job creation in smaller towns – Disclose wages paid to persons
              employed (including employees employed on a permanent or
              non-permanent/on contract basis) in the following locations, as %
              of total wage cost.
            </strong>
          </p>

          <table
            cellpadding="10"
            cellspacing="0"
            border="1"
            style={{ width: "100%", borderCollapse: "collapse" }}
            className="table"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>Location</th>
                <th>(Current Financial Year)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rural</td>
                <td>{assignValue("id", "9-5-0-0", "value") || "NA"}</td>
              </tr>
              <tr>
                <td>Semi-urban</td>
                <td>{assignValue("id", "9-5-0-1", "value") || "NA"}</td>
              </tr>
              <tr>
                <td>Urban</td>
                <td>{assignValue("id", "9-5-0-2", "value") || "NA"}</td>
              </tr>
              <tr>
                <td>Metropolitan</td>
                <td>{assignValue("id", "9-5-0-3", "value") || "NA"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            pageBreakBefore: "always",
          }}
        >
          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Leadership Indicators
          </h4>

          <strong>
            <p>
              1. Provide details of actions taken to mitigate any negative
              social impacts identified in the Social Impact Assessments
              (Reference: Question 1 of Essential Indicators above):
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>Parameter </th>
                <th>Details </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Details of negative social impact identified</td>
                <td>{assignValue("id", "9-7-0-0", "value") || "NA"}</td>
              </tr>
              <tr>
                <td>Corrective action taken</td>
                <td>{assignValue("id", "9-7-0-1", "value") || "NA"}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              2., a. Do you have a preferential procurement policy where you
              give preference to purchase from suppliers comprising
              marginalized/vulnerable groups? (Yes/No)
            </strong>
          </p>
          <p>{assignValue("id", "9-2-0-0", "value")}</p>

          <strong>
            <p>B,. From which marginalized/vulnerable groups do you procure?</p>
          </strong>
          <p>{assignValue("id", "9-3-0-0", "value")}</p>
          <p>
            <strong>
              C,. What percentage of total procurement (by value) does it
              constitute?
            </strong>
          </p>
          <p>{assignValue("id", "9-4-0-0", "value")}</p>

          <strong>
            <p>
              3,. Details of the benefits derived and shared from the
              intellectual properties owned or acquired by your entity (in the
              current financial year), based on traditional knowledge
            </p>
          </strong>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>S. No</th>
                <th>Intellectual Property based on traditional knowledge </th>
                <th>Owned/ Acquired (Yes/No)</th>
                <th>Benefit shared (Yes / No)</th>
                <th>Basis of calculating benefit share</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>{assignValue("id", "9-0-2-0", "value")}</td>
                <td>{assignValue("id", "9-0-2-1", "value")}</td>
                <td>{assignValue("id", "9-0-2-2", "value")}</td>
                <td>{assignValue("id", "9-0-2-3", "value")}</td>
              </tr>
            </tbody>
          </table>
          <strong>
            <p>
              d. Details of corrective actions taken or underway, based on any
              adverse order in intellectual property related disputes wherein
              usage of traditional knowledge is involved.
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>Name of authority</th>
                <th>Brief of the Case </th>
                <th>Corrective action taken</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{assignValue("id", "9-0-3-0", "value")}</td>
                <td>{assignValue("id", "9-0-3-1", "value")}</td>
                <td>{assignValue("id", "9-0-3-2", "value")}</td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>e. Details of beneficiaries of CSR Projects:</p>
          </strong>
          <table border="1" cellpadding="10" cellspacing="0" width={"100%"}>
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>S. No</th>
                <th>CSR Project </th>
                <th>No. of persons benefitted from CSR Projects </th>
                <th>
                  % of beneficiaries from vulnerable and marginalized groups{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i</td>
                <td>{assignValue("id", "9-0-5-0", "value")}</td>
                <td>{assignValue("id", "9-0-5-1", "value")}</td>
                <td>{assignValue("id", "9-0-5-2", "value")}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ pageBreakBefore: "always" }}>
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            PRINCIPLE 9 Businesses should engage with and provide value to their
            consumers in a responsible manner
          </h3>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Essential Indicators
          </h4>
          <p>
            <strong>
              1. Describe the mechanisms in place to receive and respond to
              consumer complaints and feedback.
            </strong>
          </p>
          <p>{assignValue("id", "10-0-0-0", "value")}</p>
          <strong>
            <p>
              2. Turnover of products and/ services as a percentage of turnover
              from all products/service that carry information about:
            </p>
          </strong>
          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>Category</th>
                <th>As a percentage to total turnover </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Environmental and social parameters relevant to the product
                </td>
                <td>{assignValue("id", "10-0-1-0", "percentage")}</td>
              </tr>
              <tr>
                <td>Safe and responsible usage</td>
                <td>{assignValue("id", "10-0-1-1", "percentage")}</td>
              </tr>
              <tr>
                <td>Recycling and/or safe disposal</td>
                <td>{assignValue("id", "10-0-1-2", "percentage")}</td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>3. Number of consumer complaints in respect of the following:</p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                {[
                  "Categories",
                  "Received during the year",
                  "Pending resolution at end of year",
                  "Remarks",
                ].map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Data privacy</td>
                <td>{assignValue("id", "10-0-2-0", "Received")}</td>
                <td>{assignValue("id", "10-0-2-0", "Pending")}</td>
                <td>{assignValue("id", "10-0-2-0", "Remarks")}</td>
              </tr>
              <tr>
                <td>Advertising</td>
                <td>{assignValue("id", "10-0-2-1", "Received")}</td>
                <td>{assignValue("id", "10-0-2-1", "Pending")}</td>
                <td>{assignValue("id", "10-0-2-1", "Remarks")}</td>
              </tr>
              <tr>
                <td>Cyber-security</td>
                <td>{assignValue("id", "10-0-2-2", "Received")}</td>
                <td>{assignValue("id", "10-0-2-2", "Pending")}</td>
                <td>{assignValue("id", "10-0-2-2", "Remarks")}</td>
              </tr>
              <tr>
                <td>Delivery of essential</td>
                <td>{assignValue("id", "10-0-2-3", "Received")}</td>
                <td>{assignValue("id", "10-0-2-3", "Pending")}</td>
                <td>{assignValue("id", "10-0-2-3", "Remarks")}</td>
              </tr>
              <tr>
                <td>Services</td>
                <td>{assignValue("id", "10-0-2-4", "Received")}</td>
                <td>{assignValue("id", "10-0-2-4", "Pending")}</td>
                <td>{assignValue("id", "10-0-2-4", "Remarks")}</td>
              </tr>
              <tr>
                <td>Restrictive Trade Practices</td>
                <td>{assignValue("id", "10-0-2-5", "Received")}</td>
                <td>{assignValue("id", "10-0-2-5", "Pending")}</td>
                <td>{assignValue("id", "10-0-2-5", "Remarks")}</td>
              </tr>
              <tr>
                <td>Unfair Trade Practices</td>
                <td>{assignValue("id", "10-0-2-6", "Received")}</td>
                <td>{assignValue("id", "10-0-2-6", "Pending")}</td>
                <td>{assignValue("id", "10-0-2-6", "Remarks")}</td>
              </tr>
              <tr>
                <td>Other</td>
                <td>{assignValue("id", "10-0-2-7", "Received")}</td>
                <td>{assignValue("id", "10-0-2-7", "Pending")}</td>
                <td>{assignValue("id", "10-0-2-7", "Remarks")}</td>
              </tr>
            </tbody>
          </table>

          <strong>
            <p>
              f. Details of instances of product recalls on account of safety
              issues:
            </p>
          </strong>

          <table
            border="1"
            width={"100%"}
            className="table"
            cellpadding="10"
            cellspacing="0"
          >
            <thead style={{ border: "1px solid black" }}>
              <tr>
                <th>categories </th>
                <th>Number </th>
                <th>Reasons for recall </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Voluntary Recalls</td>
                <td>{assignValue("id", "10-0-3-0", "Number")}</td>
                <td>{assignValue("id", "10-0-3-0", "Reason")}</td>
              </tr>
              <tr>
                <td>Forced Recalls</td>
                <td>{assignValue("id", "10-0-3-1", "Number")}</td>
                <td>{assignValue("id", "10-0-3-1", "Reason")}</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>
              g. Does the entity have a framework/ policy on cyber security and
              risks related to data privacy? (Yes/No) If available, provide a
              web-link of the policy.
            </strong>
          </p>
          <p>{assignValue("id", "10-0-4-0", "value")}</p>
          <p>
            <strong>
              h. Provide details of any corrective actions taken or underway on
              issues relating to advertising, and delivery of essential
              services; cyber security and data privacy of customers;
              re-occurrence of instances of product recalls; penalty / action
              taken by regulatory authorities on safety of products / services.
            </strong>
          </p>
          <p>{assignValue("id", "10-0-4-2", "value")}</p>

          <h4
            style={{
              color: "#004B88",
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Leadership Indicators
          </h4>

          <p>
            <strong>
              1. Channels / platforms where information on products and services
              of the entity can be accessed (provide web link, if available).
            </strong>
          </p>
          <p>{assignValue("id", "10-0-4-3", "value")}</p>

          <p>
            <strong>
              2. Steps taken to inform and educate consumers about safe and
              responsible usage of products and/or services.
            </strong>
          </p>
          <p>{assignValue("id", "10-0-4-4", "value")}</p>

          <p>
            <strong>
              3,. Mechanisms in place to inform consumers of any risk of
              disruption/discontinuation of essential services.
            </strong>
          </p>
          <p>{assignValue("id", "10-0-4-5", "value")}</p>

          <p>
            <strong>
              4. Does the entity display product information on the product over
              and above what is mandated as per local laws? (Yes/No/Not
              Applicable) If yes, provide details in brief. Did your entity
              carry out any survey with regard to consumer satisfaction relating
              to the major products / services of the entity, significant
              locations of operation of the entity or the entity as a whole?
              (Yes/No)
            </strong>
          </p>
          <p>{assignValue("id", "10-0-4-1", "value")}</p>
          <p>
            <strong>
              5. Provide the following information relating to data breaches:{" "}
            </strong>
          </p>
          <p>{assignValue("id", "10-0-4-1", "value")}</p>

          <strong>
            <p> a. Number of instances of data breaches along-with impact</p>
            <p>{assignValue("id", "10-0-5-0", "value")}</p>
          </strong>
          <strong>
            <p>
              b. Percentage of data breaches involving personally identifiable
              information of customer
            </p>
            <p>{assignValue("id", "10-0-5-1", "value")}</p>
          </strong>
        </div>
        {/* page 1 */}
        <div style={{ padding: "30px", pageBreakBefore: "always" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              height: "50px",
            }}
          >
            <img src="/bg41.png" alt="" style={{ height: "50px" }} />
          </div>

          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "0px",
              marginBottom: "10px",
            }}
          >
            Independent Assurance Statement:
          </h3>

          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            Introduction:
          </h3>
          <p>
            CETIZION Verifica Private Ltd (We) have been entrusted by the
            management of [Organization Name] (the Organization) to conduct an
            independent “Limited” assurance of its ESG Report. All contractual
            obligations related to this assurance engagement rest entirely with
            [Organization Name]. Our task was to provide a fair and adequate
            judgment on [Organization Name]’s ESG Report
          </p>

          <p>
            The intended users of this assurance statement are stakeholders
            relevant to [Organization Name]’s overall ESG performance and the
            impacts of its business activities during the financial year [Year].
          </p>

          <p>
            CETIZION Verifica Private Ltd is a global provider of ESG and
            Corporate Sustainability services, and a licensed assurance provider
            under [Relevant Assurance Standards], with qualified professionals
            in areas such as Corporate Sustainability Assurance, Environmental
            Stewardship, Climate Change, Greenhouse Gas (GHG) verification,
            Biodiversity, Human and Labor Rights, Supply Chain Due Diligence,
            and Stakeholder Consultation. We have maintained complete
            impartiality and independence during the assurance engagement and
            were not involved in the preparation of the report contents
          </p>

          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            Assurance Standard:
          </h3>
          <p>
            The Independent Assurance was conducted in accordance with Standard
            on Assurance Engagements 3000 (Revised) “Assurance Engagements other
            than Audits or Reviews of Historical Financial Information” (ISAE
            3000) and, where applicable, with ISAE 3410 “Assurance Engagements
            on Greenhouse Gas Statements”. To achieve “Limited” assurance, these
            standards require reviewing the processes, systems, and competencies
            used to compile the subject matter for assurance. This does not
            include detailed testing of each reported KPI or an in-depth review
            of operational processes and internal controls.
          </p>

          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            Scope & Level of Assurance:
          </h3>
          <p
            style={{
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            Our assurance engagement covers the following
          </p>

          <ul>
            <li>
              ESG performance of [Organization Name], including disclosures
              related to Environment, Social, and Governance (ESG) practices.
              This report includes data from all global operations, as
              applicable, for the reporting period.
            </li>
          </ul>
        </div>

        {/* page 2 */}

        <div style={{ padding: "30px", pageBreakBefore: "always" }}>
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            Limitation:
          </h3>

          <p>
            The assurance engagement was conducted primarily at [Organization
            Name]’s headquarters with participation from relevant stakeholders
            and representatives from key operational locations. We have not
            encountered any significant situations that would limit our
            assurance activities. The verification was based on data and
            information provided by [Organization Name], assuming they are
            complete and accurate. We did not verify financial data, as this is
            the responsibility of another third party.
          </p>

          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            Independent Assurance Statement:
          </h3>
          <p>
            CETIZION Verifica Private Ltd reviewed and challenged the report
            contents and assessed the process undertaken by [Organization Name]
            in gathering and disclosing information related to its
            sustainability performance. Our assessment was based on objective
            criteria defined by the applicable Assurance Standards.
          </p>

          <p>
            We employed analytical methods, conducted interviews, and performed
            random sampling to verify the accuracy of reported data and
            disclosures. Our work included consultations with [number] of
            [Organization Name] representatives, including the ESG team, senior
            management, and responsible employees. External stakeholder
            consultations were not conducted. Given that all data could be
            verified through available proofs and verified database entries,
            this approach was deemed appropriate for the purpose of assurance
          </p>

          <p>
            The Assurance was performed by our multidisciplinary team of
            experienced professionals in Corporate Sustainability, Environment,
            Climate Change, Human and Labor Rights, Supply Chain Due Diligence,
            and Stakeholder Consultation.
          </p>

          <p>
            We believe our work provides a sufficient and substantiated basis to
            reach the conclusions outlined below, in line with the contract
            terms
          </p>

          <table
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
              width: "100%",
              className: "table",
            }}
          >
            <thead style={{ border: "1px solid black" }}>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Sr. No.
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Attribute
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Parameter{" "}
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Measurement{" "}
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Data & Assurance Approach
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Cross – reference to the BRSR
              </th>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid black", padding: "10px" }}>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  1
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Green-house gas (GHG) footprint
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total Scope 1 emissions (Breakup of the GHG into CO2, CH4,
                  N2O, HFCs, PFCs, SF6, NF3, if available)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  GHG (CO2 e) Emission in Mn MT / KT / MT Direct emissions from
                  organization’s owned- or controlled sources
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Absolute Fossil Fuel (Coal, Natural Gas, Diesel, FO etc.)
                  Consumption (Mn MT / KT / MT / MM BTU etc.) <br />
                  2. Emission Factor (GHG in CO2 e / Unit of Measure) - IPCC or
                  Actual Testing from Accredited Test Lab <br />
                  3. Quantity of Carbon Capture (Mn MT / KT / MT)
                  <br />
                  4. GHG emissions in CO2 equivalent by process (Non-Fuel
                  Source) (Mn MT / KT / MT / MM BTU) <br />
                  5. Fugitive emissions 6. Total Scope 1 GHG Emissions: Point 2
                  x Point 1 - Point 3 + Point 4 + Point 5
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              <tr style={{ border: "1px solid black", padding: "10px" }}>
                <td
                  rowSpan="3"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  {" "}
                  Greenhouse gas emissions may be measured in accordance with
                  the Greenhouse Gas Protocol: A Corporate Accounting and
                  Reporting Standard
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total Scope 2 emissions (Breakup of the GHG (CO2 e) into CO2,
                  CH4, N2O, HFCs, PFCs, SF6, NF3, if available)
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  GHG (CO2 e) Emission in Mn MT / KT / MT Indirect emissions
                  from the generation of energy that is purchased from a utility
                  provider
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Total Consumption of Purchased Energy (MW), Steam (MT),
                  Refrigeration (MMBTU) <br />
                  2. GHG (CO2 e) Emission Factor across all purchased energy
                  sources - IPCC or actual from the supplier (audited
                  certificates) <br />
                  3. Total Scope 2 GHG Emissions: Total Consumption x Emission
                  Factor
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  GHG Emission Intensity (Scope 1 +2)
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total Scope 1 and Scope 2 emissions (MT) / Total Revenue from
                  Operations adjusted for PPP
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1.Total Emission (Scope 1 & 2) <br />
                  2. Total Revenue from Operations - From Audited P&L Statement{" "}
                  <br />
                  3. PPP (USD / INR) <br />
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1.Total Emission (Scope 1 & 2) Principle 6, Question of
                  Essential Indicators
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td style={{ border: "1px solid black", padding: "10px" }}></td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total Scope 1 and Scope 2 emissions (MT) / Total Output of
                  Product or Services
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Total Emission (Scope 1 & 2) as above <br />
                  2. Company & Sector Specific (i.e., No. of Vehicles Produced,
                  MT of Material Produced, Data in Mn TB, No. of Seats / Travel
                  Class, Room-nights etc.)
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              {/* 2nd  */}
              <tr style={{ border: "1px solid black" }}>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  2
                </td>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  Water footprint
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total water consumption
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Mn Lt or KL
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Water consumed is water that it is no longer available for use
                  by the ecosystem or local community, such as water that has
                  been withdrawn and incorporated into products or has
                  evaporated or is polluted to the point of being unusable by
                  other users, and is therefore not released back to surface
                  water, groundwater, seawater, or a third party. It also
                  includes water that has been stored during the reporting
                  period for use or discharge in a subsequent reporting period.
                  If the entity cannot directly measure its water consumption,
                  it may calculate this using the following: 1. Input water flow
                  meter logs (Calibrated Meters) 2. Output water flow meter logs
                  (Calibrated Meters) 3. Water consumption = Input Water -
                  Output Water
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>
              <tr style={{ border: "1px solid black", padding: "10px" }}>
                <td style={{ border: "1px solid black" }} rowSpan="2">
                  Water consumption intensity
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Mn Lt or KL / Rupee adjusted for PPP
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Total water consumed 2. Total Revenue from Operations (from
                  audited P&L) 3. PPP (USD / INR)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 3 of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Mn Lt or KL / Product or Service
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Consumption as above 2. Company & Sector Specific (i.e.,
                  No. of Vehicles Produced, MT of Material Produced, Data in Mn
                  TB, No. of Seats / Travel Class etc)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 3 of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Water Discharge by destination and levels of Treatment
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Mn Lt or KL
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Untreated Water <br />
                  2. Primary Treatment (Removal of material that floats or
                  settle out i.e Filtration, Screening, Sedimentation etc.){" "}
                  <br />
                  3. Secondary Treatment (Removal of Dissolved organic Matter
                  i.e. Oxidation, Digestion etc.) <br />
                  4. Tertiary Treatment (Disinfecting Water i.e. removal of
                  pathogens, Phosphorous, Nitrogen etc.)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              {/* 3rd  */}

              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="3"
                >
                  3
                </td>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="3"
                >
                  Energy footprint
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total energy consumed % of energy consumed from renewable
                  sources
                </td>

                <td>In Joules or multiples In % terms</td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total energy consumption = nonrenewable fuel consumed +
                  renewable fuel consumed + purchased electricity, heating,
                  cooling, steam + self-generated electricity, heating, cooling,
                  steam (If the entity generates electricity from a
                  non-renewable or renewable fuel source and then consumes the
                  generated electricity, the energy consumption shall be counted
                  only once) Energy consumed through renewable sources / total
                  energy consumed
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="2"
                >
                  Energy intensity
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Joules or multiples / Rupee adjusted for PPP
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Total energy consumed 2. Total Revenue from Operations
                  (from audited P&L) 3. PPP (USD / INR)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Joules or multiples / Product or Service
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1.Consumption as above 2. Company & Sector Specific (i.e., No.
                  of Vehicles Produced, MT of Material Produced, Data in Mn TB,
                  No. of Seats / Travel Class etc)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              {/* 4th */}

              <tr>
                <td
                  rowSpan="14"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  4
                </td>

                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="14"
                >
                  Embracing circularity - details related to waste management by
                  the entity
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  {" "}
                  Plastic waste (A){" "}
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg / MT{" "}
                </td>
                <td>
                  Absolute weight of the packaging material (Bags, Bottles,
                  Pallets etc.) discarded as defined under the plastic waste
                  management rules 2016 and amendments thereof
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  E-waste (B)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg / MT
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Discarded Computers, televisions, cell phones, VCRs, stereos,
                  DVD players, copiers, and fax machines etc. as listed under
                  e-waste management rules 2016 and amendments thereof
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  {" "}
                  Bio-medical waste (C)
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  {" "}
                  Kg / MT
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  {" "}
                  Solids and liquid waste including its container and any
                  intermediate product, which is generated during the diagnosis,
                  treatment or immunization of human beings or animals or
                  research activities as listed under Bio-medical waste
                  management rules 2016 and amendments thereof
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 9 of Essential Indicators
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Construction and demolition waste (D)
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg / MT
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Construction waste as per C&D waste management Rules 2016 and
                  amendments thereof like concrete, plaster, metal rods / wires,
                  wood, plastics etc.
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Battery waste (E)
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg / MT
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Discarded batteries i.e., Li-ion, Alkaline, Lead Acid etc used
                  in vehicles, computers & laptops, mobiles other electronics,
                  UPS, Power Back up etc. as
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Radioactive waste (F)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg / MT
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Discarded material such as paper, plastic, clothes, equipment,
                  machine parts etc. having exposure to radiation across Nuclear
                  Power Plants, Hospitals, Research Laboratories, Industrial
                  Applications etc.
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 9 of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Other Hazardous waste. Please specify, if any. (G)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg / MT
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  As per hazardous waste management rules of CPCB
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 9 of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Other Non-hazardous waste generated (H). Please specify, if
                  any. (Break-up by composition i.e., by materials relevant to
                  the sector)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg / MT
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Waste not identified as Hazardous as per CPCB
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 9 of Essential Indicators
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total waste generated ((A+B + C + D + E + F + G + H))
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg / MT
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  self-explanatory
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 9 of Essential Indicators
                </td>
              </tr>

              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="2"
                >
                  Waste intensity
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg or MT / Rupee adjusted for PPP
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Total waste generated <br />
                  2. Total Revenue from Operations (from audited P&L) <br />
                  3. PPP (USD / INR)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 9 of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg or MT / Unit of Product or Service
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Total waste generated <br />
                  2. Company & Sector Specific (i.e., No. of Vehicles Produced,
                  MT of Material Produced, Data in Mn TB, No. of Seats / Travel
                  Class etc.)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question 9 of Essential Indicators
                </td>
              </tr>

              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="2"
                >
                  Each category of waste generated, total waste recovered
                  through recycling, reusing or other recovery operations
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg or MT
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Absolute quantity
                </td>

                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="2"
                >
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Intensity
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg of Waste Recycled Recovered /Total Waste generated
                  Disclosure may be provided if certificates from vendors have
                  been relied upon for assurance of KPIs on waste management
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  For each category of waste generated, total waste disposed by
                  nature of disposal method
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Kg or MT Intensity
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Amount of material in MT disposed through Incineration{" "}
                  <br />
                  2. Amount of Material to Landfill <br />
                  3. Any other method <br />
                  Kg of Waste Recycled Recovered /Total Waste generated
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 6, Question of Essential Indicators
                </td>
              </tr>

              {/* 5th */}
              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  5
                </td>

                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  Enhancing Employee Wellbeing and Safety
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Spending on measures towards well- being of employees and
                  workers – cost incurred as a % of total revenue of the company
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  In % terms
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  To check sources including – · Insurance Policies & Premium
                  Paid Details · Infant Care Policy · Amount billed/invoices
                  towards providing such facilities (The following measures may
                  be included – health insurance, accident insurance, maternity
                  benefits, paternity benefits, day care facilities, health &
                  safety measures including access to mental health)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 3, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="3"
                >
                  Details of safety related incidents for employees and workers
                  (including contract-workforce e.g. workers in the company's
                  construction sites)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Number of Permanent Disabilities
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  To check on the basis of claims
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 3, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Lost Time Injury Frequency Rate (LTIFR) (per one
                  million-person hours worked)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  1. Total number of lost time injuries <br />
                  2. Total No. of working hours
                  <br />
                  3. LTIFR = (Total number of lost time injuries *10,00,000) /
                  Total No. of working hours
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 3, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  No. of fatalities
                </td>

                <td style={{ border: "1px solid black" }}>
                  To check on the basis of claims as reported to the Factory
                  Inspector
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 3, Question of Essential Indicators
                </td>
              </tr>

              {/* 6th */}
              <tr>
                <td
                  rowSpan="2"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  6
                </td>
                <td
                  rowSpan="2"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  Enabling Gender Diversity in Business
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Gross wages paid to females as % of wages paid
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  In % terms
                </td>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="2"
                >
                  Employee Master / Register
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 5, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Complaints on POSH
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Total Complaints on Sexual Harassment (POSH) reported ·
                  Complaints on POSH as a % of female employees / workers
                  Complaints on POSH upheld
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 5, Question of Essential Indicators
                </td>
              </tr>

              {/* 7th */}
              <tr>
                <td
                  rowSpan="2"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  7
                </td>
                <td
                  rowSpan="2"
                  style={{ border: "1px solid black", padding: "10px" }}
                >
                  Enabling Inclusive Development
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  nput material sourced from following sources as % of total
                  purchases – Directly sourced from MSMEs/ small producers and
                  from within India
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  In % terms – As % of total purchases by value
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  self-explanatory ‘Input material’ - includes all types of
                  procurement such as raw material, spares, services, capex
                  procurement items etc.
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 8, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Job creation in smaller towns – Wages paid to persons employed
                  in smaller towns (permanent or non- permanent /on contract) as
                  % of total wage cost
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  In % terms – As % of total wage cost
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Place of employment of employees /workers (Place to be
                  categorized based on with RBI classification system on rural /
                  semi-urban / urban / metropolitan)
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 8, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="2"
                >
                  8
                </td>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="2"
                >
                  Fairness in Engaging with Customers and Suppliers
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Instances involving loss / breach of data of customers as a
                  percentage of total data breaches or cyber security events
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  In % terms
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}></td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 9, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Number of days of accounts payable
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  (Accounts payable *365) / Cost of goods/services procured
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  To check from financial statements
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Principle 1, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  9
                </td>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  Open-ness of business
                </td>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  Concentration of purchases & sales done with trading houses,
                  dealers, and related parties Loans and advances & investments
                  with related parties
                </td>

                <td style={{ border: "1px solid black", padding: "10px" }}>
                  · Purchases from trading houses as % of total purchases ·
                  Number of trading houses where purchases are made from
                </td>

                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  1. RPT audited by Financial Auditors (Refer Financial Audit
                  Report) 2. Financial statements / invoices
                </td>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  rowSpan="4"
                >
                  Principle 1, Question of Essential Indicators
                </td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  · Purchases from top 10 trading houses as % of total purchases
                  from trading houses
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  · Sales to dealers / distributors as % of total sales · Number
                  of dealers / distributors to whom sales are made · Sales to
                  top 10 dealers / distributors as % of total sales to dealers /
                  distributors
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Share of RPTs (as respective %age) in - · Purchases · Sales ·
                  Loans & advances · Investments
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* page 3 */}

        <div style={{ padding: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              height: "50px",
            }}
          >
            <img src="/bg41.png" alt="" />
          </div>

          <h3
            style={{
              fontSize: "20px",
              fontWeight: "800",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            Conclusion:
          </h3>
          <p>
            Based on our Limited assurance procedures performed and evidence
            obtained, subject to the limitations mentioned above, nothing has
            come to our attention that causes us to believe that the selected
            Key Performance Indicators (KPIs) for the period Financial Year
            [20XX-20XX] have not been prepared, in all material respects, in
            accordance with the “Basis for Reporting” as appropriate.
          </p>

          <p style={{ margin: "0px" }}>
            The Report includes statements and claims that reflect the
            organization's achievements and challenges, supported by documentary
            evidence and internal records. The performance data we reviewed in
            the report were collected, stored, and analyzed systematically and
            professionally, and were deemed plausible
          </p>

          <p style={{ margin: "0px" }}>
            CETIZION Verifica Private Limited shall not bear any liability or
            responsibility to third parties for perceptions or decisions made
            about the organization based on this Limited Assurance Statement
          </p>

          <p>
            CETIZION Verifica shall not bear any liability or responsibility to
            a third party for perception and decision about Synthimed Labs Pvt.
            Ltd. based on this Limited Assurance Statement.
          </p>

          <p style={{ margin: "0px" }}>
            For{" "}
            <span style={{ color: "orange", fontWeight: "400" }}>
              CETIZION{" "}
            </span>
            <span style={{ color: "blue", fontWeight: "400" }}>
              Verifica Private Limited
            </span>
          </p>

          <p style={{ margin: "0px" }}>Gangaa C SHARMA</p>
          <p style={{ margin: "0px" }}>Lead Verifier </p>
          <p style={{ margin: "0px", marginBottom: "30px" }}>
            Digitally signed by{" "}
          </p>

          <h3 style={{ margin: "0px" }}>GANGA CHARAN SHARMA</h3>
          <p style={{ margin: "0px" }}>Signed Dated: XXXXX</p>

          <p style={{ margin: "0px" }}>
            Office 703A, 7th Floor, Tower-C, Iconic Corenthum Tower
          </p>
          <p style={{ margin: "0px" }}>Plot A- 41, Sector-62, Noida-201301,</p>
          <p style={{ margin: "0px" }}>Uttar Pradesh, Delhi</p>
          <p style={{ margin: "0px" }}>Capital Region INDIA</p>
        </div>

        {/* page 4 */}

        <div style={{ padding: "30px", pageBreakBefore: "always" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              height: "50px",
            }}
          >
            <img src="/bg41.png" alt="" height="50px" />
          </div>
          <p>
            CETIZION Verifica assumes no responsibility and shall not be liable
            to any person for any loss, damage or expense caused by reliance on
            the information or advice in this document or howsoever provided,
            unless that person has signed a contract with the relevant CETIZION
            Verifica entity for the provision of this information or advice and
            in that case any responsibility or liability is exclusively on the
            terms and conditions set out in that contract.
          </p>

          <p>
            CETIZION Verifica assumes no responsibility and shall not be liable
            to any person for any loss, damage, or expense caused by reliance on
            the information or advice in this document, unless that person has
            signed a contract with CETIZION Verifica for the provision of this
            information or advice, in which case, any responsibility or
            liability is exclusively governed by the terms and conditions set
            out in that contract
          </p>

          <p>
            The English version of this Assurance Statement is the only valid
            version. CETIZION Verifica assumes no responsibility for versions
            translated into other languages. This Assurance Statement is valid
            only when published with the Report to which it refers. It may only
            be reproduced in its entirety
          </p>

          <h3 style={{ fontWeight: "800" }}>
            Copyright © CETIZION Verifica Private Ltd
          </h3>
        </div>
      </>
    </div>
  );
};

export default observer(BRSRSkeleton);
