import { observer } from "mobx-react";
import Editor from "../components/editor";
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import InitialContent from "../components/initialContent";
import fetchData from "../../../utils/fetchData";
import Tools from "./tools";
import { Modal } from "react-bootstrap";
import { useAuth } from "../../../context/authProvider";
import "./report.css";

const GRIReport = () => {
  const ref = useRef(null);
  const editor = ref.current;
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [renderedContent, setRenderedContent] = useState("");
  const [modalContent, setModalContent] = useState(""); // Store content for modal preview
  const { showAlert } = useAuth();
  const handleClose = () => setShow(false);
  const [content, setContent] = useState(null);
  const handleShow = () => {
    const content = editor?.editor?.getContent(); // Fetch the latest content from the editor
    setModalContent(content); // Set modal content with updated content
    setShow(true);
  };

  useEffect(() => {
    fetchData("company/initialContent", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setData(res.data);
          // Update renderedContent here after setting data
          setRenderedContent(
            ReactDOMServer.renderToStaticMarkup(
              <InitialContent data={res.data} editor={editor} />
            )
          );
        }
      })
      .catch((err) => {
        console.error("[Error]", err);
      });
  }, []);

  const initialValue = ReactDOMServer.renderToStaticMarkup(
    content || <InitialContent data={data} editor={editor} />
  );

  const handlePrint = () => {
    if (renderedContent) {
      const content = editor?.editor?.getContent();
      const printWindow = window.open("", "", "height=800,width=800");
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              /* Add print-specific styles here */
              body { font-family: Arial, sans-serif; margin: 20px; }
              @page { size: A4 landscape; }
            </style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.focus();
        printWindow.print();
        printWindow.onafterprint = function () {
          printWindow.close();
        };
      };
    } else {
      alert("Content not ready for download."); // Optional: Alert if data is not ready
    }
  };

  const saveDraft = () => {
    if (editor?.editor) {
      const token = JSON.parse(
        localStorage.getItem("access-token") ||
          localStorage.getItem("admin-access-token")
      )?.token;
      const payload = new FormData();
      payload.append("year", new Date().getFullYear());
      payload.append("html", editor?.editor?.getContent?.());
      fetch(process.env.REACT_APP_BASE_URL + `report/create`, {
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          showAlert(res.msg, res.status);
        })
        .catch((err) => {
          console.error("[Error]", err);
        });
    }
  };

  useEffect(() => {
    fetchData("report/getAll")
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setContent(
            <div dangerouslySetInnerHTML={{ __html: res.data.html }}></div>
          );
          return;
        }
      })
      .catch((err) => console.error("[Error]", err));
  }, []);

  return (
    <div style={{ height: "calc(100vh - 150px)" }}>
      <div className="card">
        <div className="card-body p-4 d-flex justify-content-between align-items-center">
          <h5 className="card-title">GRI Reports</h5>
          <div className="d-flex gap-2">
            {/* <button className="btn btn-secondary" onClick={saveDraft}>
              Save as Draft
            </button> */}
            <button className="btn btn-secondary" onClick={handleShow}>
              Preview
            </button>
            <button className="btn btn-secondary" onClick={handlePrint}>
              Download
            </button>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "20px", gap: "20px" }}>
        <div
          className="report-editor"
          style={{ width: "100%", height: "calc(100vh - 300px)" }}
        >
          {data && content && (
            <>
              <div className="card p-3 mb-0">
                <Tools editor={editor} />
              </div>
              <Editor ref={ref} initialValue={initialValue} />
            </>
          )}
        </div>
      </div>
      <Modal size="xl" className="modal-xxl"  show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>GRI Report Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-preview" dangerouslySetInnerHTML={{ __html: modalContent }} />
          {/* <InitialContent /> */}
          {/* Display updated content */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default observer(GRIReport);
