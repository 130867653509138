import { observer } from "mobx-react";
import Charts from "./charts";

const Tools = ({ editor }) => {
  return (
    <div className="d-flex align-items-center gap-3 ">
      <h6 className="">Tools : </h6>
      <Charts editor={editor} />
    </div>
  );
};

export default observer(Tools);
