import vision from "../../../assets/images/Report/vision.jpeg";
import values from "../../../assets/images/Report/values.jpeg";
import mission from "../../../assets/images/Report/mission.jpeg";
import img10 from "../../../assets/images/Report/10.jpeg";
import env from "../../../assets/images/Report/Env.jpeg";
import social from "../../../assets/images/Report/Social.jpeg";
import gov from "../../../assets/images/Report/Gov.jpeg";
import envDay from "../../../assets/images/Report/env-day.png";
import pyramid from "../../../assets/images/Report/pyramid.png";
import climateSmart from "../../../assets/images/Report/ClimateSmart.png";
import envEff from "../../../assets/images/Report/env-eff.png";
import solar from "../../../assets/images/Report/solar.png";
import reuse from "../../../assets/images/Report/reuse.png";
import handWash from "../../../assets/images/Report/hand-wash.png";
import waste from "../../../assets/images/Report/waste.png";
import scope1 from "../../../assets/images/Report/scope1.png";
import numToRoman from "../../../helpers/numToRoman";
import imperitive from "../../../assets/images/Report/imperitive.png";
import cornerstones from "../../../assets/images/Report/Cornerstones.png";
import strategy from "../../../assets/images/Report/strategy.png";
import aspirations from "../../../assets/images/Report/aspirations.png";
import approach from "../../../assets/images/Report/approach.png";
import quality from "../../../assets/images/Report/quality.png";
import trust from "../../../assets/images/Report/trust.png";
import reliability from "../../../assets/images/Report/reliability.png";
import consistency from "../../../assets/images/Report/consistency.png";
import innovation from "../../../assets/images/Report/innovation.png";
import enviro from "../../../assets/images/Report/enviro.png";
import social2 from "../../../assets/images/Report/social.png";
import gover from "../../../assets/images/Report/gover.png";

import bg from "../../../assets/images/Report/bg41.png";
import page16 from "../../../assets/images/Report/page-16-img.webp";
import page17 from "../../../assets/images/Report/page-17-img.jpg";
import page18 from "../../../assets/images/Report/page-18-img.png";
import page19 from "../../../assets/images/Report/page-19-img.webp";
import page24 from "../../../assets/images/Report/page-24-img.jpg";
import page29 from "../../../assets/images/Report/page-29-img.jpg";
import page30 from "../../../assets/images/Report/page-30-img.png";
import page31 from "../../../assets/images/Report/page-31-img.png";
import page32 from "../../../assets/images/Report/page-32-img.jpeg";
import page35 from "../../../assets/images/Report/page-35-img.jpg";
import page57 from "../../../assets/images/Report/page-57-img.png";
import page59 from "../../../assets/images/Report/page-59-img.webp";
import page60 from "../../../assets/images/Report/page-60-img.png";
import page64 from "../../../assets/images/Report/page-64-img.jpg";
import page66 from "../../../assets/images/Report/page-66-img.jpg";
import page68 from "../../../assets/images/Report/page68.png";
import page71 from "../../../assets/images/Report/page-71-img.png";
import page74 from "../../../assets/images/Report/page-74-img.jpg";
import page75 from "../../../assets/images/Report/page-74-2-img.jpg";
import page76 from "../../../assets/images/Report/page-76-img.jpg";
import React, { useMemo } from "react";

import "./report.css";
const getConverter = (data) => (keys) =>
  keys?.map((val, index) => {
    const curr = data?.waste?.curr?.find((e) => e.name === val)?.value;
    const prev = data?.waste?.prev?.find((e) => e.name === val)?.value;
    return {
      curr,
      prev,
      name: val,
      id: index,
    };
  });
const getRevenue = (data) => (keys) =>
  keys?.map((val, index) => {
    const curr = data?.revenue?.curr?.find((e) => e.title === val)?.value;
    const prev = data?.revenue?.prev?.find((e) => e.title === val)?.value;
    return {
      curr,
      prev,
      title: val,
      id: index,
    };
  });
const getBiodiversityConverter = (data) => (keys) =>
  keys?.map((val, index) => {
    const curr =
      data?.biodiversity?.curr?.find((e) => e.name === val)?.value || 0;
    const prev =
      data?.biodiversity?.prev?.find((e) => e.name === val)?.value || 0;
    return {
      curr,
      prev,
      name: val,
      id: index,
    };
  });
const getSocialConverter = (data) => (keys) =>
  keys.map((val, index) => {
    const curr = data?.social?.curr?.find((e) => e.name === val)?.value || 0;
    const prev = data?.social?.prev?.find((e) => e.name === val)?.value || 0;
    return {
      curr,
      prev,
      name: val,
      id: index,
    };
  });
const createFindIdFunc = (data) => (name, group, type) => {
  const curr =
    data?.[type]?.curr?.find((e) => e.name === name && e.group === group)
      ?.value || 0;
  const prev =
    data?.[type]?.prev?.find((e) => e.name === name && e.group === group)
      ?.value || 0;
  return {
    curr,
    prev,
    name: name,
    group: group,
    // id: id,
    type: type,
  };
};
const createFindKeyFuncBRSR =
  (data) =>
  (key = "id", value, valueKey) => {
    const curr =
      data?.brsr?.curr?.find((e) => e?.[key] === value)?.value?.[valueKey] || 0;
    const prev =
      data?.brsr?.prev?.find((e) => e?.[key] === value)?.value?.[valueKey] || 0;
    return { curr, prev };
  };

const InitialContent = ({ data, editor }) => {
  const year = new Date().getFullYear();
  const performance = data?.performance;

  const convertData = getConverter(data);
  const convertRevenueData = getRevenue(data);
  const convertBiodiversityData = getBiodiversityConverter(data);
  const convertSocialData = getSocialConverter(data);
  const assignValue = createFindIdFunc(data);
  const assignValueBRSR = useMemo(() => createFindKeyFuncBRSR(data), [data]);

  // console.log(assignValueBRSR("dataId", "0-1-2-6", "value")?.curr); // { curr: '37', prev: '36' }

  const typesOfWasteGenerated = convertData([
    "Hazardous",
    "Non-hazardous",
    "E-waste",
    "Plastic waste",
    "Bio-medical waste",
    "Construction and demolition waste",
    "Battery waste",
    "Radioactive waste",
    "Other",
  ]);

  const hazardous = convertData([
    "Hazardous waste - Reuse",
    "Hazardous waste - Composting",
    "Hazardous waste - Recycling",
    "Hazardous waste - other",
  ]);

  const nonHazardous = convertData([
    "Non-hazardous - Reuse",
    "Non-hazardous - Composting",
    "Non-hazardous - Recycling ",
    "Non-hazardous - other ",
  ]);

  const eWaste = convertData(["E-waste - Recycle"]);

  const hazWaste = convertData([
    "Hazardous waste - Incineration With Energy recovery",
    "Hazardous waste -  Incineration Without Recovery",
    "Hazardous waste -  Landfilling",
    "Hazardous waste -  Co-processing",
    "Hazardous waste - Deep well injection",
    "Hazardous waste - Other",
  ]);

  const nonHazWaste = convertData([
    "Non-Hazardous - Incineration With Energy recovery ",
    "Non-Hazardous -  Incineration Without Recovery",
    "Non-Hazardous -  Landfilling",
    "Non-Hazardous -  Deep well injection",
    "Non-Hazardous -  Other",
  ]);

  const biodiversity = convertBiodiversityData([
    "Critically endangered",
    "Endangered",
    "Vulnerable",
    "Near threatened",
    "Least concern",
  ]);
  const ohcWorkersCoveredSocial = convertSocialData([
    "Number of Employees whose Work/Workplace is controlled by the Organisation.",
    "Number of Employees covered by the OHS System, Audited or Certified by an External party.",
    "Number of Workers Excluded.",
    "Number of Employees Covered by the OHS System",
    "Number of Employees Covered by the OHS System and internally audited.",
  ]);

  const waterRevenue = convertRevenueData(["Water intensity (Revenue)"]);
  const energyConsumptionRevenue = convertRevenueData([
    "Intensity of Total Energy Consumption/Revenue",
  ]);

  return (
    <div style={{ fontFamily: "Poppins, sans-serif" }} className="gri-report">
      {/* page 1 */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* <img src={bg} alt="Company Name with Logo " /> */}

        <div>
          <h1 style={{ margin: "0" }}>Innovating Healthcare</h1>
          <h1 style={{ margin: "0" }}>Enhancing Lives.</h1>
        </div>

        <>
          <h4>Sustainability Report 20XX-XX</h4>
        </>
      </div>
      {/* page 2 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2 style={{ textDecoration: "underline" }}>Table of Contents</h2>

        <h3>Introduction</h3>
        <ol>
          <li>
            About the Report
            ...........................................................................4
          </li>
          <li>
            ESG Performance Highlights
            ........................................................5
          </li>
          <li>
            Message from the Managing Director
            .........................................6
          </li>
          <li>
            About The Company
            ......................................................................8
          </li>
          <li>
            Global Presence
            ...........................................................................9
          </li>
          <li>
            Our Vision Mission & Core Values
            ...............................................10
          </li>
          <li>
            Financial Performance
            ...................................................................11
          </li>
        </ol>

        <h3>Corporate Governance</h3>
        <ol start="8">
          <li>
            Corporate Governance
            ..................................................................12
          </li>
          <li>
            Board of Directors
            ........................................................................13
          </li>
        </ol>

        <h3>Strategic Risk Management</h3>
        <ol start="10">
          <li>
            Strategic Risk Management
            .......................................................17
          </li>
          <li>
            Risk Management Approach
            ......................................................18
          </li>
          <li>
            Key Risks
            ........................................................................................19
          </li>
          <li>
            Emerging Risks
            ..............................................................................23
          </li>
          <li>
            Risk Culture
            ..................................................................................23
          </li>
        </ol>

        <h3>Stakeholder Engagement and Materiality Assessment</h3>
        <ol start="15">
          <li>
            Stakeholder Engagement Methodology
            ..................................24
          </li>
          <li>Gaining Insights: Our Stakeholder Engagement Approach .....25</li>
          <li>
            Approaching Materiality: Evaluating Impact and Importance ...25
          </li>
          <li>
            Our ESG Strategy
            ........................................................................27
          </li>
        </ol>

        <h3>Responsible Future</h3>
        <ol start="19">
          <li>
            Customer Safety
            .........................................................................30
          </li>
          <li>
            Product Quality
            ............................................................................30
          </li>
          <li>
            Research and Development (R&D)
            ............................................35
          </li>
          <li>
            Our Approach & Capabilities
            .....................................................36
          </li>
          <li>
            Product Quality & Product End of Life
            .......................................38
          </li>
        </ol>
      </div>

      {/* page 3 */}
      <div>
        <h3>Environmental Stewardship</h3>
        <ol start="24">
          <li>
            Environmental Performance FYXX
            .........................................................................40
          </li>
          <li>
            Environmental Governance Mechanism
            ............................................................................42
          </li>
          <li>
            Our Climate-Smart Vision
            ............................................43
          </li>
          <li>
            Energy Efficiency
            .....................................................44
          </li>
          <li>Water Stewardship .......................................46</li>
          <li>Waste Management .......................................49</li>
          <li>Emission Management .......................................52</li>
          <li>Biodiversity .......................................55</li>
          <li>Climate Governance .......................................56</li>
          <li>
            Biodiversity Management Plan
            .......................................57
          </li>
        </ol>{" "}
        <h3>Workforce Resilience and Well-being</h3>
        <ol start="34">
          <li>
            Building and Empowering Teams
            .........................................................................30
          </li>
          <li>
            Diversity, Equity, and Inclusion (DEI) Approach
            ............................................................................30
          </li>
          <li>
            Promotion Criteria ............................................35
          </li>
          <li>
            Talent Engagement
            .....................................................36
          </li>
          <li>Employee Benefit .......................................38</li>
          <li>
            Nurturing an Ethics-driven Culture
            .......................................38
          </li>
          <li>
            Ensuring Employee Well-being, Health, and Safety
            .......................................38
          </li>
        </ol>
        <h3>Enhancing Social Value</h3>
        <ol start="42">
          <li>
            Local Community Engagement
            .........................................................................30
          </li>
          <li>
            Our Initiatives to Create Lasting Value
            ............................................................................30
          </li>
          <li>
            Our Governance Structure
            ............................................35
          </li>
          <li>
            How Our Organization serve the community
            .....................................................36
          </li>
        </ol>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "40px",
                width: "200px",
                border: "1px solid black",
              }}
            >
              <img src="" alt="bar code of the company" />
            </div>

            <p> For more information, please, visit our website</p>
          </div>
        </div>
      </div>

      {/* page 4 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>About the Report </h2>
        <p>free text</p>

        <div style={{ display: "flex", gap: "30px" }}>
          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Frameworks and Standards for Reporting </h3>
            <p>Free text</p>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Scope and Reporting Limits</h3>
            <p>free text</p>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Feedback </h3>
            <p>
              We are committed to gathering feedback on our sustainability
              performance disclosures from all our stakeholders. Please feel
              free to share your feedback, suggestions, or questions at: Company
              Mail ID{" "}
            </p>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>"Third-Party Validation" </h3>
            <p>
              The Sustainability Report for FY23 has been independently verified
              by CETIZION Verifica Pvt. Ltd.{" "}
            </p>
          </div>
        </div>
      </div>

      {/* page 5 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2> ESG Performance Highlight </h2>

        <div style={{ display: "flex" }}>
          <div style={{ border: "1px solid black" }}>
            <h2
              style={{
                textOrientation: "upright",
                writingMode: "vertical-rl",
                fontSize: "16px",
              }}
            >
              ENVIRONMENT
            </h2>
          </div>

          <img style={{ width: "500px", height: "200px" }} src={env} alt="" />

          <p>Free text</p>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ border: "1px solid black" }}>
            <h2
              style={{
                textOrientation: "upright",
                writingMode: "vertical-rl",
                fontSize: "16px",
              }}
            >
              SOCIAL
            </h2>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p>Free text</p>
            <img
              style={{ width: "500px", height: "200px" }}
              src={social}
              alt=""
            />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ border: "1px solid black" }}>
            <h2
              style={{
                textOrientation: "upright",
                writingMode: "vertical-rl",
                fontSize: "16px",
              }}
            >
              GOVERNANCE
            </h2>
          </div>
          <img style={{ width: "500px", height: "200px" }} src={gov} alt="" />
          <p className="">Free text</p>
        </div>
      </div>

      {/* page 6 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>Message from the Managing Director</h2>

        <div
          style={{
            display: "flex",
            margin: "0 auto",
            border: "1px solid black",
            borderRadius: "50%",
            overflow: "hidden",
            width: "400px",
            height: "300px",
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src="https://www.shutterstock.com/image-illustration/mockup-3d-logo-facade-sign-600nw-2046330740.jpg"
            alt="MD PIC"
          />
        </div>

        <div>
          <ul>
            <h3>Dear Stake Holders </h3>
            <li>
              Globally, businesses have the potential to significantly
              contribute to building a more sustainable and inclusive society.
              At our organization, we are committed to leveraging the strength,
              expertise, and network of our people to achieve sustainable
              outcomes for all our stakeholders. Our purpose is driven by our
              Vision: ‘Reaching People and Touching Lives Globally as a Leading
              Provider of Valued Medicines.’{" "}
            </li>

            <li>
              We firmly believe that the health of our planet is deeply
              connected to the life it sustains, which is essential for global
              societal progress. In a world marked by increasing uncertainty, we
              are dedicated to collaborating and co-creating solutions with our
              stakeholders that have a positive impact on society.{" "}
            </li>

            <li>
              Our efforts are guided by a well-defined roadmap for our
              Environment, Social, and Governance (ESG) initiatives. I am
              pleased to share that our organization has become a signatory of
              the United Nations Global Compact (UNGC), one of the world’s
              largest corporate sustainability initiatives. This further
              underscores our commitment to aligning our strategy with universal
              ESG principles and taking the necessary steps to advance societal
              goals.{" "}
            </li>

            <li>
              In addition to embedding ESG priorities into our business strategy
              and operations, we recognize the importance of transparent and
              accurate reporting on our progress and future sustainability
              targets. I am proud to present our Sustainability Report for FY24,
              which offers valuable insights into our sustainability journey,
              our key focus areas, our accomplishments, and our long-term goals.{" "}
            </li>
          </ul>
        </div>

        <div>
          <h3>Environmental Responsibility and Climate Action </h3>
        </div>

        <div style={{ pageBreakBefore: "always" }}>
          <h3>Employee Well-being </h3>
          <p>
            Our people are the core of our success. As a global organization, we
            take pride in attracting and retaining a diverse, skilled workforce
            that drives innovation and synergy. We invest in employee growth
            through training, recognition, and engagement opportunities, and
            have earned Great Place to Work Certification in FY24 in India for
            our people practices.{" "}
          </p>

          <p>
            Employee safety is a top priority, with comprehensive health and
            safety training across all locations and adherence to our
            Environment, Health, and Safety (EHS) Policy. Our ISO 45001:2018
            certification reinforces our commitment to workplace safety.{" "}
          </p>

          <p>
            We foster an environment that supports growth, well-being, and
            safety, paving the way for a sustainable future.{" "}
          </p>
        </div>

        <div>
          <h3>Social Impact and Community Development</h3>

          <p>
            Our organization plays a key role in fostering an inclusive society
            by leveraging resources to support underprivileged communities. We
            focus on healthcare, education, water and sanitation, environmental
            conservation, rural development, and disaster relief.{" "}
          </p>

          <p>
            In FYXX, we invested XXXX Million in Corporate Social Responsibility
            (CSR). We are committed to advancing the United Nations Sustainable
            Development Goals (UN SDGs) through our socially responsible
            programs.{" "}
          </p>
        </div>

        <div>
          <h3>Enhancing Healthcare Accessibility</h3>
          <p>
            Access to healthcare is a fundamental right, and we are dedicated to
            providing high-quality medicines at affordable prices. With XX
            manufacturing facilities and a presence in over XX countries, we
            address unmet patient needs. In FYXX, we invested ₹XX Billion in R&D
            to drive innovation. We actively seek collaborations and engage with
            healthcare providers to enhance patient outcomes and work towards a
            healthier, more inclusive future.
          </p>
        </div>

        <div>
          <h3>Maintaining Commitment to Product Safety and Quality</h3>

          <p>
            As a leading pharmaceutical manufacturer, we prioritize product
            quality and safety throughout their lifecycle. Our facilities are
            approved by major regulatory agencies, and we hold ISO 9001:2015
            certification at many sites. We use a proactive approach to risk
            management and quality assurance, supported by a strong Quality
            Management System. Our global drug safety monitoring team ensures
            product safety and effectiveness, and our quality practices extend
            to our supply chain through our Global Code of Conduct and updated
            vendor audits that now include ESG parameters.{" "}
          </p>
        </div>
      </div>

      {/* page 7 */}
      <div style={{ pageBreakBefore: "always" }}>
        <div>
          <h3>"Planning for the Future"</h3>
          <p>
            Our ESG strategy's success hinges on continuous improvement and
            increased focus on climate resilience and responsible operations. We
            will integrate ESG priorities into our long-term strategy, actively
            engage with stakeholders, and collaborate with experts to drive
            positive change. By fostering innovation and responsibility, we aim
            to build a sustainable future. We thank our employees, customers,
            partners, and shareholders for their unwavering support as we work
            towards a brighter, healthier future for all.{" "}
          </p>
        </div>

        <div>
          <p>XXXXXXXXXXXXXX Name of MD with Digital Signature</p>

          <p>Managing Director</p>
          <p>XXXXXXXXXXXXXXXXXXXXX Company Details</p>
        </div>

        <div style={{ pageBreakBefore: "always" }}>
          <h2>About the Company</h2>

          <img src="h" alt="about the company" />
          <h3>Enhancing Lives Every Day </h3>
          <p>free text</p>
          <p>
            <strong>Global Accreditations</strong>
          </p>
          <p>
            Our Organization has achieved significant global accreditations from
            regulatory bodies including USFDA, EDQM, ANVISA, and KFDA, among
            others. These accreditations demonstrate the company's dedication to
            upholding superior quality standards and regulatory compliance,
            ensuring that its products consistently meet international quality
            and regulatory benchmarks. Some figure of certificates to be merges
            in Boxes.
          </p>
        </div>
      </div>

      <div style={{ pageBreakBefore: "always" }}>
        <h3>Our Global Presence</h3>
        <img src="" alt="world map" />
        <div
          style={{ display: "flex", gap: "16px", pageBreakBefore: "always" }}
        >
          <div style={{ width: "33%", border: "1px solid black" }}>
            <img style={{ width: "100%" }} src={vision} alt="" />
            <p style={{ margin: "0px", fontSize: "14px" }}>
              Vision of Leadership: The organization aspires to be a leader in
              its field, setting the standard for others to follow. This
              leadership isn't just about market dominance; it's about being at
              the forefront of positive change, particularly in promoting health
              and well-being.{" "}
            </p>
            <p style={{ margin: "0px", fontSize: "14px" }}>
              Global Impact: The organization's focus is worldwide. It aims to
              have a broad, international presence and impact, indicating that
              its products and services are meant to benefit people across
              different countries and cultures.{" "}
            </p>

            <p style={{ margin: "0px", fontSize: "14px" }}>
              Commitment to Quality: Quality is a central theme. The
              organization prioritizes delivering top-quality products, which is
              crucial for earning trust and ensuring effectiveness, especially
              in sectors like pharmaceuticals or healthcare.{" "}
            </p>

            <p style={{ margin: "0px", fontSize: "14px" }}>
              Innovation: The organization is dedicated to pioneering new and
              innovative solutions. This suggests a forward-thinking approach,
              where continuous improvement and adaptation are key strategies.
              Innovation isn't just about new products; it also involves
              improving existing ones and finding better ways to meet global
              needs.{" "}
            </p>

            <p style={{ margin: "0px", fontSize: "14px" }}>
              Positive Impact on Well-Being: The ultimate goal is to improve the
              well-being of individuals and communities. This reflects a
              commitment to corporate social responsibility, where the
              organization's success is measured not just by profit but by the
              positive impact it has on people's lives.{" "}
            </p>

            <p style={{ margin: "0px", fontSize: "14px" }}>
              Consistency in Delivery: There's an emphasis on consistently
              delivering excellence. This indicates that the organization aims
              to maintain high standards over time, ensuring that its
              contributions to global health are reliable and ongoing.{" "}
            </p>
          </div>
          <div style={{ width: "33%", border: "1px solid black" }}>
            <img style={{ width: "100%" }} src={mission} alt="" />
            <p style={{ fontSize: "14px" }}>
              Our mission is to build a profitable and sustainable organization
              that consistently delivers excellence across global markets. We
              aim to offer a diverse range of high-quality products, ensuring
              that our operations are system-driven and aligned with world-class
              standards.
            </p>
            <p style={{ fontSize: "14px" }}>
              To achieve this, we rely on the strength of our loyal customer
              base, the dedication of our talented team, and the implementation
              of effective systems. By fostering a culture of innovation and
              continuous improvement, we leverage the latest technologies to
              stay at the forefront of our industry.
            </p>

            <p style={{ fontSize: "14px" }}>
              Through these combined efforts, we are committed to elevating
              industry standards and making a positive impact on the lives of
              individuals worldwide, contributing to a better future for all.
            </p>
          </div>
          <div style={{ width: "33%", border: "1px solid black" }}>
            <img style={{ width: "100%" }} src={values} alt="" />
            <p style={{ fontSize: "14px" }}>
              Our organizational culture is grounded in core values that ensure
              equal opportunity for everyone to contribute toward achieving both
              organizational and societal goals.
            </p>
            <p style={{ fontSize: "14px" }}>
              We view our customers as our most valuable teachers, and our
              employees as our most important assets, recognizing their critical
              roles in our success.
            </p>

            <p style={{ fontSize: "14px" }}>
              Decision-making within our organization is guided by a clear and
              defined value system, which we refer to as the ‘RIPCO’ Value
              System.
            </p>
            <p style={{ fontSize: "14px" }}>
              This value-driven approach helps us align our actions with our
              mission, ensuring consistency and integrity in all that we do
            </p>
          </div>
        </div>
      </div>

      <div style={{ pageBreakBefore: "always" }}>
        <h2>Financial Performance</h2>
        <h3>Achieving Consistent Profitable Growth</h3>
        <p>
          We aim to strengthen our core competencies to serve global communities
          with our portfolio of value-added products, driving sustained health
          outcomes. To deliver longterm value to our shareholders and
          stakeholders, we are dedicated to optimizing efficiency, cash flow,
          and cost structure while expanding our global business.
        </p>

        <h4>GRI 201-1 </h4>
      </div>

      <div style={{ pageBreakBefore: "always" }}>
        <h2>Corporate Governance</h2>
        <h3>Exceeding Regulatory Standards.</h3>
        <p>
          Our corporate governance approach is deeply rooted in our values of
          quality, reliability, trust, consistency, and innovation. Upholding
          the highest standards of ethical business conduct is crucial to
          creating shared value for all stakeholders. By maintaining ethical
          practices, we ensure transparent and accountable communication, which
          supports responsible and sustainable decision-making.
        </p>

        <div>
          <h2>Employess Code of Conduct</h2>
          <p>
            Our International Code of Conduct is the cornerstone of our strong
            corporate governance framework. Aligned with our core values, the
            International Code of Conduct offers essential guidance on
            maintaining the highest standards of responsible behavior throughout
            our business operations and value chain. It serves as the governing
            document for all business activities and applies to all employees
            and the Board of Directors. Additionally, all business partners,
            including suppliers, service providers, agents, and channel
            partners, are required to adhere to the principles outlined in the
            International Code of Conduct.
          </p>

          <img style={{ width: "100%", height: "300px" }} src={img10} alt="" />
        </div>

        <h4>GRI 2-23, 10GRI 2-24 </h4>
      </div>

      {/* page 13 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>
          Features of our <strong>Employees Code of Conduct</strong>
        </p>
        <table
          border="1"
          cellPadding="10"
          style={{ borderCollapse: "collapse", width: "100%" }}
        >
          <thead>
            <tr>
              <th>Code for Independent Directors</th>
              <th>Conflict of Interest</th>
              <th>Competition Law Compliance</th>
              <th>Anti-bribery and Anti-Corruption</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Honest and Ethical Conduct and Fair Dealing</td>
              <td>Anti-Money Laundering</td>
              <td>Whistleblower Policy</td>
              <td>Protection of Confidential Information</td>
            </tr>
            <tr>
              <td>Privacy Policy</td>
              <td>Prohibition Against Company Political Contributions</td>
              <td>Public Policy Advocacy</td>
              <td>Prevention of Sexual Harassment</td>
            </tr>
            <tr>
              <td>Non-discrimination</td>
              <td>Human Rights</td>
              <td>Environmental Policy</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <p>
          In alignment with our ECoC, we uphold a zero-tolerance policy towards
          bribery and corruption. We exercise stringent oversight across all
          business operations to prevent any such occurrences. Employees receive
          comprehensive training on the ECoC and are expected to adhere to it
          rigorously. In FYXX, there were no reported instances of bribery,
          corruption, anti-competitive behavior, antitrust, or monopoly
          practices
        </p>

        <div
          style={{
            width: "100%",
            height: "300px",
            border: "1px solid black",
          }}
        >
          <p>Board of Directors</p>
        </div>

        <h4>
          GRI 205-1, GRI 2-15, GRI 205-2, GRI 205-3 and 206-1, GRI 2-9 and 2-11,
          GRI 2-12, 2-13 and 2-14, GRI 2-18, 2-17, GRI 2-10
        </h4>
      </div>
      {/* page 14 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>
          Remuneration Paid/Payable to the Directors of the Company for FYXX
        </p>

        <p>
          The ratio of each Director's remuneration to the median employee
          remuneration, along with the percentage change in remuneration
        </p>
        <table
          border="1"
          cellPadding="10"
          style={{ borderCollapse: "collapse", width: "100%" }}
        >
          <thead>
            <tr>
              <th>Director</th>
              <th>Salary</th>
              <th>Bonus</th>
              <th>Benefits</th>
              <th>Sitting Fees </th>
              <th>Commission to Independent Directors</th>
              <th>Total in In Rupees</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            marginTop: "50px",
            display: "grid",
            gridTemplateColumns: "repeat(4, 250px)",
            gridTemplateRows: "repeat(3, 100px)",
            gap: "0",
          }}
        >
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
          <div
            style={{ border: "1px solid", height: "100px", width: "250px" }}
          ></div>
        </div>

        <p>
          Pic of Board of Director with their role and responsibilities: 1:
          Audit Committee. 2: Corporate Social Responsibility (CSR) Committee.
          3: Risk Management Committee
        </p>
        <p>
          4: Corporate Governance and Ethics Committee. 5: Nomination and
          Remuneration Committee. 6: Stakeholders’ Relationship Committee
        </p>

        <h4>
          GRI 2-12, GRI 2-17, GRI 2-13 and 2-14, GRI 2-19 and 2-20, GRI 2-21
        </h4>
      </div>

      {/* page 15 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
          Committees of the Board
        </h4>

        <p style={{ marginTop: "0px", marginBottom: "10px" }}>
          Boards typically establish several committees, each with clearly
          defined roles and responsibilities, to ensure that issues are
          addressed effectively and that diverse matters are resolved promptly.
          These committees meet regularly according to a pre-determined
          schedule, deliberate on technical or specialized matters, and offer
          counsel and advice to the Board. Additionally, these committees play a
          critical role in providing oversight and guidance on evaluating and
          implementing ESG-related topics
        </p>

        <div
          className="grid-report"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", height: "280px" }}>
            <img src="" alt="Indicator logo 1" />
            <p style={{ fontSize: "16px" }}>
              Audit Committees are responsible for overseeing the financial
              reporting process, reviewing internal financial controls, and
              assessing the risk management system. They also manage the
              whistleblower or vigil mechanism, evaluate the effectiveness of
              the audit process, and facilitate communication between internal
              and external auditors. Additionally, Audit Committees approve
              transactions with related parties and evaluate internal financial
              controls and risk management systems, among other
              responsibilities.
            </p>
          </div>
          <div style={{ border: "1px solid black", height: "280px" }}>
            <img src="" alt="Indicator logo 1" />
            <p style={{ fontSize: "16px" }}>
              Corporate Governance and Ethics Committees are responsible for
              monitoring compliance with corporate governance guidelines, making
              recommendations to the Audit Committee and the Board on related
              matters, and suggesting any necessary corrective actions. They
              also review and ensure the implementation of ethical standards and
              best practices in corporate governance.
            </p>
          </div>
          <div style={{ border: "1px solid black", height: "280px" }}>
            <img src="" alt="Indicator logo 1" />
            <p style={{ fontSize: "16px" }}>
              Corporate Social Responsibility (CSR) Committees are primarily
              responsible for formulating the annual action plan and making
              recommendations to the Board regarding the amount of expenditure
              for CSR initiatives. They also monitor and review the utilization
              of funds for various CSR-related activities and projects in
              accordance with the organization's CSR policy
            </p>
          </div>
          <div style={{ border: "1px solid black", height: "280px" }}>
            <img src="" alt="Indicator logo 1" />
            <p style={{ fontSize: "16px" }}>
              Nomination and Remuneration Committees review and approve the
              Remuneration Policy and establish criteria for evaluating
              Independent Directors. They also recommend and approve
              remuneration for Executive Directors and all forms of compensation
              for senior management. Additionally, these committees identify
              individuals qualified to become directors or be appointed to
              senior management positions based on established criteria and make
              recommendations to the Board regarding their appointment or
              removal, among other responsibilities.
            </p>
          </div>
          <div style={{ border: "1px solid black", height: "280px" }}>
            <img src="" alt="Indicator logo 1" />
            <p style={{ fontSize: "16px" }}>
              Risk Management Committees are responsible for implementing the
              Risk Management Policy, reviewing and assessing risks, and
              developing mitigation plans. They evaluate the adequacy of risk
              management systems, review internal control processes for
              identified risks, and assess the business continuity plan. The
              Committee ensures that effective methodologies, processes, and
              systems are in place to monitor and evaluate risks associated with
              the organization's business.
            </p>
          </div>
          <div style={{ border: "1px solid black", height: "280px" }}>
            <img src="" alt="Indicator logo 1" />
            <p style={{ fontSize: "16px" }}>
              The primary function of Stakeholder Committees is to address the
              concerns of security holders and review adherence to service
              standards and measures. The committee also takes steps to reduce
              the amount of unclaimed dividends.
            </p>
          </div>
        </div>
        <h4>GRI 2-9, GRI 2-16, GRI 2-20</h4>
      </div>

      {/* page 16 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>Policies: </p>
        <p>
          Alongside a strong governance structure, our comprehensive policies
          offer a detailed framework for responsible business conduct throughout
          our operations and value chain. These policies set clear expectations
          for all employees and business partners and serve as guiding documents
          for various business activities
        </p>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", height: "50px" }}> 1</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 2</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 3</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 4</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 5</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 6</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 7</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 8</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 9</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 10</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 11</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 12</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 13</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 14</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 15</div>
          <div style={{ border: "1px solid black", height: "50px" }}> 16</div>
        </div>

        <strong>
          <p style={{ fontWeight: "600" }}> Grievance Redressal</p>
        </strong>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Free Text</p>

          <img
            src={page16}
            style={{ height: "300px", width: "500px" }}
            alt="page-16"
          />
        </div>

        <h4>GRI 2-25 and 2-26, GRI 2-27</h4>
      </div>

      {/* page 17 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ fontWeight: "600" }}>Strategic Risk Management</p>{" "}
        <p>Focus on Risk Mitigation</p>
        <h4>
          At [Organization Name], risk management is an integral part of our
          corporate strategy. We emphasize risk assessment, mitigation, and
          de-risking while continuously enhancing our risk management
          capabilities.
        </h4>
        <p>
          From strategic and regulatory risks to financial, operational, and
          market risks, organizations are often exposed to various uncertainties
          due to the nature, scale, and geographic reach of their operations.
          Through an Enterprise Risk Management (ERM) framework, these risks can
          be closely monitored, analyzed, and mitigated. Guided by a
          comprehensive risk governance mechanism, an integrated risk management
          approach enables organizations to navigate both current and emerging
          risks effectively while pursuing their business objectives with
          resilience and strength.
        </p>
        <p style={{ fontWeight: "600" }}>
          {" "}
          Framework for Enterprise Risk Management
        </p>{" "}
        <h4>Free text</h4>
        <p style={{ fontWeight: "600" }}>Risk Governance Structure</p>{" "}
        <img style={{ width: "100%", height: "300px" }} src={page17} alt="" />
      </div>

      {/* page 18  */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>
          At [Organization Name], we take a cross-functional approach to risk
          management, ensuring cohesive responses to risk incidents. The Board
          of Directors has established a Risk Management Committee (RMC)
          responsible for overseeing risk management and evaluating mitigation
          plans. Our Internal Audit team collaborates with business units to
          identify and monitor risks, testing mitigation strategies during
          reviews.
        </p>

        <p>
          Our materiality assessment process captures stakeholder perspectives,
          guiding risk responses to critical issues that impact value creation.
          We review material topics annually to adapt to changes in global
          trends or strategy. Additionally, a Familiarization Program is in
          place to equip Independent Directors with the knowledge needed for
          effective risk oversight, with several having experience in risk
          management
        </p>

        <p style={{ fontWeight: "600" }}>Risk Management Approach</p>

        <p>
          We promptly escalate new risks and review existing ones at least twice
          a year or more frequently if needed. If an adverse incident occurs,
          management promptly communicates with all relevant stakeholders.
          Updates are provided to the Board based on the severity of the event.
          Additionally, every six months, the Risk Management Committee is
          briefed on newly identified key emerging risks, ensuring a dynamic
          risk management approach and transparency in risk outlook.
        </p>

        <img src={page18} alt="" style={{ width: "100%", height: "400px" }} />
      </div>

      {/* page 19  */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>ERM Framework </p>
        <img src={page19} alt="" style={{ width: "100%" }} />

        <p style={{ fontWeight: "600" }}>Key Risks</p>

        <p>
          Following a thorough assessment by management, we have identified key
          risks that could affect our business operations, financial
          performance, and overall success. Below is a summary of these
          significant risks and their potential impacts, which warrant careful
          attention regarding the organization's exposure. The listed mitigation
          actions are not exhaustive but reflect our comprehensive approach to
          managing these risks.
        </p>

        <div style={{ padding: "0px 20px" }}>
          <h4>A. Corporate Governance and Business Ethics</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span>
            Meets the requirements for maintaining high standards of compliance
            across various markets, staying current with changing regulations,
            and upholding and enforcing ethical business practices
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          Failing to uphold the highest standards of corporate governance and
          business ethics could result in regulatory consequences, as well as
          financial and reputational damage.
        </p>

        <p>
          We minimize non-compliance risk and ensure regulatory adherence across
          our operations by actively engaging with regulatory bodies. We enforce
          strict adherence to company policies through regular training on
          business ethics and the code of conduct. We also emphasize quality
          control measures at all operational locations to maintain compliance
          with good manufacturing practices (cGMP). Additionally, we keep
          accurate records and implement robust internal controls to comply with
          all relevant anti-bribery laws, including the U.S. Foreign Corrupt
          Practices Act (FCPA) and local regulations in every country where we
          operate.
        </p>
      </div>

      {/* page 20  */}
      <div>
        <div style={{ padding: "0px 20px" }}>
          <h4>B. Product Quality, Safety and Recall Management</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span>Ensuring
            product safety throughout their lifecycle involves addressing
            aspects such as adverse event reporting, adherence to Good
            Manufacturing Practices (GMP) regulations, and communication of
            safety-related information, in accordance with current Good
            Manufacturing Practices (cGMP).
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          Significant issues with product quality and safety could lead to
          recalls and warnings from regulatory agencies, causing temporary
          disruptions to operations and potential damage to brand reputation.
          Such issues may also increase the risk of litigation, fines, and
          penalties.
        </p>

        <p>
          We ensure strict adherence to global quality standards and procedures.
          We use comprehensive and centralized pharmacovigilance processes with
          detailed standard operating procedures (SOPs) to efficiently monitor
          and report adverse events, in compliance with relevant regulations,
          including the Narcotic Drugs and Psychotropic Substances (NDPS) Act.
        </p>

        <p>
          We continually invest in technology, cGMP training, automation,
          digitalization, and employee development. We conduct regular quality
          reviews of third-party suppliers. Additionally, we implement brand
          protection measures, including intellectual property and trademark
          safeguards, as well as anti-counterfeit strategies, to maintain
          product authenticity in the market
        </p>

        <div style={{ padding: "0px 20px" }}>
          <h4>C. Cyber security and Data Privacy</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span>{" "}
            Vulnerabilities can emerge from outdated systems, infrequent
            technology updates, and potential cyber threats such as hacking and
            data breaches, which can jeopardize sensitive information and
            digital assets
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          The absence of a strong data integrity and security framework could
          lead to a higher incidence of data breaches, resulting in the loss of
          valuable information that could negatively impact the business.
        </p>

        <p>
          To prevent breaches involving company and stakeholder data, we
          regularly assess the vulnerability of our technology and IT systems.
          We follow established processes and guidelines to ensure compliance
          with data security and privacy laws, such as the General Data
          Protection Regulation (GDPR). We employ perimeter security, IT
          monitoring systems, antivirus software, and patch management to
          address cybersecurity and data breach risks. Additionally, we provide
          cyber security training for our employees and enhance their awareness
          through periodic internal communications on safe data practices,
          protection against phishing emails, and prevention of hacker attacks.
        </p>

        <div style={{ padding: "0px 20px" }}>
          <h4>D. Human Capital Development</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span>Focusing on and
            investing in talent management initiatives, such as acquiring,
            retaining, developing, and ensuring the well-being and satisfaction
            of employees, is essential
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          Since our business relies on the well-being of our employees, failing
          to meet or exceed their expectations could negatively affect employee
          retention, productivity, and business continuity, ultimately impacting
          organizational growth.
        </p>

        <p>
          We have various initiatives to attract and retain talent, including
          development programs focused on global talent management, competitive
          compensation, an inclusive work culture, and other employee benefits.
          We also have a formal succession planning program for all leadership
          roles and ensure ongoing skill enhancement through continuous training
          and development.
        </p>
      </div>

      {/* page 21 */}
      <div>
        <div style={{ padding: "0px 20px" }}>
          <h4>E. Product Access and Affordability</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span> It is crucial
            to address challenges related to the product portfolio, product
            accessibility, and pricing.
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          Over time, limited product accessibility and an inability to expand
          geographically may adversely affect the organization's brand value and
          long-term growth.
        </p>

        <p>
          We concentrate on building a strong and diverse product portfolio by
          improving cross-functional collaboration, organizational capabilities,
          project management, and governance related to product identification,
          development, planning, and launch. We are enhancing our in-licensing
          and out-licensing activities. We prioritize the development and
          commercialization of complex generics and specialty products, among
          others. Additionally, we implement operational excellence programs to
          improve yields, ensure supply chain continuity, and maintain
          sufficient inventories.
        </p>

        <div style={{ padding: "0px 20px" }}>
          <h4>F. Climate Change</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span>Poor management
            of greenhouse gas (GHG) emissions may expose the organization to
            climate-related physical and transition risks, potentially
            disrupting operations and impacting business continuity.
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          Immediate physical risks to operations can damage assets, causing
          business interruptions and increased costs for repairs and site
          restoration. Transition risks related to climate change might lead to
          stricter regulations in operational and export markets, resulting in
          higher compliance and investment costs for new technologies.
          Additionally, failure to adapt to the negative impacts of climate
          change can result in reputational damage and loss of stakeholder trust
        </p>

        <p>
          We have set a target to reduce greenhouse gas (GHG) emissions (Scope 1
          and Scope 2) by 15% by 203, relative to the 2020 baseline year. We
          also perform climate risk assessments across our operations to
          evaluate both physical and transition risks. We are actively seeking
          ways to decrease our dependence on fossil fuels by increasing the use
          of biomass, procuring renewable energy, and implementing energy
          efficiency initiatives. These efforts include enhancing energy
          efficiency at our facilities, starting energy reduction projects, and
          transitioning from non-renewable to renewable energy sources
        </p>
      </div>

      {/* page 22  */}
      <div>
        <div style={{ padding: "0px 20px" }}>
          <h4>G. Environmental Impact Management</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span> Dedicated
            efforts towards efficient water usage, minimizing waste generation,
            and proper disposal reflect the organization's commitment to
            sustainability and a healthy planet.
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          Failing to manage environmental impacts can result in legal,
          regulatory, and financial repercussions, damage to reputation and
          stakeholder trust, and could ultimately lead to the loss of operating
          licenses
        </p>

        <p>
          We actively seek opportunities to manage our environmental impact and
          have set targets for water conservation and waste management. We aim
          to reduce water consumption by 5% by 2025 and co-process 20% of
          hazardous waste by 2030 compared to the 2020 baseline year. We
          continuously monitor our performance on water usage and waste
          management, focusing on efficient water consumption, reducing water
          withdrawal, and enhancing water recovery. For waste management, we
          prioritize increasing recycling and reuse within our operations and
          co-processing hazardous waste. We have implemented a comprehensive
          Environment, Health, and Safety (EHS) policy and procedures to ensure
          compliance with Central Pollution Control Board guidelines and local
          regulations set by State Pollution Control Boards.
        </p>

        <div style={{ padding: "0px 20px" }}>
          <h4>H. Sustainable Supply Chain and Responsible Procurement</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span>Disruptions in
            the supply chain can affect business continuity and product quality,
            while the risk of relying on non-substitutable suppliers may impact
            the availability of essential raw materials.
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          Long-term commercial relationships with suppliers may be jeopardized
          if they fail to meet social, environmental, and safety standards,
          potentially resulting in a loss of business value. Additionally,
          disruptions from suppliers of non-substitutable and critical raw
          materials could significantly impact the business.
        </p>

        <p>
          We consistently seek ways to mitigate supply chain risks by evaluating
          alternative suppliers for critical or non-substitutable raw materials.
          According to our Supplier Code of Conduct, suppliers must comply with
          the organization's ESG standards outlined in the code. We focus on
          producing high-quality products to ensure consumer safety and maintain
          the quality of raw materials by performing regular supplier audits
        </p>

        <div style={{ padding: "0px 20px" }}>
          <h4>I. Occupational Health and Safety (OHS)</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span> Occupational
            Health and Safety (OHS) is crucial to the organization's commitment
            to maintaining a safe and secure work environment. Ineffective
            health and safety management programs can result in numerous health
            and safety incidents.
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          Frequent health and safety incidents can adversely affect the
          organization's safety performance and workforce well-being,
          potentially damaging its brand image, reputation, and ability to
          attract and retain talent
        </p>

        <p>
          We have a comprehensive Environmental, Health, and Safety (EHS)
          management system, which includes regular internal and external audits
          of our EHS practices. Our risk assessment methodology and safety
          procedures are based on the principles of our Process Safety
          Management System, ensuring a cohesive approach to risk assessment and
          working conditions. We implement a detailed corrective action plan
          after identifying hazards and evaluating safety incidents to help
          prevent future occurrences
        </p>
      </div>

      {/* page 23 */}
      <div>
        <div style={{ padding: "0px 20px" }}>
          <h4>J. Business Interruption/Operational Inefficiencies</h4>

          <p style={{ padding: "0px 20px" }}>
            <span style={{ fontWeight: "600" }}>Overview: </span>Disruptions or
            inefficiencies arising from natural disasters, regulatory delays,
            cyber security threats, or labor shortages can impact the efficiency
            of manufacturing and supply chains
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            Possible Consequence
          </h4>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
          <h2 style={{ whiteSpace: "nowrap", padding: "0 10px" }}>
            &gt;&gt;&gt; Reduction
          </h2>
          <div style={{ width: "100%", borderBottom: "1px solid blue" }}></div>
        </div>

        <p>
          This risk could result in revenue loss, higher operational costs, and
          potentially harm the organization's reputation. Delays in market entry
          may impact competitiveness, and data breaches could lead to increased
          legal and financial liabilities
        </p>

        <p>
          We conduct quarterly planning to ensure the availability of critical
          raw materials and avoid stock outs, maintaining a three-month safety
          stock for key products. In case of supply delays, we use air shipments
          to reduce lead times. Senior management oversees regulatory compliance
          and product launch timelines, while we keep critical spares at
          multiple sites and install backup solutions to address power and raw
          material shortages. We manage labor shortages through timely
          recruitment and regular operational reviews to ensure business
          continuity. Here's a general version of the paragraph suitable for any
          organization:
        </p>

        <p style={{ fontWeight: "600" }}>Emerging Risks</p>

        <p>
          In our risk management process, we assess the likelihood, impact, and
          timing of risks. Alongside current risks, we review emerging risks at
          least every three years to ensure timely remediation and prevent
          adverse consequences. As part of our risk management framework, we
          identify emerging risks based on their probability of occurrence and
          potential impact on the business. This involves a comprehensive
          analysis of internal and external data, industry trends, market
          conditions, regulatory changes, and expert insights. This systematic
          approach helps us recognize potential risks that could negatively
          affect the business and allows for the timely implementation of
          mitigation strategies
        </p>

        <p>
          We have identified key emerging risks, such as evolving regulatory
          changes and the potential misuse of technology, as significant
          long-term concerns. We are actively working to develop and implement
          strategies to address these risks and protect our organization.
        </p>

        <p style={{ fontWeight: "600" }}>Risk Culture</p>

        <p>
          Beyond a solid risk management framework for identifying and
          mitigating risks, we understand the importance of fostering a strong
          risk culture throughout the organization. Establishing a comprehensive
          and effective risk culture is crucial for successful risk management.
        </p>

        <p>
          We provide targeted risk training for employees at all levels and
          ensure alignment with compliance and regulatory requirements,
          including environmental considerations such as reducing resource usage
          and waste, managing emissions, and adopting sustainable practices.
        </p>

        <p>
          Employees are encouraged to report potential risks, and our internal
          audit team coordinates across departments to address various risk
          types. We offer platforms for employees to raise concerns and
          highlight risks, which helps refine our mitigation strategies and
          strengthens our risk culture.
        </p>

        <p>
          Additionally, we incorporate risk criteria into all aspects of our
          operations, including product development and approval. Our global
          quality standards ensure that critical information on managing risk is
          integrated throughout a product's lifecycle, from development to
          disposal.
        </p>
      </div>

      {/* page 24 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ fontWeight: "600" }}>
          Stakeholder Engagement and Materiality Assessment
        </p>

        <p>Free Text</p>
        <img src={page24} alt="" style={{ width: "100%", height: "500px" }} />
      </div>

      {/* page 25 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ fontWeight: "600" }}>Stakeholder Engagement Methodology</p>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            width: "100%",
          }}
        >
          <div
            style={{
              border: "1px solid #000",
              height: "300px",
              padding: "10px",
            }}
          >
            <p>
              <strong>Refining Stakeholder Priorities and Interests</strong>
            </p>
            <p>- Identifying Key Internal Stakeholders</p>
            <p>- Identifying External Stakeholders</p>
            <p>- Gathering Topics of Interest from Previous Engagements</p>
          </div>

          <div
            style={{
              border: "1px solid #000",
              height: "300px",
              padding: "10px",
            }}
          >
            <p>
              <strong>
                Conversational Engagement and Managing Stakeholder Expectations
              </strong>
            </p>
            <p>- Conducting Structured and Needs-Based Conversations</p>
            <p>- Collecting Stakeholder Feedback and Expectations</p>
            <p>
              - Reviewing Business Strategy and Addressing Stakeholder
              Expectations
            </p>
          </div>

          <div
            style={{
              border: "1px solid #000",
              height: "300px",
              padding: "10px",
            }}
          >
            <p>
              <strong>
                Utilizing Stakeholder Insights and Reporting Progress
              </strong>
            </p>
            <p>
              - Integrating Stakeholder Feedback into Business Strategy Reviews
            </p>
            <p>- Creating Programs to Address Stakeholder Expectations</p>
            <p>- Regularly Sharing Progress Updates with Stakeholders</p>
          </div>
        </div>

        <p style={{ fontWeight: "600" }}>Stakeholder Engagement</p>

        <p>
          Engaging with stakeholders is a key part of our materiality
          assessment. We consult a range of internal and external
          stakeholders—such as employees, customers, suppliers, regulators, and
          community representatives—to gather insights on sustainability
          priorities and key issues. This includes structured interviews,
          surveys, and workshops to understand stakeholder views on ESG matters
          and determine which topics to prioritize. This process ensures our
          efforts align with stakeholder expectations and needs
        </p>

        <p style={{ margin: "0px", fontWeight: "600" }}>
          Membership in associations focused on sustainability issues such as
          child labor, human trafficking, and environmental stewardship.: ………
        </p>

        <p style={{ margin: "0px", fontWeight: "600" }}>
          {" "}
          Engagement with External stakeholders (Communities, NGO):………….
        </p>

        <p style={{ marginBottom: "0px", fontWeight: "600" }}>
          Materiality Assessment
        </p>

        <p>
          The materiality assessment involves identifying and prioritizing key
          social, environmental, and governance issues significant to the
          business and stakeholders. This process helps focus on critical risks
          and opportunities, following standards like the Global Reporting
          Initiative (GRI) and the Sustainability Accounting Standards Board
          (SASB). We plan to incorporate double materiality in our next
          reporting cycle
        </p>

        <p style={{ fontWeight: "600" }}>
          The key steps in our materiality assessment process include:
        </p>

        <p style={{ fontWeight: "600" }}>Identification of Material Topics</p>

        <p>
          We begin by identifying a thorough list of potential ESG topics,
          drawing from global standards, peer benchmarking, stakeholder
          feedback, and industry trends. We use frameworks like GRI and SASB to
          ensure all relevant issues are covered comprehensively.
        </p>

        <p style={{ fontWeight: "600" }}>Prioritization of Topics</p>

        <p>
          We analyze and prioritize stakeholder inputs to identify the most
          critical topics, considering their impact on long-term business
          viability and their importance to stakeholders. This process includes
          creating a materiality matrix, which visually represents the relative
          significance of each topic to both the business and its stakeholders,
          highlighting key areas of focus.
        </p>

        <p style={{ fontWeight: "600" }}>Validation and Review</p>

        <p>
          The prioritized list of material topics is reviewed and confirmed
          through internal discussions and additional stakeholder consultations
          to ensure it accurately reflects key issues. The materiality matrix is
          refined at this stage to accurately represent these priorities.
        </p>

        <h4>GRI 2-29</h4>
      </div>

      {/* page 26       */}
      <div style={{ pageBreakBefore: "always" }}>
        <strong>
          {" "}
          <p> Materiality Matrix</p>
        </strong>

        <p style={{ fontWeight: "600" }}>For graph of materiality matrix</p>

        <p style={{ fontWeight: "600" }}>Continuous Improvement: </p>

        <p>
          We regularly revisit our materiality assessment to ensure it remains
          relevant and reflective of changing stakeholder expectations and
          emerging trends. This ongoing process allows us to adapt and improve
          our ESG practices continuously, maintaining alignment with the latest
          standards and norms
        </p>

        <p style={{ fontWeight: "600" }}>Integration into Practices: </p>

        <p>
          The identified material topics guide our actions and resource
          allocation, helping us focus on creating positive impacts across the
          most important issue areas. This ensures that our efforts are
          strategically aligned with the most critical ESG issues. By conducting
          a robust materiality assessment, at our organization can lead on the
          most critical ESG topics, align with global reporting frameworks,
          prioritize resources effectively, and build strong relationships with
          stakeholders. This process not only drives positive change within the
          organization but also enhances our reputation and long-term success.
        </p>
        <h4>GRI 3-1, 3-2,3-3</h4>
      </div>

      {/* page 27 */}

      <div style={{ pageBreakBefore: "always" }}>
        <p>Our ESG Strategy</p>

        <p>Integrating ESG into Business Goals</p>

        <p>
          At the core of our ESG strategy are our values and principles, which
          shape our actions and decisions. Integrity, innovation,
          responsibility, and dedication form the basis of our approach to ESG.
          These values drive us to maintain the highest ethical standards,
          promote innovation, and stay committed to making a positive impact on
          society and the environment.
        </p>

        <h4>Our Values</h4>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: "20px",
            width: "100%",
          }}
        >
          <div style={{ border: "1px solid #000", padding: "10px" }}>
            <img
              src={quality}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <p>
              <strong>Quality</strong>
            </p>
            <p>
              We prioritize delivering high-quality products and solutions that
              meet the needs of customers and stakeholders worldwide.
            </p>
          </div>
          <div style={{ border: "1px solid #000", padding: "10px" }}>
            <img
              src={reliability}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <p>
              <strong>Reliability</strong>
            </p>
            <p>
              We strive to be a trusted partner by consistently fulfilling our
              commitments and ensuring a dependable delivery of products and
              services.
            </p>
          </div>
          <div style={{ border: "1px solid #000", padding: "10px" }}>
            <img
              src={trust}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <p>
              <strong>Trust</strong>
            </p>
            <p>
              We value the trust placed in us by our stakeholders and work
              diligently to maintain it through transparency, accountability,
              and responsible business practices.
            </p>
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: "20px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <div style={{ border: "1px solid #000", padding: "10px" }}>
            <img
              src={consistency}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <p>
              <strong>Consistency</strong>
            </p>
            <p>
              We ensure consistency in our operations by aligning our actions
              with our goals and commitments.
            </p>
          </div>
          <div style={{ border: "1px solid #000", padding: "10px" }}>
            <img
              src={innovation}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <p>
              <strong>Innovation</strong>
            </p>
            <p>
              We cultivate a culture of innovation to drive progress in
              addressing unmet needs and enhancing outcomes for our
              stakeholders.
            </p>
          </div>
        </div>

        <div style={{ width: "100%", overflowX: "auto", marginTop: "20px" }}>
          <p style={{ textAlign: "center", fontWeight: "600" }}>Environment</p>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "seaGreen" }}>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  ESG Enablers
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  Our ESG Focus Area
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  ESG Ambitions and Integration
                </th>

                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  UN SDGs
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  - Committed to increasing the use of renewable energy sources.
                  <br />
                  - Reducing reliance on natural resources.
                  <br />
                  - Implementing conservation practices based on the reduce,
                  reuse, recycle, and recharge (4R) principle.
                  <br />
                  - Adopting digitalization and eco-efficient processes.
                  <br />- Diverting hazardous waste for responsible processing.
                </td>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  Environmental Management: We are committed to reducing our
                  environmental impact by implementing sustainable practices,
                  minimizing energy use, optimizing resource consumption, and
                  effectively managing waste and emissions.
                </td>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  - Carbon Emission Reduction: Aiming for a 35% reduction by
                  2030 (baseline: 2020).
                  <br />
                  - Responsible Water Management: Striving for a 10% reduction
                  in water usage by 2025 (baseline: 2020).
                  <br />
                  - Sustainable Waste Management: Targeting to co-process 30% of
                  hazardous waste by 2025.
                  <br />- Promoting Responsible Resource Consumption: Focusing
                  on advancing eco-friendly practices throughout our operations.
                </td>

                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  <img src={enviro} alt="env-table" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ width: "100%", overflowX: "auto", marginTop: "20px" }}>
          <p style={{ textAlign: "center", fontWeight: "600" }}>Social</p>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#add8e6" }}>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  ESG Enablers
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  Our ESG Focus Area
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  ESG Ambitions and Integration
                </th>

                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  UN SDGs
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  - We prioritize the development of our workforce through
                  tailored learning and development programs that enhance
                  skills.
                  <br />
                  - We uphold a comprehensive health and safety management
                  system that prioritizes employee wellbeing and complies with
                  human rights policies and relevant regulations.
                  <br />
                  - Our Supplier Code of Conduct ensures that our suppliers and
                  partners adhere to our values and policies.
                  <br />- We implement a rigorous Quality Management System
                  (QMS) to guarantee product quality and safety.
                </td>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  - Employee Well-being: We support the well-being and growth of
                  our employees through targeted learning and development
                  programs, comprehensive health and safety management systems,
                  and policies that promote a healthy work-life balance.
                  <br />- Societal Development: We contribute to the communities
                  where we operate through our CSR initiatives, focusing on
                  education, healthcare, and social welfare.
                </td>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  - Empowering Global Employees: Offering targeted online
                  training on Code of Conduct principles.
                  <br />
                  - Cultivating an Agile Workforce: Developing a diverse skill
                  set for greater adaptability.
                  <br />
                  - Upholding Health and Safety Standards: Applying industry
                  best practices for optimal protection.
                  <br />
                  - Ensuring Human Rights Compliance: Committing to a
                  zero-tolerance policy for non-compliance.
                  <br />- Enhancing Access to Healthcare: Providing safe,
                  effective, and fairly priced products.
                </td>

                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  <img src={social2} alt="env-table" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* page 28 */}

      <div style={{ pageBreakBefore: "always" }}>
        <div style={{ width: "100%", overflowX: "auto", marginTop: "20px" }}>
          <p style={{ textAlign: "center", fontWeight: "600" }}>Governance</p>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",

                    width: "25%",
                  }}
                >
                  ESG Enablers
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",

                    width: "25%",
                  }}
                >
                  Our ESG Focus Area
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",

                    width: "25%",
                  }}
                >
                  ESG Ambitions and Integration
                </th>

                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    width: "25%",
                  }}
                >
                  UN SDGs
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  - We have six core committees within the Board, including
                  those dedicated to sustainability and corporate
                  responsibility.
                  <br />
                  - We implement a Global Code of Conduct across our value chain
                  to ensure ethical practices.
                  <br />- We follow a Board Diversity Policy and regularly
                  assess Board performance to promote accountability and
                  transparency.
                </td>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  - Corporate Governance: We uphold strong corporate governance
                  practices by adhering to global best practices and fostering
                  transparency, accountability, and integrity in all our
                  operations.
                  <br />
                  - Innovation and Technology: We embrace innovation and utilize
                  technology to advance research, development, manufacturing
                  processes, and enhance patient care.
                  <br />- Product Quality & Safety: We ensure responsible
                  product management by focusing on the safety, efficacy, and
                  quality of our products throughout their entire lifecycle,
                  from development to post-market surveillance.
                </td>
                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  - Committed to Regulatory and ESG Compliance: Adhering to high
                  standards for regulatory and ESG requirements.
                  <br />- Integrating ESG Parameters: Advancing sustainability
                  throughout the value chain.
                </td>

                <td style={{ border: "1px solid #000", padding: "8px" }}>
                  <img src={gover} alt="env-table" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* page 29 */}

      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ fontWeight: "600" }}>Responsible Future</p>

        <p> Improving Access to High-Quality Healthcare</p>

        <p>
          Achieving universal health coverage depends on providing safe and
          effective medicines at affordable prices. As a leading global
          pharmaceutical company, we are dedicated to delivering high-quality
          and affordable medications to patients and healthcare professionals
          across over XX countries
        </p>

        <img src={page29} alt="" style={{ width: "100%", height: "400px" }} />

        <p>Free Text</p>
      </div>

      {/* page 30 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ fontWeight: "600" }}>Customer Safety</p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>
            We implement rigorous review and quality control mechanisms to
            ensure regulatory compliance and uphold the highest standards of
            product quality for our medicines and manufactured APIs. Identifying
            and addressing risks related to product health and safety in a
            timely manner is crucial for enhancing product quality, ensuring
            patient safety, and maintaining stakeholder trust. Failure to uphold
            product quality may result in penalties and warnings from regulatory
            authorities, potentially damaging our brand value and stakeholder
            perception. We adhere to all quality and regulatory compliance
            standards and continuously monitor product safety to assess and
            manage the risk-benefit profile of our products.
          </p>

          <img src={page30} alt="" style={{ width: "100%" }} />
        </div>
        <strong>
          <p>Product Quality</p>
        </strong>
      </div>

      {/* page 31 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>
          Our Quality Vision is to globalize, harmonize, and streamline Good
          Practices processes to foster a lasting culture of quality. We aim to
          continuously enhance our Quality Management System (QMS) and its
          components. We promote a high-quality culture through ongoing employee
          development, training, and empowerment. We believe that ensuring the
          production of safe, high-quality products is a responsibility shared
          by everyone.
        </p>

        <p>
          In line with our Quality Vision, we have adopted a comprehensive
          approach to quality management, integrating our global QMS with
          industry-leading practices and assurance procedures. Our commitment to
          quality includes a robust framework for handling complaints,
          conducting thorough investigations, and implementing corrective
          actions. Our quality management team ensures that product quality
          adheres to stringent quality and safety standards. Some of our API and
          formulation manufacturing sites are ISO 9001:2015 certified,
          highlighting our dedication to maintaining high-quality standards in
          our manufacturing processes. Our Quality Practices encompass
          sustainable quality design, quality data governance, process
          harmonization for internal compliance, and global quality metrics,
          informed by past experiences
        </p>

        <img src={page31} alt="" style={{ width: "100%" }} />
      </div>

      {/* page 32           */}
      <div style={{ pageBreakBefore: "always" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",

            width: "100%",
          }}
        >
          <div
            style={{
              border: "1px solid black",
              padding: "20px",
              height: "auto",
            }}
          >
            <h3>Quality Management System (QMS)</h3>
            <p>- Global QMS</p>
            <p>
              - Cross-functional implementation across R&D, quality, and
              operations
            </p>
            <p>
              - Adoption of best practices to ensure the delivery of
              high-quality products
            </p>
          </div>

          <div
            style={{
              border: "1px solid black",
              padding: "20px",
              height: "auto",
            }}
          >
            <h3>Quality Practices</h3>
            <p>- Sustainable quality design</p>
            <p>- Quality data governance</p>
            <p>- Harmonization of processes for improved compliance</p>
            <p>- Global quality metrics</p>
            <p>- Strategy based on lessons learned</p>
          </div>

          <div
            style={{
              border: "1px solid black",
              padding: "20px",
              height: "auto",
            }}
          >
            <h3>Key Elements of our QMS</h3>
            <p>
              <strong>Procedural Documents:</strong> Electronic management with
              controlled printing, access, and versioning
            </p>
            <p>
              <strong>Deviation Analysis:</strong> Regular trend analysis of
              deviations
            </p>
            <p>
              <strong>Training:</strong> Instructor-led and e-learning systems,
              including Advanced Pharmacovigilance courses
            </p>
            <p>
              <strong>Good Documentation Practices:</strong> Adherence to SOPs
              for documentation
            </p>
            <p>
              <strong>Corrective and Preventive Actions (CAPA):</strong>{" "}
              Comprehensive management of product quality complaints, including
              assessment, investigation, and corrective actions
            </p>
          </div>
        </div>

        <h4>GRI 3-3, GRI 416-1, GRI 416-2</h4>

        <img src={page32} alt="" style={{ width: "100%" }} />
      </div>

      {/* page 33  */}
      <div style={{ pageBreakBefore: "always" }}>
        <div
          style={{
            width: "100%",
            border: "1px solid black",
            borderCollapse: "collapse",
            display: "table",
          }}
        >
          <div style={{ display: "table-row" }}>
            <div
              style={{
                display: "table-cell",
                textAlign: "center",
                padding: "10px",
                fontWeight: "bold",
              }}
              colSpan={3}
            >
              Quality Assurance Process
            </div>
          </div>

          <div style={{ display: "table-row" }}>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              Adherence to GxP regulations and local country-specific
              regulations
            </div>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              Regular inspections are conducted at manufacturing sites to meet
              GxP certification requirements set by regulatory agencies.
            </div>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              Release of raw materials, including APIs and packaging materials,
              after qualification and testing
            </div>
          </div>

          <div style={{ display: "table-row" }}>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              Ensuring the quality of finished products through in-process
              testing, final product testing, and stability testing
            </div>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              Strict compliance is maintained with specifications approved by
              regulatory agencies for each specific market requirement.
            </div>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              Preventing deviations, failures, and discrepancies by documenting
              investigations in the QMS
            </div>
          </div>

          <div style={{ display: "table-row" }}>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              A comprehensive QMS system that includes change management,
              deviation management, CAPA, adverse product event handling, field
              alert reporting, complaint management, and recall processes
            </div>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              Regular audits are performed by the Company’s Corporate Quality
              team at all manufacturing sites, contract manufacturing sites, and
              vendor locations.
            </div>
            <div
              style={{
                display: "table-cell",
                border: "1px solid black",
                padding: "10px",
              }}
            >
              Training employees involved in GxP activities through
              role-specific modules.
            </div>
          </div>
        </div>

        <h3 style={{ color: "orange" }}>
          Product Quality Complaint Management Process
        </h3>

        <p>
          Our Global Standard Operating Procedure (GSoP) outlines the guidelines
          for managing product recalls, including the conditions for initiating
          a recall. Products are recalled based on the site recall committee's
          recommendations, which involve evaluating the recall proposal,
          notifying relevant parties, and conducting trend analysis
        </p>

        <p>
          We employ a thorough approach to handle product quality complaints.
          Complaints are promptly recorded in our system and undergo a
          preliminary assessment. An initial risk assessment is conducted during
          the investigation, and sample follow-ups are performed. Based on the
          findings, Corrective and Preventive Actions (CAPA) are implemented to
          address any issues. Throughout the process, we document a summary of
          the complaint and conduct an assessment. A response is then provided
          to the complainant, ensuring effective communication and closure of
          the complaint.
        </p>

        <div>
          <ul>
            <li>
              <strong>Complaint Receipt</strong>
              <ul>
                <li>
                  <strong>Input:</strong> Complaint received
                </li>
                <li>
                  <strong>Action:</strong> Log complaint in the system
                </li>
              </ul>
            </li>
            <li>
              <strong>Preliminary Assessment</strong>
              <ul>
                <li>
                  <strong>Action:</strong> Review and assess the complaint
                </li>
                <li>
                  <strong>Outcome:</strong> Determine if further investigation
                  is needed
                </li>
              </ul>
            </li>
            <li>
              <strong>Initial Risk Assessment</strong>
              <ul>
                <li>
                  <strong>Action:</strong> Conduct initial risk assessment
                </li>
                <li>
                  <strong>Outcome:</strong> Prioritize based on risk level
                </li>
              </ul>
            </li>
            <li>
              <strong>Investigation</strong>
              <ul>
                <li>
                  <strong>Action:</strong> Perform investigation and follow-up
                </li>
                <li>
                  <strong>Outcome:</strong> Gather evidence and analyze findings
                </li>
              </ul>
            </li>
            <li>
              <strong>Corrective and Preventive Actions (CAPA)</strong>
              <ul>
                <li>
                  <strong>Action:</strong> Implement CAPA based on investigation
                  findings
                </li>
                <li>
                  <strong>Outcome:</strong> Address identified issues
                </li>
              </ul>
            </li>
            <li>
              <strong>Documentation</strong>
              <ul>
                <li>
                  <strong>Action:</strong> Document complaint summary and CAPA
                  actions
                </li>
                <li>
                  <strong>Outcome:</strong> Maintain records for review
                </li>
              </ul>
            </li>
            <li>
              <strong>Response to Complainant</strong>
              <ul>
                <li>
                  <strong>Action:</strong> Provide response to the complainant
                </li>
                <li>
                  <strong>Outcome:</strong> Communicate resolution and next
                  steps
                </li>
              </ul>
            </li>
            <li>
              <strong>Trend Analysis</strong>
              <ul>
                <li>
                  <strong>Action:</strong> Analyze trends from complaints
                </li>
                <li>
                  <strong>Outcome:</strong> Identify recurring issues and areas
                  for improvement
                </li>
              </ul>
            </li>
            <li>
              <strong>Closure</strong>
              <ul>
                <li>
                  <strong>Action:</strong> Close the complaint
                </li>
                <li>
                  <strong>Outcome:</strong> Ensure effective resolution and
                  closure
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      {/* page 34 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h3 style={{ color: "orange" }}>Responsible Product Stewardship</h3>

        <p>
          At OUR Organization, we prioritize implementing ethical practices
          throughout the entire product lifecycle, including accessibility,
          labeling, and disposal.
        </p>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",

            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Product Accessibility</h3>
            <p>
              We work to enhance the availability of our products in diverse
              international markets, urban areas, and rural locations. Our broad
              distribution network, which includes retailers, distributors,
              wholesalers, and logistics partners, allows us to ensure our
              products are accessible to patients globally.
            </p>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Product Labeling and Information</h3>
            <p>
              In line with our commitment to responsible product management, we
              provide customers with comprehensive product information,
              including pharmacokinetics, safe usage, composition, clinical
              pharmacology, drug interactions and side effects, and storage
              instructions. During the reporting year, we had no incidents of
              regulatory non-compliance that resulted in fines, penalties,
              warnings, or breaches of voluntary codes.
            </p>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Responsible Product Disposal</h3>
            <p>
              We follow a rigorous process for the safe disposal of returned or
              recalled products, in compliance with local safety and
              environmental regulations. We adhere to relevant laws and meet
              specific standards for each region. Additionally, we document the
              methods used for identifying product quantities and the dates of
              destruction.
            </p>
          </div>
        </div>
      </div>

      {/* page 35 */}
      <div style={{ pageBreakBefore: "always" }}>
        <img
          src={page35}
          alt="page-35-img"
          style={{ width: "100%", height: "500px" }}
        />

        <h4>GRI 3-3, GRI 417-1, GRI 417-2, GRI 417-3</h4>
      </div>

      {/* page 36 */}
      <div style={{ pageBreakBefore: "always" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",

            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", padding: "10px" }}>
            <img
              src={imperitive}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <h3>Imperative</h3>
            <ul>
              <li>Improve product quality and safety profiles</li>
              <li>Increase transparency and build trust with stakeholders</li>
              <li>
                Foster a culture of innovation throughout the organization
              </li>
              <li>
                Ensure timely evaluation and resolution of product queries and
                complaints
              </li>
            </ul>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <img
              src={cornerstones}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <h3>Cornerstones</h3>
            <ul>
              <li>
                Developed Quality Vision and implemented Quality, Compliance,
                and Pharmacovigilance Management Systems
              </li>
              <li>Integrated and strategic Quality Assurance Process</li>
            </ul>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <img
              src={strategy}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <h3>Strategic Enablers</h3>
            <ul>
              <li>A robust global pharmacovigilance policy</li>
              <li>A skilled global pharmacovigilance and quality team</li>
              <li>A Product Safety Committee</li>
              <li>A global QMS aligned with industry best practices</li>
            </ul>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <img
              src={aspirations}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <h3>Aspirations</h3>
            <ul>
              <li>Maintain ethical use of products</li>
              <li>
                Increase awareness about the proper use and disposal of our
                products
              </li>
            </ul>
          </div>
        </div>

        <p style={{ fontWeight: "600" }}>
          Anti-counterfeit Awareness and Processes
        </p>

        <p>
          We are dedicated to combating counterfeit medicines through a robust
          anti-counterfeit governance system. We focus on raising awareness
          about the risks associated with counterfeit drugs, particularly in
          regions where this issue is prevalent. To address this challenge, we
          have formed a specialized task force that manages the risks related to
          counterfeit medicines. This team works closely with our
          trace-and-track technology and complaint management system to ensure
          effective monitoring and enhanced safety and security.
        </p>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            pageBreakBefore: "always",

            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", padding: "4px" }}>
            <h3 style={{ color: "orange" }}>
              Anti-counterfeit Governance Mechanism
            </h3>
            <ul>
              <li>
                The trademark and learning and development (L&D) teams provide
                training to all field staff on how to identify and report
                counterfeit medicines.
              </li>
              <li>
                A specialized task force, including senior field members from
                various regions, receives training to spot counterfeit products.
                We have aligned this identification process with relevant key
                performance indicators (KPIs).
              </li>
            </ul>
          </div>

          <div style={{ border: "1px solid black", padding: "4px" }}>
            <h3 style={{ color: "orange" }}>
              Anti-counterfeit Management System
            </h3>
            <ul>
              <li>
                Report complaints and queries about counterfeit products
                immediately to the relevant regulatory authorities.
              </li>
              <li>
                Utilize trace-and-track technology to prevent the sale of
                counterfeit products.
              </li>
              <li>
                Collect feedback and complaints from both complainants and
                marketing representatives.
              </li>
              <li>
                Improve product packaging to make it easier to differentiate
                between genuine and counterfeit medicines.
              </li>
              <li>
                Use a comprehensive complaint management system to effectively
                handle suspected counterfeit cases.
              </li>
              <li>
                The task force is dedicated to developing standardized and
                unique packaging to reduce the risk of counterfeiting.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* page 37 */}
      <div>
        <h3 style={{ color: "orange" }}>Research and Development (R&D)</h3>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",

            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
        </div>

        <h4>GRI 3-3</h4>
      </div>

      {/* page 38 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>Our R&D Approach and Capabilities</p>

        <h3>Enablers</h3>
        <ul>
          <li>
            We invest significantly in R&D to develop complex, specialty, and
            generic products, as well as APIs, and to advance process
            improvement technologies.
          </li>
          <li>
            Our R&D team consists of over XXXX highly skilled professionals,
            equipped with cutting-edge infrastructure.
          </li>
          <li>
            We rigorously adhere to global regulatory standards to ensure the
            highest quality levels.
          </li>
          <li>
            We focus on pioneering new technologies, such as using green
            reagents in API synthesis, applying Process Analytical Technology
            (PAT) tools, and employing advanced processing methods.
          </li>
          <li>
            We implement comprehensive strategies for managing the entire
            product lifecycle, including backward integration for key products.
          </li>
          <li>
            Our process optimization is guided by the Quality by Design concept
            and Six Sigma methodologies to enhance efficiency and process
            capability.
          </li>
          <li>
            We are developing innovative compact dosage forms that offer better
            stability and reduced pharmacokinetic variability.
          </li>
          <li>
            We are committed to excellence and innovation in the pharmaceutical
            industry.
          </li>
          <li>
            We continuously advance our product portfolio to address the
            evolving needs of patients worldwide.
          </li>
        </ul>

        <h3>Capabilities</h3>
        <ul>
          <li>
            We have expertise in finished dosage development, biological
            support, chemistry, and the creation of new drugs.
          </li>
          <li>
            We can develop products in a variety of dosage forms, including oral
            tablets, liquids, ointments, gels, sprays, and injectable.
          </li>
          <li>
            We specialize in formulating non-infringing and complex specialty
            products.
          </li>
          <li>
            Our extensive product portfolio covers multiple therapeutic areas to
            address diverse patient needs.
          </li>
          <li>
            We are equipped to conduct clinical trials for both complex generics
            and specialty products.
          </li>
        </ul>

        <h3>Ambitions</h3>
        <ul>
          <li>
            We are making strategic investments to grow our specialty product
            pipeline.
          </li>
          <li>We are concentrating on the development of complex generics.</li>
          <li>
            We are placing greater emphasis on strengthening the R&D pipeline
            for emerging markets and India.
          </li>
          <li>
            We are intensifying efforts to develop strategically significant
            APIs.
          </li>
        </ul>

        <h4>GRI 3-3, GRI 418-1</h4>
      </div>

      {/* page 39 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h3 style={{ color: "orange" }}>Foundational Pillars</h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",

            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", padding: "10px" }}>
            <img
              src={imperitive}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <h2>Imperative</h2>
            <ul>
              <li>
                Adopting advanced technology and innovation to enhance
                sustainable manufacturing practices.
              </li>
              <li>
                Striving for cost efficiency throughout all business operations.
              </li>
            </ul>
          </div>
          <div style={{ border: "1px solid black", padding: "10px" }}>
            <img
              src={approach}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <h2>Approach</h2>
            <ul>
              <li>
                Applying lean manufacturing principles and process optimization
                to maximize operational efficiency.
              </li>
              <li>
                Concentrating on creating specialized products designed for
                various markets.
              </li>
            </ul>
          </div>
          <div style={{ border: "1px solid black", padding: "10px" }}>
            <img
              src={strategy}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <h2>Strategic Enablers</h2>
            <ul>
              <li>
                Ongoing integration of cutting-edge technologies, including
                Robotics Process Automation (RPA) and AI/ML.
              </li>
              <li>
                Specialized teams dedicated to advancing R&D for both specialty
                and generic products.
              </li>
              <li>
                Frequent updates provided to the Board of Directors and Managing
                Director on progress.
              </li>
              <li>
                Organizational units dedicated to enhancing cyber security
                measures and data management practices.
              </li>
            </ul>
          </div>
          <div style={{ border: "1px solid black", padding: "10px" }}>
            <img
              src={aspirations}
              alt="page-36-img"
              style={{ width: "100px", height: "auto" }}
            />
            <li>
              Drive the development of innovative treatments and advance global
              healthcare by increasing investments in R&D and innovation.
            </li>
          </div>
        </div>
        <strong>
          <p style={{ marginTop: "10px", marginBottom: "0px" }}>
            QUALITY: XX International Accreditations
          </p>
          <p style={{ margin: "0px" }}>
            R&D: XXX sq. Research & Development Centre
          </p>
          <p style={{ margin: "0px" }}>SCIENTISTS: XX</p>
          <p style={{ margin: "0px" }}>PhD: XX</p>
          <p style={{ margin: "0px" }}>PATENTS: XX</p>
        </strong>

        <h4>GRI 3-3, 41GRI 418-1</h4>
      </div>

      {/* page 40 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ fontWeight: "600" }}>Product Quality & Safety</p>

        <p>
          At Our Organization, product quality and safety are top priorities. We
          have established thorough quality management systems in line with ISO
          9001 and cGMP standards to ensure that all products adhere to
          stringent safety and efficacy requirements throughout their entire
          lifecycle
        </p>

        <p style={{ fontWeight: "600" }}>Product End-of-Life</p>

        <p>
          From the sourcing of raw materials to the final product, every stage
          of our manufacturing process adheres to global regulatory standards.
          At Our Organization our products (APIs) are integral to the production
          of medicines. We conduct thorough end-of-life assessments of our
          products and provide clear communication and support to minimize any
          impact on our customers. This approach reduces risks and ensures the
          highest levels of customer safety, reflecting in our organization'
          commitment to delivering safe and effective pharmaceutical products
        </p>

        <p>
          We uphold the highest standards in marketing and product labeling to
          ensure transparency and foster trust with our customers. We provide
          detailed guidance to sales and marketing teams on interacting with
          competitors to prevent any perception of collusion. Our promotional
          materials and product labels are accurate, clear, and compliant with
          regulatory requirements. Our ethical marketing practices include
          offering comprehensive information about product benefits, risks, and
          proper usage, enabling healthcare providers and customers to make
          informed decisions. This dedication to ethical communication
          underscores organization commitment to responsible business practices.
        </p>

        <h4>GRI 3-3, 41GRI 418-1</h4>
      </div>

      {/* page 41 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>Environmental Stewardship</h2>
        <h3>A Commitment to Safeguard Our Planet.</h3>
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ border: "1px solid black" }}>
            <ul>
              <li>
                Protecting the environment is central to our efforts,
                representing a key strategic priority and a fundamental aspect
                of our sustainable development goals. Aware of our
                responsibility to minimize any harmful impacts on the
                environment, we implement targeted actions to lessen our
                ecological footprint.
              </li>
            </ul>
          </div>
          <div style={{ border: "1px solid black" }}>
            <ul>
              <li>
                {" "}
                As a responsible corporate entity, we acknowledge our role in
                supporting the United Nations' Sustainable Development Goals (UN
                SDGs). We prioritize the efficient use of resources and use our
                influence within the business value chain to help create a more
                sustainable future.
              </li>
            </ul>
          </div>
        </div>

        <img style={{ width: "100%" }} src={envDay} alt="" />
      </div>
      {/* page 42 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>ENVIRONMENT Performance 2024</h2>
        <p>
          We have set ambitious goals to make a positive environmental impact
          and have implemented responsible actions and thoughtful practices to
          achieve these goals effectively.
        </p>

        <div>
          <h3 style={{ textAlign: "center", color: "green" }}>
            Energy Management
          </h3>
          <table style={{ width: "100%", border: "1px solid black" }}>
            <thead>
              <tr>
                <th>Highlights</th>
                <th>Achievement</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Highlight" && e?.type === "energy"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) =>
                          e?.title === "Achievements" && e?.type === "energy"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Target" && e?.type === "energy"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3 style={{ textAlign: "center", color: "green" }}>
            Waste Management
          </h3>
          <table style={{ width: "100%", border: "1px solid black" }}>
            <thead>
              <tr>
                <th>Highlights</th>
                <th>Achievement</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Highlight" && e?.type === "waste"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) =>
                          e?.title === "Achievements" && e?.type === "waste"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Target" && e?.type === "waste"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3 style={{ textAlign: "center", color: "green" }}>
            Water Management
          </h3>
          <table style={{ width: "100%", border: "1px solid black" }}>
            <thead>
              <tr>
                <th>Highlights</th>
                <th>Achievement</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Highlight" && e?.type === "water"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) =>
                          e?.title === "Achievements" && e?.type === "water"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Target" && e?.type === "water"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3 style={{ textAlign: "center", color: "green" }}>
            Carbon Emissions
          </h3>
          <table style={{ width: "100%", border: "1px solid black" }}>
            <thead>
              <tr>
                <th>Highlights</th>
                <th>Achievement</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Highlight" && e?.type === "aqi"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Achievements" && e?.type === "aqi"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Target" && e?.type === "aqi"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h4>GRI 302-4</h4>
      </div>
      {/* page 43 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h3>Environmental Governance Mechanism</h3>

        <ul>
          <li>
            Our Environmental Governance Mechanism encompasses a robust
            Environment, Health, and Safety (EHS) policy, alongside a
            well-defined EHS Management System and Energy Management System.
            These frameworks are crucial for supporting our efforts and ensuring
            the effective achievement of our environmentally responsible
            objectives, particularly in managing greenhouse gas emissions, water
            consumption, and waste generation.
          </li>
          <li>
            Our EHS governance processes play a critical role in evaluating the
            effectiveness of our environmental initiatives and maintaining
            oversight of EHS performance relative to our targets. We conduct
            regular internal and external audits, as well as inspections, to
            track and assess compliance with relevant EHS regulations and
            standards.
          </li>
          <li>
            Additionally, we have implemented a comprehensive emergency response
            plan across all our sites, ensuring rapid and efficient resolution
            of any EHS incidents or accidents. Our EHS Management System aligns
            with ISO 14001:2015 standards.
          </li>
          <li>
            Recognizing the critical role of our workforce in achieving our
            environmental goals, we have established various engagement
            platforms to raise awareness and promote environmentally friendly
            practices among employees.
          </li>
          <li>
            These forums empower our workforce to identify and address potential
            hazards within our operations, evaluate and mitigate environmental
            impacts, and advocate for a safe and healthy work environment. We
            also provide targeted EHS training to our employees, encouraging
            responsible conduct and environmentally conscious behavior.
          </li>
        </ul>

        <h4>GRI 2-27</h4>
      </div>
      {/* page 44 */}
      <div style={{ pageBreakBefore: "always" }}>
        <img
          style={{
            width: "50%",
            height: "200px",
            textAlign: "center",
            margin: " auto",
            display: "block",
          }}
          src={pyramid}
          alt=""
        />
        <h2 style={{ textAlign: "center", color: "green" }}>
          Our Climate-smart Vision
        </h2>
      </div>

      {/* page 45 */}
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid black",
          }}
        >
          <h4>Free text</h4>
          <img
            src={climateSmart}
            alt=""
            style={{ width: "50%", height: "300px" }}
          />
        </div>
        <h4>GRI 3-3 </h4>
      </div>

      {/* page 46 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>Energy Managements</h2>
        <p style={{ margin: "0px" }}>Energy Efficiency</p>
        <p>
          Our energy demand and fossil fuel-based energy consumption in our
          operations are inextricably linked to greenhouse gas emissions and
          influence the achievement of our decarburization targets. To reduce
          our overall energy demand and leverage clean energy for our
          operations, we have adopted a three-pronged approach of monitor,
          minimize, and decarbonize
        </p>

        <img src={envEff} style={{ width: "100%", height: "200px" }} alt="" />

        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <table
            style={{
              borderCollapse: "collapse",
              width: "80%",
              textAlign: "center",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Energy Consumption (GJ)
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  FY-2023
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  FY2024
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Renewable Energy Consumption
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {(
                    Number(assignValue("Biomass", "C1", "energy")?.prev || 0) +
                    Number(
                      assignValue(
                        "Solar rooftop (including solar street light)",
                        "C1",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(
                      assignValue("Windmills", "C1", "energy")?.prev || 0
                    ) +
                    Number(
                      assignValue(
                        "Renewable sources - power purchased",
                        "C1",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(
                      assignValue("Steam purchased", "C1", "energy")?.prev || 0
                    ) +
                    Number(assignValue("Others", "C1", "energy")?.prev || 0)
                  ).toFixed(2)}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {(
                    Number(assignValue("Biomass", "C1", "energy")?.curr || 0) +
                    Number(
                      assignValue(
                        "Solar rooftop (including solar street light)",
                        "C1",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(
                      assignValue("Windmills", "C1", "energy")?.curr || 0
                    ) +
                    Number(
                      assignValue(
                        "Renewable sources - power purchased",
                        "C1",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(
                      assignValue("Steam purchased", "C1", "energy")?.curr || 0
                    ) +
                    Number(assignValue("Others", "C1", "energy")?.curr || 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Non-Renewable Energy Consumption
                </td>
                <td
                  style={{
                    border: "1px solid black",

                    textAlign: "center",
                  }}
                >
                  {(
                    Number(
                      assignValue("High speed diesel (HSD)", "C2", "energy")
                        ?.prev || 0
                    ) +
                    Number(
                      assignValue(
                        "Low Sulphur Heavy Stock ( LSHS)",
                        "C2",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(assignValue("Petrol", "C2", "energy")?.prev || 0) +
                    Number(
                      assignValue(
                        "Compressed natural gas (CNG)",
                        "C2",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(
                      assignValue(
                        "Liquefied petroleum gas (LPG)",
                        "C2",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(
                      assignValue("Light diesel oil (LDO)", "C2", "energy")
                        ?.prev || 0
                    ) +
                    Number(assignValue("Coal", "C2", "energy")?.prev || 0) +
                    Number(
                      assignValue("Grid electricity", "C2", "energy")?.prev || 0
                    ) +
                    Number(assignValue("Others", "C2", "energy")?.prev || 0)
                  ).toFixed(2)}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {(
                    Number(
                      assignValue("High speed diesel (HSD)", "C2", "energy")
                        ?.curr || 0
                    ) +
                    Number(
                      assignValue(
                        "Low Sulphur Heavy Stock ( LSHS)",
                        "C2",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(assignValue("Petrol", "C2", "energy")?.curr || 0) +
                    Number(
                      assignValue(
                        "Compressed natural gas (CNG)",
                        "C2",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(
                      assignValue(
                        "Liquefied petroleum gas (LPG)",
                        "C2",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(
                      assignValue("Light diesel oil (LDO)", "C2", "energy")
                        ?.curr || 0
                    ) +
                    Number(assignValue("Coal", "C2", "energy")?.curr || 0) +
                    Number(
                      assignValue("Grid electricity", "C2", "energy")?.curr || 0
                    ) +
                    Number(assignValue("Others", "C2", "energy")?.curr || 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",

                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Total Energy Consumption
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {(
                    Number(assignValue("Biomass", "C1", "energy")?.prev || 0) +
                    Number(
                      assignValue(
                        "Solar rooftop (including solar street light)",
                        "C1",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(
                      assignValue("Windmills", "C1", "energy")?.prev || 0
                    ) +
                    Number(
                      assignValue(
                        "Renewable sources - power purchased",
                        "C1",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(
                      assignValue("Steam purchased", "C1", "energy")?.prev || 0
                    ) +
                    Number(assignValue("Others", "C1", "energy")?.prev || 0) +
                    Number(
                      assignValue("High speed diesel (HSD)", "C2", "energy")
                        ?.prev || 0
                    ) +
                    Number(
                      assignValue(
                        "Low Sulphur Heavy Stock ( LSHS)",
                        "C2",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(assignValue("Petrol", "C2", "energy")?.prev || 0) +
                    Number(
                      assignValue(
                        "Compressed natural gas (CNG)",
                        "C2",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(
                      assignValue(
                        "Liquefied petroleum gas (LPG)",
                        "C2",
                        "energy"
                      )?.prev || 0
                    ) +
                    Number(
                      assignValue("Light diesel oil (LDO)", "C2", "energy")
                        ?.prev || 0
                    ) +
                    Number(assignValue("Coal", "C2", "energy")?.prev || 0) +
                    Number(
                      assignValue("Grid electricity", "C2", "energy")?.prev || 0
                    ) +
                    Number(assignValue("Others", "C2", "energy")?.prev || 0)
                  ).toFixed(2)}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {(
                    Number(assignValue("Biomass", "C1", "energy")?.curr || 0) +
                    Number(
                      assignValue(
                        "Solar rooftop (including solar street light)",
                        "C1",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(
                      assignValue("Windmills", "C1", "energy")?.curr || 0
                    ) +
                    Number(
                      assignValue(
                        "Renewable sources - power purchased",
                        "C1",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(
                      assignValue("Steam purchased", "C1", "energy")?.curr || 0
                    ) +
                    Number(assignValue("Others", "C1", "energy")?.curr || 0) +
                    Number(
                      assignValue("High speed diesel (HSD)", "C2", "energy")
                        ?.curr || 0
                    ) +
                    Number(
                      assignValue(
                        "Low Sulphur Heavy Stock ( LSHS)",
                        "C2",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(assignValue("Petrol", "C2", "energy")?.curr || 0) +
                    Number(
                      assignValue(
                        "Compressed natural gas (CNG)",
                        "C2",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(
                      assignValue(
                        "Liquefied petroleum gas (LPG)",
                        "C2",
                        "energy"
                      )?.curr || 0
                    ) +
                    Number(
                      assignValue("Light diesel oil (LDO)", "C2", "energy")
                        ?.curr || 0
                    ) +
                    Number(assignValue("Coal", "C2", "energy")?.curr || 0) +
                    Number(
                      assignValue("Grid electricity", "C2", "energy")?.curr || 0
                    ) +
                    Number(assignValue("Others", "C2", "energy")?.curr || 0)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",

                    textAlign: "center",
                  }}
                >
                  Energy intensity (GJ/revenue in ₹ Million)
                </td>
                {energyConsumptionRevenue.map((e) => (
                  <>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {Number(e?.prev).toFixed(2)}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      {Number(e?.curr).toFixed(2)}
                    </td>
                  </>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        <h2>
          Graph of Energy managements from Tools with PII Charts Including Sub
          category
        </h2>

        <h4>GRI 302-1, GRI 302-3 </h4>
      </div>

      {/* page 47 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>Minimize and Decarbonize</h2>
        <p>
          In FY24, we have introduced targeted energy efficiency initiatives. A
          few of these are listed below:
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid black",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ border: "1px solid black", width: "100%" }}>
                <p>1 .Free Text.</p>
              </div>
              <div style={{ border: "1px solid black", width: "100%" }}>
                <p>2. Free Text</p>
              </div>
            </div>

            <div style={{ height: "100px" }}>3. Free text</div>
          </div>
          <img src={solar} alt="" />
        </div>
      </div>

      {/* page 48 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>Water Managements</h2>

        <h3>
          Our water management strategy follows the principles of the 4Rs:
          'Reduce, Reuse, Recycle, and Recharge'.
        </h3>

        <img src={reuse} alt="" style={{ width: "100%", height: "400px" }} />

        <div style={{ border: "1px solid black" }}>
          <p style={{ textAlign: "center", margin: "0px" }}>Free Texts</p>
        </div>

        <h4>GRI 303-1, 303-2, GRI 303-3 </h4>
      </div>

      {/* page 49 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>Water withdrawal by sources:</h2>
        <table
          border="1"
          style={{
            borderCollapse: "collapse",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#f8e4a0" }}>
              <th></th>
              <th>Categories</th>
              <th>{`FY${year - 1}`}</th>
              <th>{`FY${year}`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>i</td>
              <td>Surface water</td>
              <td>{assignValue("Surface water", "A", "water")?.prev}</td>
              <td>{assignValue("Surface water", "A", "water")?.curr}</td>
            </tr>
            <tr>
              <td>ii</td>
              <td>Groundwater</td>
              <td>{assignValue("Groundwater", "A", "water")?.prev}</td>
              <td>{assignValue("Groundwater", "A", "water")?.curr}</td>
            </tr>
            <tr>
              <td>iii</td>
              <td>Third party water</td>
              <td>{assignValue("Third party water", "A", "water")?.prev}</td>
              <td>{assignValue("Third party water", "A", "water")?.curr}</td>
            </tr>
            <tr>
              <td>iv</td>
              <td>Produced water</td>
              <td>{assignValue("Produced water", "A", "water")?.prev}</td>
              <td>{assignValue("Produced water", "A", "water")?.curr}</td>
            </tr>
            <tr>
              <td>v</td>
              <td>Seawater / desalinated water</td>
              <td>
                {
                  assignValue("Seawater / desalinated water", "A", "water")
                    ?.prev
                }
              </td>
              <td>
                {
                  assignValue("Seawater / desalinated water", "A", "water")
                    ?.curr
                }
              </td>
            </tr>
            <tr>
              <td>vi</td>
              <td>Others</td>
              <td>{assignValue("Others", "A", "water")?.prev}</td>
              <td>{assignValue("Others", "A", "water")?.curr}</td>
            </tr>
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total
              </td>
              <td>
                {(
                  Number(
                    assignValue("Surface water", "A", "water")?.prev || 0
                  ) +
                  Number(assignValue("Groundwater", "A", "water")?.prev || 0) +
                  Number(
                    assignValue("Third party water", "A", "water")?.prev || 0
                  ) +
                  Number(
                    assignValue("Produced water", "A", "water")?.prev || 0
                  ) +
                  Number(
                    assignValue("Seawater / desalinated water", "A", "water")
                      ?.prev || 0
                  ) +
                  Number(assignValue("Others", "A", "water")?.prev || 0)
                ).toFixed(2)}
              </td>
              <td>
                {(
                  Number(
                    assignValue("Surface water", "A", "water")?.curr || 0
                  ) +
                  Number(assignValue("Groundwater", "A", "water")?.curr || 0) +
                  Number(
                    assignValue("Third party water", "A", "water")?.curr || 0
                  ) +
                  Number(
                    assignValue("Produced water", "A", "water")?.curr || 0
                  ) +
                  Number(
                    assignValue("Seawater / desalinated water", "A", "water")
                      ?.curr || 0
                  ) +
                  Number(assignValue("Others", "A", "water")?.curr || 0)
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ display: "flex" }}>
          <p>
            Should be taken from tool with PII charts & Pic to be adjust
            somewhere in page:
          </p>

          <img
            src={handWash}
            alt=""
            style={{ width: "100%", height: "300px" }}
          />
        </div>
        <h4>GRI 303-4, GRI 303-5</h4>
      </div>

      {/* page 50 */}
      <div
        style={{
          width: "100%",
          pageBreakBefore: "always",
        }}
      >
        <table border="1" width={"100%"}>
          <thead>
            <tr>
              <th colspan="2">Categories</th>
              <th>{`FY${year - 1}`}</th>
              <th>{`FY${year}`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">Water Consumption</td>
              <td>
                {(
                  Number(
                    assignValue(
                      "Total Fresh Water Consumption : Plant",
                      "C",
                      "water"
                    )?.prev || 0
                  ) +
                  Number(
                    assignValue(
                      "Total Fresh Water Consumption : Colony",
                      "C",
                      "water"
                    )?.prev || 0
                  ) +
                  Number(
                    assignValue("Recycled Water consumption:", "C", "water")
                      ?.prev || 0
                  ) +
                  Number(
                    assignValue(
                      "Recycled Water consumption: Process",
                      "C",
                      "water"
                    )?.prev || 0
                  ) +
                  Number(
                    assignValue(
                      "Recycled Water consumption: Utility",
                      "C",
                      "water"
                    )?.prev || 0
                  ) +
                  Number(
                    assignValue(
                      "Quantum of Effluent Generated (STP)",
                      "C",
                      "water"
                    )?.prev || 0
                  ) +
                  Number(
                    assignValue(
                      "Quantum of Effluent Recycled (STP)",
                      "C",
                      "water"
                    )?.prev || 0
                  ) +
                  Number(
                    assignValue(
                      "Quantum of Effluent Generated (ETP)",
                      "C",
                      "water"
                    )?.prev || 0
                  ) +
                  Number(
                    assignValue(
                      "Quantum of Effluent Recycled (ETP)",
                      "C",
                      "water"
                    )?.prev || 0
                  ) +
                  Number(assignValue("Others", "C", "water")?.prev || 0)
                ).toFixed(2)}
              </td>
              <td>
                {(
                  Number(
                    assignValue(
                      "Total Fresh Water Consumption : Plant",
                      "C",
                      "water"
                    )?.curr || 0
                  ) +
                  Number(
                    assignValue(
                      "Total Fresh Water Consumption : Colony",
                      "C",
                      "water"
                    )?.curr || 0
                  ) +
                  Number(
                    assignValue("Recycled Water consumption:", "C", "water")
                      ?.curr || 0
                  ) +
                  Number(
                    assignValue(
                      "Recycled Water consumption: Process",
                      "C",
                      "water"
                    )?.curr || 0
                  ) +
                  Number(
                    assignValue(
                      "Recycled Water consumption: Utility",
                      "C",
                      "water"
                    )?.curr || 0
                  ) +
                  Number(
                    assignValue(
                      "Quantum of Effluent Generated (STP)",
                      "C",
                      "water"
                    )?.curr || 0
                  ) +
                  Number(
                    assignValue(
                      "Quantum of Effluent Recycled (STP)",
                      "C",
                      "water"
                    )?.curr || 0
                  ) +
                  Number(
                    assignValue(
                      "Quantum of Effluent Generated (ETP)",
                      "C",
                      "water"
                    )?.curr || 0
                  ) +
                  Number(
                    assignValue(
                      "Quantum of Effluent Recycled (ETP)",
                      "C",
                      "water"
                    )?.curr || 0
                  ) +
                  Number(assignValue("Others", "C", "water")?.curr || 0)
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="2">Water withdrawal by water stress area</td>
              <td>
                {(
                  Number(
                    assignValue("Surface Water", "H", "water")?.prev || 0
                  ) +
                  Number(assignValue("Ground Water", "H", "water")?.prev || 0) +
                  Number(assignValue("Sea Water", "H", "water")?.prev || 0) +
                  Number(
                    assignValue("Produced Water", "H", "water")?.prev || 0
                  ) +
                  Number(
                    assignValue("Third-party Water", "H", "water")?.prev || 0
                  ) +
                  Number(assignValue("Others", "H", "water")?.prev || 0)
                ).toFixed(2)}
              </td>
              <td>
                {(
                  Number(
                    assignValue("Surface Water", "H", "water")?.curr || 0
                  ) +
                  Number(assignValue("Ground Water", "H", "water")?.curr || 0) +
                  Number(assignValue("Sea Water", "H", "water")?.curr || 0) +
                  Number(
                    assignValue("Produced Water", "H", "water")?.curr || 0
                  ) +
                  Number(
                    assignValue("Third-party Water", "H", "water")?.curr || 0
                  ) +
                  Number(assignValue("Others", "H", "water")?.curr || 0)
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="2">Water Intensity (KL/revenue in I Mn)</td>
              {waterRevenue.map((e) => (
                <>
                  <td>{Number(e?.prev).toFixed(2)}</td>
                  <td>{Number(e?.curr).toFixed(2)}</td>
                </>
              ))}
            </tr>
          </tbody>
        </table>

        <h4>GRI 303-4, GRI 303-5 </h4>
      </div>

      {/* page 51 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>Waste Managements:</h2>

        <p>
          Waste management in an organization entails a comprehensive approach
          to managing waste efficiently and minimizing its environmental impact.
          This approach includes preventing waste through process optimization
          and reducing waste by improving operational efficiencies. The
          organization also focuses on reusing materials wherever possible and
          recycling items to transform them into new products. Additionally,
          waste recovery methods, such as waste-to-energy technologies and
          composting, are employed to extract value from waste. Proper disposal
          methods are used for non-recyclable and hazardous materials, ensuring
          compliance with regulations.
        </p>

        <p>
          To support these efforts, effective waste management practices include
          waste segregation at the source, regular waste audits, and
          comprehensive employee training. Collaboration with suppliers helps to
          reduce packaging waste and implement sustainable practices. Clear
          policies and goals are established to guide waste management efforts,
          while performance metrics and reporting maintain transparency and
          accountability. Continuous improvement practices are essential for
          refining and enhancing waste management strategies over time.
        </p>

        <img src={waste} alt="" style={{ width: "100%", height: "300px" }} />

        <h4>GRI 306-1, 306-2, GRI 306-3</h4>
      </div>

      {/* page 52 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2 style={{ color: "green" }}>Type of Waste Generated (MT)</h2>

        <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#f8e4a0" }}>
              <th>Categories</th>
              <th></th>
              <th>{`FY${year - 1}`}</th>
              <th>{`FY${year}`}</th>
            </tr>
          </thead>
          <tbody>
            {typesOfWasteGenerated?.map((e, index) => (
              <tr key={`types_of_waste_generated${e}`}>
                <td>{numToRoman(index + 1)}</td>
                <td>{e.name}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total
              </td>
              <td>
                {typesOfWasteGenerated.reduce(
                  (acc, curr) => acc + curr.prev,
                  0
                )}
              </td>
              <td>
                {typesOfWasteGenerated.reduce(
                  (acc, curr) => acc + curr.curr,
                  0
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <h2
          style={{
            color: "green",
          }}
        >
          Waste Diverted from Disposal (MT)
        </h2>

        <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#f8e4a0" }}>
              <th>2</th>
              <th>Categories</th>
              <th>{`FY${year - 1}`}</th>
              <th>{`FY${year}`}</th>
            </tr>
          </thead>
          <tbody>
            {/* Hazardous waste */}
            <tr>
              <td>i</td>
              <td>
                <strong>Hazardous waste</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {hazardous?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("-")[1]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (i)
              </td>
              <td>{hazardous.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{hazardous.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>

            {/* Non-hazardous */}
            <tr>
              <td>ii</td>
              <td>
                <strong>Non-hazardous</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {nonHazardous?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("-")[2]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (ii)
              </td>
              <td>{nonHazardous.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{nonHazardous.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>

            {/* E-waste */}
            <tr>
              <td>iii</td>
              <td>
                <strong>E-waste</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {eWaste?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("-")[2]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (iii)
              </td>
              <td>{eWaste.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{eWaste.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>
          </tbody>
        </table>

        <h2 style={{ color: "green" }}>Waste Directed to Disposal (MT)</h2>

        <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#f8e4a0" }}>
              <th>3</th>
              <th>Categories</th>
              <th>{`FY${year - 1}`}</th>
              <th>{`FY${year}`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>i</td>
              <td>
                <strong>Hazardous waste</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {hazWaste?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("- ")[1]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (i)
              </td>
              <td>{hazWaste.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{hazWaste.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>

            {/* Non-Hazardous */}
            <tr>
              <td>ii</td>
              <td>
                <strong>Non-Hazardous</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {nonHazWaste?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("-")[2]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (ii)
              </td>
              <td>{nonHazWaste.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{nonHazWaste.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>
          </tbody>
        </table>

        <p>
          To reduce the disposal of single-use plastics, we have partnered with
          a licensed third-party waste handler to collect and manage plastic
          waste at its end of life. This collaboration ensures adherence to
          pollution control board guidelines and complies with extended producer
          responsibility (EPR) regulations.
        </p>
      </div>

      {/* page 53 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h2>Emissions Management</h2>
        <h3 style={{ color: "green" }}>Scope 1 GHG Emissions</h3>

        <p>
          We systematically monitor and report on the emissions from direct fuel
          sources, such as HSD, furnace oil, petrol, LDO, and coal, used in our
          operations. Over the past two years, our Scope 1 emissions have been
          trending downward, showing reductions in both the total amount of
          emissions and the emissions intensity per unit of output.
        </p>

        <h4>Scope 1 Emissions (tCO2)</h4>

        <div>
          <img
            style={{ width: "50%", display: "block", margin: "auto" }}
            src={scope1}
            alt=""
          />
          <h4>GRI 302-4 and 305-5, GRI 305-1 and 305-4 </h4>
        </div>

        <div style={{ pageBreakBefore: "always" }}>
          <h3 style={{ color: "green" }}>Scope 2 GHG Emissions</h3>

          <h2 style={{ color: "green" }}>Free Text</h2>

          <h4>GRI 305-2 and 305-4, GRI 305-3, </h4>
        </div>
      </div>

      {/* page 55 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h3 style={{ color: "green" }}>Other Air Emissions</h3>
        <p>
          We track emissions of air pollutants, including sulfur oxides (SOx),
          nitrogen oxides (NOx), and particulate matter from our operations. We
          consistently keep these emissions below the limits set by central and
          state pollution control boards. Additionally, we are actively pursuing
          measures to further reduce the intensity of air pollutant emissions
          and ensure full compliance with applicable environmental standards and
          regulations.
        </p>

        <h4>GRI 305-6, GRI 305-7, </h4>
      </div>

      {/* page 56 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h3 style={{ color: "green" }}>Biodiversity</h3>

        <p>
          Our organization acknowledges the critical connection between
          biodiversity and our business sustainability. Biodiversity and
          ecosystem services, including freshwater supply, air purification,
          noise reduction, and flood control, are directly and indirectly linked
          to our operations. The diversity of plant and animal life is essential
          for maintaining nature's balance and serves as a key indicator of
          ecosystem health. To minimize any negative impact on biodiversity and
          ecosystem services, we have developed a Biodiversity Policy that
          outlines our commitment to effective biodiversity management. This
          policy is publicly accessible on our website.
        </p>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            <p>Location & Boundary:</p>

            <p style={{ color: "green" }}>IUCN Red List (Nos):</p>
          </div>

          <table
            border="1"
            cellPadding="2"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <tbody>
              {biodiversity?.map((e, index) => (
                <tr key={`biodiversity${e}`}>
                  <td>{numToRoman(index + 1)}</td>
                  <td>{e.name}</td>
                  <td>{e.curr}</td>
                  {/* <td>{e.prev}</td> */}
                </tr>
              ))}
              {/* <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  I
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Critically endangered{" "}
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}></td>
              </tr>

              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  II
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Endangered
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}></td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  III
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Vulnerable
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}></td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  IV
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Near threatened
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}></td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  V
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}>
                  Least concern
                </td>
                <td style={{ border: "1px solid black", padding: "10px" }}></td>
              </tr> */}
            </tbody>
          </table>
        </div>

        <strong>
          <p style={{ color: "green" }}>Extent and Approach</p>
        </strong>

        <ul>
          <li>
            At [Your Organization's Name], biodiversity is a cornerstone of our
            sustainability strategy, recognizing its vital role in maintaining
            ecosystem stability and supporting our operations. Our efforts are
            focused on preserving and enhancing biodiversity across all key
            locations, with particular attention to sites where our activities
            intersect with sensitive ecosystems.
          </li>
          <li>
            We employ a rigorous, data-driven methodology to manage
            biodiversity, guided by the Taskforce on Nature-related Financial
            Disclosures (TNFD) Framework. This approach includes comprehensive
            assessments conducted by third-party experts, documenting local
            species, habitats, and ecosystem services. These assessments are
            crucial for identifying potential biodiversity risks and formulating
            effective mitigation strategies.
          </li>
          <li>
            Our commitment extends to ongoing monitoring and adaptive
            management, ensuring that we continuously improve our practices in
            response to new insights and environmental changes. Through
            collaboration with local communities and stakeholders, we align our
            biodiversity initiatives with broader conservation goals,
            contributing to the long-term health of the ecosystems that sustain
            both our business and the environment.
          </li>
        </ul>

        <strong>
          <p style={{ color: "green" }}>
            Phases of Biodiversity Risk Assessment
          </p>
        </strong>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",

            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
        </div>

        <strong>
          <p style={{ color: "green" }}>
            Biodiversity-Related Risks and Opportunities
          </p>
        </strong>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",

            width: "100%",
          }}
        >
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
          <div style={{ border: "1px solid black", padding: "10px" }}></div>
        </div>
      </div>

      {/* page 57 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h3 style={{ color: "green" }}>Climate Governance</h3>

        <p>
          We manage risks using a cross-functional approach that ensures
          cohesive response and management of risk incidents. This process is
          supported by a multi-tiered governance framework.
        </p>

        <img
          src={page57}
          alt="page-57-img"
          style={{ width: "100%", height: "400px" }}
        />

        <h3 style={{ color: "green" }}>Roles and Responsibilities</h3>

        <p>
          Our climate governance framework is designed to ensure effective
          management of climate-related issues through a clearly defined
          structure of roles and responsibilities. The Board of Directors
          provides strategic oversight, approving climate policies and setting
          organizational objectives. They monitor progress and ensure that our
          climate strategies align with both business goals and regulatory
          requirements.
        </p>
      </div>

      {/* page 58 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>
          The Climate Governance Committee, consisting of senior executives and
          experts, is responsible for developing and implementing climate
          strategies. This committee oversees climate risk assessments and
          integrates climate considerations into our operations, ensuring
          compliance with regulations and standards.
        </p>
        <p>
          The Sustainability Team manages the execution of climate initiatives
          on a day-to-day basis. They handle performance monitoring, carbon
          reduction projects, and stakeholder coordination, providing the
          technical expertise necessary for effective climate strategy
          implementation.
        </p>
        <p>
          All employees support our climate objectives by adhering to
          established policies, engaging in training programs, and participating
          in sustainability initiatives. Their collective efforts are crucial
          for fostering a climate-conscious culture and achieving our climate
          goals, ensuring that climate governance is integrated throughout our
          organization.
        </p>

        <p style={{ fontWeight: "600 " }}>Biodiversity Management Plan</p>

        <p>
          Our organization is committed to the preservation and enhancement of
          biodiversity as an integral part of our sustainability efforts. We
          recognize the crucial role that diverse ecosystems play in maintaining
          ecological balance and supporting long-term environmental health. As
          part of our biodiversity management plan, we strive to minimize our
          environmental footprint through practices that protect and restore
          natural habitats, reduce pollution, and mitigate the impact of our
          operations on local flora and fauna. We regularly assess our
          activities and their effects on biodiversity, engage with stakeholders
          to promote awareness, and implement measures to conserve and promote
          diverse biological resources. By integrating biodiversity
          considerations into our operational and strategic planning, we aim to
          contribute positively to the environment and support sustainable
          development goals.
        </p>

        <h4>GRI 201-2</h4>
      </div>

      {/* page 59 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h3>Workforce Resilience and Well-being</h3>

        <h2 style={{ color: "#7CB9E8", fontWeight: "400", margin: "0px" }}>
          Empowering Talent
        </h2>

        <p>
          Aligned with our vision of 'Making a Global Impact and Enhancing
          Lives,' we are leveraging the strengths of our diverse and dynamic
          workforce. With a dedicated team of over XXXXX professionals, we are a
          unified global brand committed to our mission of fostering a healthier
          future for everyone."
        </p>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            <p>Aligned with UN SDGs</p>
            <img src={page68} alt="page68" />
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
            }}
          >
            <div
              style={{
                border: "1px solid #000",

                borderRadius: "5px",
              }}
            >
              <h3>Goal 1:</h3>
              <p>
                Eliminate all forms of discrimination against individuals of all
                genders, everywhere.
              </p>
            </div>
            <div
              style={{
                border: "1px solid #000",

                borderRadius: "5px",
              }}
            >
              <h3>Goal 2:</h3>
              <p>
                Ensure full and effective participation and equal opportunities
                for leadership for all genders at every level of decision-making
                in political, economic, and public spheres.
              </p>
            </div>
            <div
              style={{
                border: "1px solid #000",

                borderRadius: "5px",
              }}
            >
              <h3>Goal 3:</h3>
              <p>
                Achieve full and productive employment and fair working
                conditions for all individuals, including young people and
                persons with disabilities.
              </p>
            </div>
          </div>
        </div>

        <div style={{ pageBreakBefore: "always" }}>
          <p>
            {" "}
            <strong>Material topics: </strong>
          </p>
          <ul style={{ padding: "20px", listStyleType: "disc" }}>
            <li>Workforce Development</li>
            <li>Diversity, Equity, and Inclusion</li>
            <li>Employee Health and Well-being</li>
          </ul>

          <img src={page59} alt="page 59" width="100%" height="500px" />

          <h4>GRI 3-3</h4>
        </div>
      </div>

      {/* page 60 */}

      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ color: "#7CB9E8" }}>
          <strong>Building and Empowering Teams </strong>
        </p>

        <p>Here’s a revised version that is applicable to any organization:</p>

        <p>
          "Our workforce embodies the organization’s core values of Humility,
          Integrity, Passion, and Innovation, driving both the success of the
          company and positively impacting the lives of those we serve. We
          recognize that our success is a result of the outstanding individuals
          who form the backbone of our organization, and we are committed to
          fostering and empowering talent to reach their full potential.
        </p>

        <p>
          This commitment is realized through our forward-thinking human
          resource management strategy, ongoing investments in its
          implementation, and deliberate efforts to adapt to evolving workforce
          expectations. We strengthen this approach with our unwavering
          commitment to four guiding principles — Connect, Align, Respect, and
          Ensure (C.A.R.E) — which are fundamental to our continued success."
        </p>
        <img
          src={page60}
          alt="page 60"
          width="50%"
          style={{ display: "block", margin: "0 auto" }}
        />
      </div>

      {/* page 61 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>
          "Our organization is recognized as a Great Place to Work, reflecting
          our strong commitment to creating an outstanding workplace
          environment. This recognition validates our efforts to build a culture
          of trust, collaboration, and genuine care, making our organization a
          preferred destination for building meaningful careers.
        </p>

        <p>
          As a knowledge-driven entity, we are dedicated to offering a platform
          for continuous learning and development. We bring together talented
          individuals from various disciplines and diverse backgrounds, which
          aligns with the global nature of our work and the communities we serve
        </p>

        <p>
          Our core values guide our approach to workforce management and
          development, and we are focused on empowering our diverse global team
          of XXXX+ employees."
        </p>

        <p>
          "The table below provides details on our workforce including gender,
          age, and region, for the financial year 20XX (FYXX)." Value should be
          in graph form also
        </p>
        <div style={{ padding: "40px" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr style={{ borderBottom: "2px solid #00BFFF" }}>
                <th>Employee Category</th>
                <th>&lt;30 years</th>
                <th>30-50 years</th>
                <th>&gt;50 years</th>
                <th>Male</th>
                <th>Female</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td style={{ color: "orange" }}>Employees</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Permanent Employees</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-2", "value")?.curr || 0}
                </td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-3", "value")?.curr || 0}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Contractual Employees</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-6", "value")?.curr || 0}
                </td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-7", "value")?.curr || 0}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>
                  <strong>Total Employees</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {(
                    Number(
                      assignValueBRSR("dataId", "0-1-2-2", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-6", "value")?.curr || 0
                    )
                  ).toFixed(2)}
                </td>
                <td>
                  {(
                    Number(
                      assignValueBRSR("dataId", "0-1-2-3", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-7", "value")?.curr || 0
                    )
                  ).toFixed(2)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td style={{ color: "orange" }}>Workers</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Permanent Workers</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-11", "value")?.curr || 0}
                </td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-12", "value")?.curr || 0}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Contractual Labor</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-15", "value")?.curr || 0}
                </td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-16", "value")?.curr || 0}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>
                  <strong>Total Workers</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {(
                    Number(
                      assignValueBRSR("dataId", "0-1-2-11", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-15", "value")?.curr || 0
                    )
                  ).toFixed(2)}
                </td>
                <td>
                  {(
                    Number(
                      assignValueBRSR("dataId", "0-1-2-12", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-16", "value")?.curr || 0
                    )
                  ).toFixed(2)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td style={{ color: "gold", fontWeight: "bold" }}>
                  Differently Abled Employees And Workers
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Permanent Employees</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-20", "value")?.curr || 0}
                </td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-21", "value")?.curr || 0}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Contractual Employees</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-24", "value")?.curr || 0}
                </td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-25", "value")?.curr || 0}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Permanent Workers</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-28", "value")?.curr || 0}
                </td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-29", "value")?.curr || 0}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Contractual Labor</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-32", "value")?.curr || 0}
                </td>
                <td>
                  {assignValueBRSR("dataId", "0-1-2-33", "value")?.curr || 0}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>
                  <strong>Total Differently Abled Employees and Workers</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {(
                    Number(
                      assignValueBRSR("dataId", "0-1-2-20", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-24", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-28", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-32", "value")?.curr || 0
                    )
                  ).toFixed(2)}
                </td>
                <td>
                  {(
                    Number(
                      assignValueBRSR("dataId", "0-1-2-21", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-25", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-29", "value")?.curr || 0
                    ) +
                    Number(
                      assignValueBRSR("dataId", "0-1-2-33", "value")?.curr || 0
                    )
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h4>GRI 2-7, 2-8 and 405-1 </h4>
      </div>

      {/* page 62 */}
      <div style={{ pageBreakBefore: "always" }}>
        <strong>
          <p>Supporting Diversity and Inclusion </p>
        </strong>

        <p>
          "Central to our success is a diverse and inclusive work culture.
          During the reporting year, women represented XX% of our total
          workforce. Additionally, XX% of our employees fell within the 30-50
          age range, reflecting a strong balance of experience and energy across
          our team."
        </p>

        <strong>One Pie Chart Sholud be mentioned of male and female </strong>

        <div>
          <p style={{ margin: "0px" }}>
            Our approach to fostering workforce inclusion is centered around
            three key strategies:
          </p>

          <h3 style={{ color: "#0066b2", fontWeight: "600", margin: "0px" }}>
            1: Equal Opportunities
          </h3>
          <p>
            "As an equal-opportunity employer, we are committed to treating all
            employees with the highest level of dignity, respect, and fairness."
          </p>

          <h3 style={{ color: "#0066b2", fontWeight: "600", margin: "0px" }}>
            2: Non-discrimination
          </h3>
          <p>
            "We uphold a zero-tolerance policy towards all forms of
            discrimination, including those based on gender, sexual orientation,
            race, religion, caste, ethnicity, age, nationality, disability, HIV
            status, family status, and other characteristics."
          </p>

          <ul>
            <li>
              XX% of workers belong to minority groups and/or are classified as
              vulnerable, relative to the total workforce.
            </li>
            <li>
              XX% of top executive positions (excluding boards of directors) are
              held by individuals from minority groups and/or vulnerable
              workers.
            </li>
            <li>
              XX% of the total workforce (including contractual workers) are
              women.
            </li>
            <li>XX% of board positions are occupied by women.</li>
            <li>The average unadjusted gender pay gap is XX%.</li>
          </ul>

          <h3 style={{ color: "#0066b2", fontWeight: "600", margin: "0px" }}>
            3: Meritocracy
          </h3>
          <p>
            "We emphasize merit-based practices in recruitment and selection,
            training and development, performance evaluation, compensation, and
            career advancement."
          </p>
        </div>

        <p style={{ margin: "0px" }}>
          Gender diversity: Percentage of women on the board:
        </p>
        <p style={{ margin: "0px" }}>
          Total number of new Female employee hires during the reporting period:
        </p>
        <p style={{ margin: "0px" }}>
          Ratio of the basic salary and remuneration of women to men for each
          employee category, by significant locations of operation:
        </p>
        <p style={{ margin: "0px" }}>
          Total number of New Female employee turnover during the reporting
          period:
        </p>

        <p style={{ color: "#0066b2" }}>
          Diversity, Equity, and Inclusion (DEI) Approach
        </p>

        <table
          border="1"
          cellPadding="10"
          style={{ borderCollapse: "collapse", width: "100%" }}
        >
          <tbody>
            <tr>
              <td>Identification of Hiring Need</td>
              <td>"Manpower Sourcing"</td>
              <td>"Selecting & Offering"</td>
              <td>"Onboarding & Pre-Integration"</td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>

        <h4>GRI 3-3</h4>
      </div>

      {/* page 63 */}
      <div style={{ pageBreakBefore: "always" }}>
        <strong>
          <p>DEI initiatives of FY202K</p>
        </strong>

        <ul>
          <li>
            Communication and Implementation of DEI Policy: Clearly articulate
            and communicate the organization’s diversity, equity, and inclusion
            (DEI) policy.
          </li>
          <li>
            Targeted Recruitment Efforts: Engage in focused hiring practices,
            including campus recruitment, open hiring events, and targeted
            recruitment for key roles.
          </li>
          <li>
            Leadership Training: Conduct workshops on gender and inclusive
            intelligence for leaders.
          </li>
          <li>
            Unconscious Bias Training: Provide sessions for all managers to
            address and mitigate unconscious bias.
          </li>
          <li>
            Development Programs: Implement targeted development programs for
            underrepresented groups within the organization.
          </li>
          <li>
            Employer Branding: Enhance employer branding through social media
            and other channels to attract diverse talent.
          </li>
        </ul>

        <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr>
              <th>Categories</th>
              <th>
                Employees covered by social security & benefits (Provident Fund,
                Employees State Insurance/Medical Insurance)
              </th>
              <th>
                Employee who have availed the retirement benefits
                (Gratuity/Superannuation)
              </th>
              <th>Employees Laid off</th>
              <th>Employees' welfare activities</th>
              <th>
                Employee earning below living wage across the organization
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Employee Benefits and Welfare</td>
              <td>
                {assignValue("Employees State Insurance", "C1", "governance")
                  ?.curr || "NA"}
                /
                {assignValue("Medical insurance", "C1", "governance")?.curr ||
                  "NA"}
              </td>
              <td>
                {assignValue("Employees State Insurance", "C1", "governance")
                  ?.curr || "NA"}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <strong>
          <p>Leave Policy: Supporting Employee Well-being and Life Events</p>
        </strong>

        <p>
          We recognize that taking time off is crucial for our employees'
          health, recovery, and personal needs. Our leave policy is designed to
          offer the necessary support and flexibility, ensuring employees can
          balance their professional and personal lives effectively.
        </p>

        <ul>
          <li>
            <strong>General Leave:</strong> Employees are entitled to leave
            based on their eligibility and must obtain approval from the
            designated authority. New hires will receive pro-rata leave
            entitlements based on their start date.
          </li>
          <li>
            <strong>Maternity Leave:</strong> Female employees are entitled to
            26 weeks of maternity leave, as per relevant regulations. This leave
            covers up to two children and can be used during pregnancy, at
            delivery, and for recovery. Maternity leave is also available for
            adoption and miscarriage.
          </li>
          <li>
            <strong>Paternity Leave:</strong> To support working fathers, we
            offer paternity leave for up to 6 days for the birth of their first
            two children, allowing them to be present during key family moments.
          </li>
          <li>
            <strong>Bereavement Leave:</strong> We provide 2 days of paid
            bereavement leave per year to support employees dealing with the
            loss of a family member. This time allows employees to grieve and
            handle personal matters.
          </li>
        </ul>

        <p>
          Our leave policy underscores our commitment to employee well-being,
          providing a range of leave options to support health, family
          responsibilities, and personal needs.
        </p>

        <h4>GRI 3-3</h4>
      </div>

      {/* page 64 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ fontWeight: "600" }}>**Employee Progression** </p>

        <ul>
          <li>
            We understand the significance of organizational structures in
            supporting our operations. These structures establish a clear chain
            of command, define roles and responsibilities, and promote effective
            collaboration among all team members. Employee advancement through
            these structures is governed by a fair, transparent, and
            well-defined system.
          </li>
          <li>
            Employee advancement is based on a comprehensive set of criteria
            that evaluates both functional skills and behavioral competencies,
            along with consistent performance. Our standardized approach ensures
            that promotions are merit-based and holistic, taking into account
            all relevant aspects of an employee’s contributions.
          </li>
        </ul>

        <img
          src={page64}
          alt="page 64"
          style={{ width: "100%", height: "400px" }}
        />

        <p style={{ fontWeight: "600" }}>
          **Career Planning and Performance Management**{" "}
        </p>

        <p>
          Career planning is a key element of our Performance Management System
          (PMS). To support structured employee progression through various
          levels, we have implemented a Promotion Policy that ensures career
          advancement is guided by clear and equitable criteria. Employee
          evaluations are conducted annually using a Results-Based Criteria
          (RBC) approach. This method assesses individual performance and
          conduct in relation to the organization’s overall goals. The process
          is as follows:
        </p>

        <ul>
          <li>
            Departmental Goal Achievement: Each department sets specific
            performance goals for the year. Achievement of these goals
            influences the budget allocation for salary increments within the
            department, ensuring that rewards are based on collective
            performance.
          </li>
          <li>
            Individual Assessments: Following the allocation of the budget based
            on departmental achievements, individual assessments are performed.
            Employees are evaluated on their personal contributions and
            performance, ensuring alignment with departmental objectives and
            organizational standards.
          </li>

          <p style={{ fontWeight: "600" }}>Promotion Criteria:</p>

          <li>
            Our Promotion Policy defines the functional and behavioral
            competencies needed for advancement. Promotions are based on
            consistent performance and readiness for greater responsibilities,
            ensuring merit-based progression.
          </li>
          <li>
            We align career planning with our Performance Management System
            (PMS) and use a goal-based approach for budget allocation. This
            ensures fair and strategic evaluations and promotions, rewarding
            individual excellence and promoting departmental collaboration.
          </li>
          <li>
            Performance management is crucial for progressive organizations.
            HR's role is to implement a scientific and inclusive PMS. We focus
            on integrated workflows and teamwork, ensuring that performance is
            evaluated based on both individual and team contributions toward
            common goals.
          </li>
        </ul>
      </div>

      {/* page 65           */}
      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ color: "#7CB9E8" }}>Average Training Hours for FY202K= </p>

        <table
          style={{
            border: "1px solid black",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>1</th>
              <th
                colSpan="4"
                style={{ border: "1px solid black", padding: "8px" }}
              >
                Average hours of training per year
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>1</td>
              <td
                colSpan="3"
                style={{ border: "1px solid black", padding: "8px" }}
              >
                For Employees
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>A</td>
              <td
                colSpan="3"
                style={{ border: "1px solid black", padding: "8px" }}
              >
                Male
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Top management
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {assignValue("Top management", "A_C_A", "social")?.curr || 0}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Middle management
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {assignValue("Middle management", "A_C_A", "social")?.curr || 0}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Junior management
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {assignValue("Junior management", "A_C_A", "social")?.curr || 0}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Total
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {(
                  Number(
                    assignValue("Top management", "A_C_A", "social")?.curr || 0
                  ) +
                  Number(
                    assignValue("Middle management", "A_C_A", "social")?.curr ||
                      0
                  ) +
                  Number(
                    assignValue("Junior management", "A_C_A", "social")?.curr ||
                      0
                  )
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>B</td>
              <td
                colSpan="3"
                style={{ border: "1px solid black", padding: "8px" }}
              >
                Female
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Top management
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {assignValue("Top management", "A_C_B", "social")?.curr || 0}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Middle management
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {assignValue("Middle management", "A_C_B", "social")?.curr || 0}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Junior management
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {assignValue("Junior management", "A_C_B", "social")?.curr || 0}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Total
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {" "}
                {(
                  Number(
                    assignValue("Top management", "A_C_B", "social")?.curr || 0
                  ) +
                  Number(
                    assignValue("Middle management", "A_C_B", "social")?.curr ||
                      0
                  ) +
                  Number(
                    assignValue("Junior management", "A_C_B", "social")?.curr ||
                      0
                  )
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>2</td>
              <td
                colSpan="3"
                style={{ border: "1px solid black", padding: "8px" }}
              >
                Average hours of training for workers
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Male
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {assignValue("Male", "A_B_A", "social")?.curr || 0}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Female
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {assignValue("Female", "A_B_A", "social")?.curr || 0}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                Tatal
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {(
                  Number(assignValue("Male", "A_B_A", "social")?.curr || 0) +
                  Number(assignValue("Female", "A_B_A", "social")?.curr || 0)
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* page 66  */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>Behavioral Framework:</p>
        <h4>Leadership Development Framework</h4>
        <p>
          Our framework emphasizes seven key behavioral traits crucial for
          leadership development. It provides a clear pathway for employees,
          preparing them for future leadership roles and fostering a
          leadership-centric culture. By developing these essential
          competencies, we promote an entrepreneurial, effective, and inclusive
          mindset, building a strong internal talent pipeline for succession.
        </p>

        <img
          src={page66}
          alt="page-66-img"
          style={{
            width: "50%",
            display: "block",
            height: "200px",
            margin: "auto",
          }}
        />

        <h4>Talent Engagement</h4>
        <p>
          Our workforce is a key driver of performance and productivity. We
          encourage our teams to actively share ideas and suggestions to advance
          our goals. Our engagement strategy is built on open and honest
          communication, reflecting our commitment to strong, trust-based
          relationships with our employees. By valuing our people, we foster
          pride, trust, and loyalty, creating a foundation for a high-performing
          and thriving organization.
        </p>
        <h4>Fair Compensation</h4>
        <p>
          We value our employees’ contributions and understand that fair
          compensation is essential for recognizing their efforts. Our
          compensation programs are designed to ensure competitive remuneration
          through industry benchmarking, guidance from compensation advisors,
          and a global rewards strategy. The Compensation Committee oversees
          remuneration processes and policies, including the compensation of
          senior management. Additionally, compensation for top executives is
          approved by shareholders as required by relevant laws and regulations.
        </p>

        <h4>GRI 404-1</h4>
      </div>

      {/* page 67  */}
      <div style={{ pageBreakBefore: "always" }}>
        <p style={{ color: "#7CB9E8" }}>Employee Benefits</p>

        <p>
          At our organization, we continuously benchmark our employee benefits
          and opportunities against industry trends to meet the evolving needs
          of our diverse teams and their families. By offering flexible benefits
          programs, including insurance policies, retirement plans, and
          allowances, we empower our employees to reach their full potential
          while meeting personal and health goals. We also support our employees
          with retirement benefits such as contributions to pension funds and
          mandatory retirement provisions in accordance with applicable laws and
          regulations. We offer a variety of leave options designed to promote
          work-life balance and meet flexibility needs. Through our company
          portal, employees can enjoy the flexibility of selecting their shift
          hours or opting for remote work, where available. We provide
          maternity, adoption, and paternity leave, along with resources such as
          on-site lactation facilities and partnerships with local childcare
          centers to support employees transitioning back to work after parental
          leave. The table below outlines details on parental leave taken during
          the fiscal year.
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div>
            <h2>Employee Benefits</h2>
            <ul>
              <li>Global parental leave</li>
              <li>Medical insurance</li>
              <li>Accident insurance</li>
              <li>Life insurance</li>
              <li>Housing allowance</li>
              <li>Education allowance for school fees</li>
              <li>Phone allowance</li>
              <li>Car allowance</li>
              <li>Meal allowance</li>
              <li>Transportation allowance</li>
              <li>Variable/Performance pay for specific employee categories</li>
            </ul>
          </div>

          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr style={{ borderBottom: "2px solid #00BFFF" }}>
                <th>Description</th>
                <th>Male</th>
                <th>Female</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Number of employees entitled to parental leave</td>
                <td>{assignValue("Male", "C_A", "social")?.curr}</td>
                <td>{assignValue("Female", "C_A", "social")?.curr}</td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Number of employees who availed parental leave</td>
                <td>{assignValue("Male", "C_B", "social")?.curr}</td>
                <td>{assignValue("Female", "C_B", "social")?.curr}</td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>
                  Number of employees who returned to work in the reporting
                  period after parental leave ended
                </td>
                <td>{assignValue("Male", "C_C", "social")?.curr}</td>
                <td>{assignValue("Female", "C_C", "social")?.curr}</td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>
                  Number of employees who returned to work after parental leave
                  ended in previous year who were still employed 12 months after
                  they returned to work
                </td>
                <td>{assignValue("Male", "C_D", "social")?.curr}</td>
                <td>{assignValue("Female", "C_D", "social")?.curr}</td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Return to work rate (%) *</td>
                <td>{assignValue("Male", "C_E_A", "social")?.curr}</td>
                <td>{assignValue("Female", "C_E_A", "social")?.curr}</td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td colSpan={3} style={{ fontStyle: "italic" }}>
                  *Does not account for spillover of maternity/paternity leave
                  across financial years.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p>
          *These provisions are subject to the HR policies of individual global
          locations
        </p>

        <h4>GRI 201-3, GRI 401-2, GRI 401-3</h4>
      </div>

      {/* page 68 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h3 style={{ color: "#7CB9E8" }}>Nurturing an Ethics-driven Culture</h3>
        <p>
          Our Employees Code of Conduct (ECoC) serves as the foundation for
          guiding ethical standards and practices across the organization. It
          applies to all members of the Board of Directors and employees,
          regardless of their employment status. The ECoC addresses a range of
          topics, including honest and ethical conduct, fair dealing,
          non-discrimination, compliance with laws and regulations, gender
          equality in the workplace, whistleblowing, and insider trading, among
          others. We expect our partners across the value chain to adhere to the
          principles outlined in the code. We consistently uphold our values in
          every business endeavor, ensuring full compliance with the ECoC.
          Through various training programs, both online and in person, we
          actively embed these principles in our employees. The ECoC is
          available on the organization's website, and all employees are
          required to acknowledge their commitment to conducting themselves in
          accordance with it.
        </p>
        <i style={{ color: "#7CB9E8" }}>Our Human Rights Commitment</i>
        <p>
          Our organization is committed to safeguarding and respecting human
          rights across all aspects of our global operations through our Human
          Rights Policy. This Description Male Female Number of employees
          entitled to parental leave Number of employees who availed parental
          leave Number of employees who returned to work in the reporting period
          after parental leave ended Number of employees who returned to work
          after parental leave ended in previous year who were still employed 12
          months after they returned to work Return to work rate (%)* *Does not
          account for spillover of maternity /paternity leave across financial
          years. policy is built upon the principles of the Universal
          Declaration of Human Rights, as well as the International Bill of
          Rights and the Declaration on Fundamental Principles and Rights at
          Work (1998) by the International Labor Organization. <br />
          As upholding human rights is a collective responsibility, we expect
          our value chain partners to adhere to these principles. Our objective
          is to promptly detect any instances of human rights violations and
          responsibly address identified risks. We also provide grievance
          redressal mechanisms to address violations of fundamental human rights
          and resolve stakeholder concerns in a just, fair, and timely manner.
          Additionally, a significant portion of our security personnel receive
          regular training on human rights policies and processes across various
          locations.
        </p>
        <h4>
          Zero Tolerance Policy towards Child and Forced/Compulsory Labor{" "}
        </h4>
        <p>
          We are firmly committed to ethical labor practices and ensure that no
          individual under the age of 18 is employed within our organization. We
          also strictly prohibit any form of forced or compulsory labor and do
          not engage with suppliers or vendors who utilize such practices.
        </p>
        <h3>Freedom of Association </h3>
        <p>
          We fully comply with local labor laws and recognize the importance of
          fair wages, freedom of association, participation, and collective
          bargaining throughout our operations. We are committed to fostering an
          environment where employee rights are consistently upheld and
          protected. In line with this commitment, we respect the right of
          employees to join, form, or refrain from participating in any employee
          collectives without fear of retaliation, harassment, or intimidation.
          Where applicable, employee unions recognized by management represent a
          portion of our workforce.{" "}
        </p>
        <h3>Human Rights Protection and Due Diligence Efforts</h3>
        <p>
          At our organization, we work to identify any adverse human rights
          impacts of our business on relevant stakeholders and take corrective
          actions to address these issues. Our efforts span various areas,
          including labor standards, health and safety, environmental practices,
          corporate ethics, and specific topics such as freedom of association,
          safe working conditions, fair wages, child labor, and discrimination.
        </p>
        <h4>Employee Awareness on Human Rights Policies and Procedures </h4>
        <p>
          To promote understanding and reinforce our commitment to human rights,
          we provide employees with focused training programs through our
          Learning Management System. These programs aim to raise awareness and
          knowledge, fostering a culture of respect, fairness, and equality. We
          strictly comply with all applicable laws and regulations related to
          human rights. In the past year, we received several complaints of
          discrimination, all of which were resolved within the prescribed
          timeline.
        </p>
        <h4>Ensuring Employee Well-being, Health, and Safety</h4>
        <p>
          Through a range of focused programs and initiatives, we have fostered
          a supportive work environment that extends beyond traditional
          benefits, addressing the holistic health and well-being of our
          workforce.
        </p>
        <h4>Addressing Mental Health </h4>
        <p>
          We conduct regular sessions on mental health to equip employees with
          valuable tools for managing stress and other challenges. By openly
          discussing these issues, we foster a culture of support and
          resilience. We offer a free and confidential counseling helpline
          available to all employees, providing a safe space for expressing
          concerns, seeking guidance, and receiving professional assistance. We
          also celebrate International Yoga Day across our global locations,
          promoting relaxation and well-being through yoga and mindfulness
          practices. Additionally, we integrate sports and health programs into
          our employee welfare initiatives, recognizing the link between
          physical fitness and overall well-being. We organize sports activities
          at every location and include sports competitions for employees'
          family members during our annual Family Day event.
        </p>
        <h3>
          GRI 406-1, GRI 411-1, GRI 410-1, GRI 408-1 and 409-1, GRI 407-1, GRI
          2-30, GRI 403-1
        </h3>
      </div>

      {/* page 69 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h4>Our Proactive Approach to Occupational Health and Safety</h4>
        <p>
          Our Employee Health and Safety (EHS) policy outlines our approach to
          occupational health and safety standards. Driven by our commitment to
          achieving 'zero harm,' our EHS management system aims to stay ahead of
          regulations and legislation. By benchmarking our EHS standards against
          international best practices such as ISO 45001:2018, we ensure a
          proactive approach to safety.
          <br />
          <br />
          We conduct regular audits and implement robust governance mechanisms
          to monitor and review EHS (Environmental, Health, and Safety)
          practices across all our operations. Our EHS leadership, from Area
          Managers to senior executives, oversees and upholds safety standards
          at all locations. We have embedded fundamental safety principles in
          our EHS guidelines and standards to ensure alignment with best
          practices, such as ISO 45001:2018, and compliance with local
          regulatory requirements. Through continuous training and awareness
          programs, we foster a culture of safety and shared commitment to
          promote it in our workplaces.
        </p>
        <h4 style={{ color: "#7CB9E8" }}>
          Employees Covered by OHS Management System
        </h4>
        <div style={{ display: "flex", gap: "10px" }}>
          {ohcWorkersCoveredSocial?.map((e, index) => (
            <div
              style={{
                border: "1px solid #7CB9E8",
                width: "100%",
                padding: "25px",
                display: "flex",
                gap: "10px",
              }}
            >
              <p>{e.name}</p>
              <h2 style={{ color: "#7CB9E8" }}>{e.curr || "XXX"}</h2>
              {/* <td>{e.curr}</td> */}
              <hr style={{ margin: "20px 0px 10px 0px" }} />
            </div>
          ))}
        </div>
        {/* <div>
              <p>
                Number of Employees whose Work/Workplace is Controlled by the
                Organization
              </p>
              <h2 style={{ color: "#7CB9E8" }}>XXX</h2>
            </div>
            <hr style={{ margin: "20px 0px 10px 0px" }} />
            <div>
              <p>
                Number of Employees covered by the OHS System, Audited or
                Certified by an External Party
              </p>
              <h2 style={{ color: "#7CB9E8" }}>XXX</h2>
            </div>
            <hr style={{ margin: "20px 0px 10px 0px" }} />
            <div>
              <p>Number of Workers Excluded</p>
              <h2 style={{ color: "#7CB9E8" }}>XXX</h2>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #7CB9E8",
              width: "100%",
              padding: "25px",
            }}
          >
            <div>
              <p>Number of Employees Covered by the OHS System</p>
              <h2 style={{ color: "#7CB9E8" }}>XXX</h2>
            </div>
            <hr style={{ margin: "20px 0px 10px 0px" }} />
            <div>
              <p>
                Number of Employees Covered by the OHS System and Internally
                Audited
              </p>
              <h2 style={{ color: "#7CB9E8" }}>XXX</h2>
            </div>
          </div>
        </div> */}
        <h4>GRI 403-1, GRI 403-8</h4>
      </div>

      {/* page 70 */}
      <div style={{ pageBreakBefore: "always" }}>
        <i style={{ color: "#7CB9E8" }}>EHS Focus Areas</i>
        <p>
          The four core areas of our global EHS management system guide our
          multifaceted approach in these areas.
        </p>
        <div style={{ display: "flex", gap: "10px" }}>
          <div
            style={{
              border: "1px solid #7CB9E8",
              width: "100%",
              padding: "25px",
            }}
          >
            <div>
              <p>Sites globally are ISO 45001:2018-certified</p>
              <ul style={{ listStyle: "inside" }}>
                <li> Self-audit level 1</li>
                <li> Corporate audit level 2</li>
                <li> Third-party audit level 3</li>
              </ul>
            </div>
            <hr style={{ margin: "20px 0px 10px 0px" }} />
            <div>
              <p>
                The ISO framework serves as The foundation for our global EHS
                Standards.
              </p>
              <ul style={{ listStyle: "inside" }}>
                <li> EHS management</li>
                <li> Process safety</li>
                <li> Occupational safety</li>
                <li> Environment</li>
                <li> Occupational health and hygiene</li>
              </ul>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #7CB9E8",
              width: "100%",
              padding: "25px",
            }}
          >
            <div>
              <p>
                The EHS policy, management system, and global standards
                strengthen our EHS governance.
              </p>
              <ul style={{ listStyle: "inside" }}>
                <li> EHS KPIs</li>
                <li> EHS CAPA Tracker</li>
                <li> EHS Culture Meter</li>
              </ul>
            </div>
            <hr style={{ margin: "20px 0px 10px 0px" }} />
            <div>
              <p>
                We drive our EHS culture development by a top-to-bottom EHS
                engagement mechanism that works through numerous channels.
              </p>
              <ul style={{ listStyle: "inside" }}>
                <li> Visible leadership</li>
                <li> Engagement of employees</li>
                <li> Line accountability in EHS</li>
                <li> Competence and capability</li>
              </ul>
            </div>
          </div>
        </div>
        <p style={{ color: "#7CB9E8", marginTop: "20px" }}>
          Hazard Identification, Risk Assessment, and Incident Investigation
        </p>
        <p>
          We regularly conduct risk assessments to evaluate safety practices and
          implement action plans to mitigate identified risks. Our risk
          assessment methodology and safety practices are guided by our Process
          Safety Management principles, which include a comprehensive set of
          elements. This approach ensures continuous improvement in risk
          management and working conditions. Employees can report and
          investigate incidents through a customized IT Global EHS portal, which
          facilitates knowledge sharing on preventive measures to prevent
          recurrences.
        </p>
        <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>
          GRI 403-2 and 403-7
        </h4>
      </div>
      {/* page 71 */}
      <div style={{ pageBreakBefore: "always" }}>
        <img
          src={page71}
          alt=""
          width="50%"
          height={"200px"}
          style={{ display: "block", margin: "0 auto" }}
        />
        <p style={{ marginTop: "10px" }}>
          Key Focus Areas of Process Safety Management
        </p>
        <strong>Risk Analysis</strong>
        <p>
          This process identifies root causes and develops appropriate
          mitigation plans.
        </p>
        <strong>**Tools Implemented: **</strong>
        <ul style={{ listStyle: "inside", marginTop: "5px" }}>
          <li>EHS checklists</li>
          <li>Hazard and Operability (HAZOP) analysis</li>
          <li>Hazard Identification and Risk Assessment (HIRA)</li>
          <li>Qualitative Risk Analysis (QRA)</li>
          <li>Job Safety Analysis (JSA)</li>
        </ul>

        <p style={{ fontWeight: "600" }}>
          {" "}
          **Risk Evaluation for Materials Used in Operations**
        </p>

        <p>
          This process assesses the EHS information related to the materials
          used in operations. The evaluation aims to prevent potential hazards
          that may arise from the unintended mixing of different materials.
        </p>
        <strong>On-Site Emergency Preparedness</strong>
        <p>
          To establish a comprehensive fire safety and emergency management
          system. We conduct regular fire safety drills and training sessions to
          ensure preparedness, and we maintain a ready supply of tested fire
          protection equipment across all our locations.
        </p>
        <strong>**Disaster Management**</strong>
        <p>
          To identify emergencies and establish a chain of procedures. We aim to
          ensure uninterrupted operations and effective response solutions.
          Through our formal on-site emergency plan (OSEP), we identify
          potential emergencies and outline procedures, including designated
          evacuation routes. Additionally, we assess risks associated with
          potential disasters that could impact our supply chain as part of our
          business continuity plan.
        </p>
      </div>
      {/* page 72 */}
      <div>
        <strong>**Employee Health Management System**</strong>
        <p>
          Our comprehensive health management system includes well-defined
          processes, standard operating procedures (SOPs), and administrative
          controls to mitigate risks associated with our operations. We conduct
          pre-employment health check-ups to ensure employees are physically fit
          for their roles. Regular health check-ups are provided at our
          dedicated occupational health centers across all facilities, with
          qualified doctors and nurses available around the clock to monitor
          health and address immediate concerns. We maintain the confidentiality
          of health records in line with our commitment to ethical standards. We
          also offer comprehensive health insurance schemes to all employees.
          Additionally, we organize health-focused initiatives to promote
          healthy living, including awareness programs and webinars on
          nutrition, mental health, meditation, and lifestyle-related diseases.
          We extend these health-focused programs to our contractual workforce
          and encourage their participation.
        </p>

        <h3 style={{ color: "#7CB9E8", textAlign: "center" }}>
          The Five Pillars of our Safety Management System
        </h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            gap: "10px",
          }}
        >
          <div>
            <h1 style={{ color: "#7CB9E8" }}>1.</h1>
            <p>
              Forums and meetings - informal and formal safety committee
              meetings{" "}
            </p>
          </div>
          <div>
            <h1 style={{ color: "#7CB9E8" }}>2.</h1>
            <p>Leadership engagement through EHS meeting forums</p>
          </div>
          <div>
            <h1 style={{ color: "#7CB9E8" }}>3.</h1>
            <p>
              In-house and h external training s in line with ISO 45001:2018
              Requirements
            </p>
          </div>
          <div>
            <h1 style={{ color: "#7CB9E8" }}>4.</h1>
            <p>
              Incident reporting And knowledge sharing through IT-based Global
              EHS porta
            </p>
          </div>
          <div>
            <h1 style={{ color: "#7CB9E8" }}>5.</h1>
            <p>Audits for compliance with ISO 45001:2018</p>
          </div>
        </div>
        <p>**Instilling a Safety-Oriented Mindset**</p>
        <p>
          We employ a multi-faceted approach to deeply integrate a
          safety-oriented culture into our operations and practices. Through
          practical safety training, we equip our workforce with the knowledge
          and skills to enhance their understanding of safety practices. We
          promote safety through both formal and informal communication
          channels, engaging employees with quizzes, interactive EHS
          competitions, safety drills, and observances of Safety Week and Fire
          Service Week. <br />
          Our rewards program recognizes individuals who demonstrate a strong
          commitment to safety, and visible leadership engagement is crucial in
          reinforcing this mindset throughout the organization. Scheduled EHS
          rounds conducted by leadership and on-site managers emphasize the
          importance of safety and foster a sense of responsibility and
          accountability among employees.
          <br />
          To measure and understand the state of our EHS culture, we use an
          evaluation tool called the ‘EHS Culture Meter’. This tool assesses the
          effectiveness of our safety training programs and EHS initiatives,
          covering all aspects of EHS and evaluating employee engagement,
          compliance, and integration with the safety management system. This
          analysis helps the EHS governance team identify strengths and areas
          needing improvement.
        </p>
        <strong>GRI 403-3 and 403-6 GRI 403-7, GRI 403-5, 92GRI 403-4</strong>
      </div>
      {/* page 73 */}
      <div style={{ pageBreakBefore: "always" }}>
        <h4 style={{ color: "#7CB9E8" }}>Safety Performance in FY</h4>
        <h5 style={{ color: "#7CB9E8" }}>
          Safety Metrics for Work-related Injuries93
        </h5>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr style={{ borderBottom: "2px solid #00BFFF" }}>
              <th>Description</th>
              <th>Employees</th>
              <th>Workers</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>Fatalities (as a result of work-related injury)</td>
              <td>
                {
                  assignValue(
                    "The number and rate of recordable work-related injuries",
                    "I_A",
                    "social"
                  )?.curr
                }
              </td>
              <td>
                {
                  assignValue(
                    "The number and rate of recordable work-related injuries",
                    "I_B",
                    "social"
                  )?.curr
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>
                High-consequence work-related injuries (excluding fatalities)
              </td>
              <td>
                {
                  assignValue(
                    "The number and rate of high-consequence work-related injuries (excluding fatalities)",
                    "I_A",
                    "social"
                  )?.curr
                }
              </td>
              <td>
                {
                  assignValue(
                    "The number and rate of high-consequence work-related injuries (excluding fatalities)",
                    "I_B",
                    "social"
                  )?.curr
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>Recordable work-related injuries</td>
              <td>
                {
                  assignValue(
                    "The number and rate of recordable work-related injuries",
                    "I_A",
                    "social"
                  )?.curr
                }
              </td>
              <td>
                {
                  assignValue(
                    "The number and rate of recordable work-related injuries",
                    "I_B",
                    "social"
                  )?.curr
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>LTIFR</td>
              <td>{assignValue("LTIFR", "I_A", "social")?.curr}</td>
              <td>{assignValue("LTIFR", "I_B", "social")?.curr}</td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>Rate of fatalities*</td>
              <td></td>
              <td></td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>
                Rate of high-consequence work-related injuries (excluding
                fatalities)*
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>Rate of recordable work-related injuries*</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <h5 style={{ color: "#7CB9E8" }}>
          Safety Metrics for Work-related Ill Health94
        </h5>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr style={{ borderBottom: "2px solid #00BFFF" }}>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>Description</td>
              <td>Employees</td>
              <td>Workers</td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>Fatalities</td>
              <td>
                {
                  assignValue(
                    "The number of fatalities as a result of work-related ill health;",
                    "J_A",
                    "social"
                  )?.curr
                }
              </td>
              <td>
                {
                  assignValue(
                    "The number of fatalities as a result of work-related ill health;",
                    "J_B",
                    "social"
                  )?.curr
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td>Recordable cases</td>
              <td>
                {
                  assignValue(
                    "The number of cases of recordable work-related ill health;",
                    "J_A",
                    "social"
                  )?.curr
                }
              </td>
              <td>
                {
                  assignValue(
                    "The number of cases of recordable work-related ill health;",
                    "J_B",
                    "social"
                  )?.curr
                }
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Enhancing Social Value</h3>
        <h4>Striving Towards an Equitable Future</h4>
        <p>
          Our commitment to achieving an equitable future is reflected in our
          continuous efforts to foster fairness and inclusivity across all
          aspects of our organization. This entails:
        </p>
        <ul style={{ listStyle: "inside" }}>
          <li>
            Promoting Fairness: Implementing policies and practices designed to
            ensure equitable treatment and opportunities for all individuals,
            regardless of their background or identity.
          </li>
          <li>
            Addressing Inequities: Identifying and rectifying systemic barriers
            and disparities that may impact certain groups, ensuring that all
            employees have equal access to resources and opportunities.
          </li>
          <li>
            Fostering Diversity and Inclusion: Cultivating a work environment
            that values and respects diverse perspectives, and actively supports
            inclusion across all levels of the organization.
          </li>
          <li>
            Applying Consistent Practices: Ensuring that recruitment,
            development, and compensation practices are applied fairly and
            without bias, to support equity and recognize merit.
          </li>
          <li>
            Providing Equal Opportunities: Offering targeted resources and
            support to help all employees reach their full potential and achieve
            their career goals
          </li>
        </ul>
        <p>
          In striving towards an equitable future, we are dedicated to embedding
          these principles into our organizational culture, thereby advancing
          fairness and equality in every facet of our operations.
        </p>
        <h4>GRI 403-9, GRI 403-10F</h4>
      </div>
      {/* page 74 */}
      <div style={{ pageBreakBefore: "always" }}>
        <img
          src={page74}
          alt=""
          width="100%"
          height="300px"
          style={{ display: "block", margin: "0 auto" }}
        />
        <strong> **Local Community Engagement**</strong>
        <p>
          Community engagement is crucial for our organization, as it fosters a
          mutually beneficial relationship between us and the communities we
          serve. By actively engaging with local communities, we gain a deeper
          understanding of their needs and concerns, which allows us to address
          them effectively and build trust and goodwill.
        </p>
        <img
          src={page75}
          alt=""
          width="40%"
          style={{ display: "block", margin: "0 auto" }}
        />
        <p>
          We view our success as closely linked to the well-being and prosperity
          of the communities we serve. Guided by this belief, we drive our
          Corporate Social Responsibility (CSR) efforts through a well-defined
          CSR policy and strategy focused on key areas for community
          development. Our CSR policy, approved by the Board, outlines the
          framework for our community programs and aligns with relevant legal
          provisions and regulations.
          <br />
          Our primary focus areas include healthcare, education, water and
          sanitation, environmental conservation, rural development, and
          addressing emerging societal challenges. We regularly conduct
          comprehensive needs assessments to effectively address community needs
          in these areas, ensuring that our CSR initiatives are tailored to
          provide support and add value where it is most needed
          <br />
          In the past fiscal year, we invested ₹XXX million in our CSR efforts,
          benefiting over XXX people across more than XXX locations in XXX
          states and union territories. Additionally, there were no reported
          negative impacts on local communities from our business and operations
          during this period.
        </p>
        <h4>GRI 413-1, GRI 413-2, GRI 413-1</h4>
      </div>
      {/* page 75 */}
      <div style={{ pageBreakBefore: "always" }}>
        <p>**How We Serve the Community**</p>
        <p>
          Our organization places Corporate Social Responsibility (CSR) at the
          forefront of our priorities. We are dedicated to making meaningful
          contributions to uplift and transform the lives of the community and
          the underprivileged. We are also deeply aware of our duty towards the
          environment, and we actively work to promote good health, social
          development, and environmental sustainability through various programs
          aimed at fostering a better and more sustainable future.
        </p>
        <p style={{ color: "#7CB9E8" }}>Our Governance Structure</p>
        <p>Board of Directors:</p>
        <p>
          The Board of Directors provides strategic oversight and governance for
          CSR initiatives, ensuring that community engagement efforts align with
          the organization’s goals and values.
        </p>
        <p>
          CSR Committee: <br />
          The CSR Committee is responsible for developing and approving CSR
          policies and programs, and for monitoring the effectiveness and impact
          of community engagement activities.
        </p>
        <p>
          Corporate CSR Team:
          <br />
          The Corporate CSR Team executes and manages CSR projects, coordinating
          efforts across different departments to ensure effective
          implementation and alignment with the organization’s overall strategy.
        </p>
        <p>
          NGO/Other Implementing Partners: <br />
          NGOs and other implementing partners collaborate with the organization
          to deliver community programs on the ground, leveraging their
          expertise and local knowledge to address specific needs effectively
        </p>
        <p>
          Plant/Field CSR Teams:
          <br />
          Plant or Field CSR Teams work directly within local communities to
          implement and oversee CSR initiatives, ensuring that projects are
          tailored to address local concerns and integrate seamlessly with the
          community’s needs.
        </p>
        <h5>
          Education Assistance Program- Protsahan: Empowering Futures through
          Education{" "}
        </h5>
        <p>
          **Protsahan** is our dedicated education assistance program, designed
          to provide scholarships to support the academic advancement of our
          employees and their children. This initiative aims to offer crucial
          financial support to eligible children, enabling them to pursue their
          dreams and build successful careers
        </p>
        <p>
          When a child demonstrates outstanding potential, hard work, and
          dedication despite challenging circumstances, it presents an
          opportunity for the community to support their aspirations. By
          nurturing such talent, we can inspire success stories that transform
          families, communities, and even nations.
        </p>
        <p>
          **Program Implementation: ** <br></br>
          Protsahan addresses cases where financial aid can significantly impact
          educational outcomes. This program is available at all locations of
          our organization and is open to employees directly or indirectly
          associated with us.
        </p>
      </div>
      {/* page 76 */}
      <div>
        <p>
          **Program Structure: ** <br /> Protsahan operates in three tiers to
          provide varying levels of educational support:
        </p>
        <p>
          Protsahan-I: Initial support for primary education. <br />
          Protsahan-II: Intermediate support for secondary education. <br />
          Protsahan-III: Advanced support for higher education
        </p>
        <p>
          Each tier is designed to provide tailored assistance, ensuring that
          every eligible child has the opportunity to reach their full
          potential. Through Protsahan, we demonstrate our commitment to
          fostering talent and contributing to societal betterment, helping to
          build a brighter future through exceptional success stories.
        </p>
        <img
          src={page76}
          alt="page76"
          width="50%"
          style={{ display: "block", margin: "0 auto" }}
        />
        <p>**Promotion of Sports among Youth**</p>
        <p>
          **Development of Volleyball Ground:** We contributed to the
          development of a volleyball ground in Village Rampur Saini, providing
          a dedicated space for sports and community activities.
        </p>
        <p>
          **Sponsored Cricket Tournament:** We sponsored an inter-village
          cricket tournament for rural youth in Village promoting sportsmanship
          and engagement among young people.
        </p>
        <p>
          By investing in community projects and demonstrating a genuine concern
          for societal welfare, we earn the support and respect of the
          communities we serve. Any other free to be add
        </p>
      </div>
      {/* page 77 */}

      <div>
        <p>**Community Service**</p>

        <p>
          - <strong>Sponsored Medical Check-Up Camps:</strong> <br />
          We have sponsored medical check-up camps in nearby villages providing
          essential health services to local residents.
        </p>
        <p>
          - <strong>Education Awareness Camps:</strong> <br />
          We supported education awareness camps in nearby villages such as
          (--------) promoting educational opportunities and awareness.
        </p>
        <p>
          - <strong>Scholarships for Meritorious Students:</strong> <br />
          We offer scholarships to outstanding science students in nearby
          villages including (____________________) helping them pursue their
          academic goals.
        </p>
        <p>
          - <strong>Development of Landscaped Parks:</strong> <br />
          We have developed landscaped parks in nearby villages such as
          (_____________________) enhancing community spaces for recreation and
          relaxation.
        </p>
        <p>
          - <strong>Construction of Religious Place:</strong> <br />
          We contributed to the construction of a religious place in the nearby
          villages of (________________), supporting local cultural and
          religious practices.
        </p>
        <p>
          - <strong>Construction of School Building:</strong> <br />
          We aided in the construction of part of a school building in
          (+______________) improving educational infrastructure for local
          students.
        </p>
        <p>
          - <strong>Contribution to Religious Festivals:</strong> <br />
          We contributed to religious festivals in nearby villages, supporting
          local cultural and community events.
        </p>

        <p>
          By engaging in these community service initiatives, we demonstrate our
          commitment to societal welfare and foster positive relationships with
          the communities we serve.
        </p>

        <h4>GRI 413-1, GRI 413-2, GRI 413-1 </h4>
      </div>
    </div>
  );
};

export default InitialContent;
